import { useMutation, useQuery } from '@apollo/client'

import {
  User,
  Application,
  ApplicationStage,
  ApplicationListResult,
  FinancingInfo,
} from 'common/types'
import { captureWarning } from 'lib/monitoring'
import {
  ACCEPT_APPLICATION_OFFER,
  APPROVE_APPLICATION,
  APPROVE_APPLICATION_FOR_FINANCING,
  CREATE_APPLICATION,
  CREATE_COMPANY_FILE_DOWNLOAD_LINK,
  CREATE_FINANCING_INFO,
  CREATE_USER_MUTATION,
  DENY_APPLICATION,
  SEND_BACK_APPLICATION,
  SUBMIT_APPLICATION,
  UPDATE_APPLICATION,
  UPDATE_FINANCING_INFO,
} from 'lib/apollo/mutations'

import {
  GET_APPLICATIONS_BY_USERID_QUERY,
  GET_APPLICATIONS_QUERY,
  GET_APPLICATION_COUNT_QUERY,
  GET_APPLICATION_QUERY,
  GET_MY_APPLICATIONS_QUERY,
  GET_USERS_QUERY,
  GET_USER_BY_USER_POOL_SUB_QUERY,
  GET_USER_QUERY,
} from 'lib/apollo/queries'
import {
  CreateCompanyFileDownloadLinkMutation,
  GetApplicationCountQuery,
} from 'common/api-types'

const useGetUsersQuery = () =>
  useQuery<{ getUsers: User[] }>(GET_USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetUserQuery = (id?: string) =>
  useQuery<{ getUser: User }>(GET_USER_QUERY, {
    variables: { userId: id },
    notifyOnNetworkStatusChange: true,
    skip: !id,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useCreateUserMutation = () =>
  useMutation<{ createUser: User }>(CREATE_USER_MUTATION, {
    refetchQueries: [{ query: GET_USERS_QUERY }],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetUserByUserPoolSubQuery = (sub: string) =>
  useQuery<{ getUserByUserPoolSub: User }>(GET_USER_BY_USER_POOL_SUB_QUERY, {
    variables: { sub },
    notifyOnNetworkStatusChange: true,
    skip: !sub,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetApplicationQuery = (applicationId?: Application['_id']) =>
  useQuery<{ getApplication: Application }>(GET_APPLICATION_QUERY, {
    variables: { id: applicationId },
    notifyOnNetworkStatusChange: true,
    skip: !applicationId,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetApplicationsByUserIdQuery = (userId?: User['_id']) =>
  useQuery<{ getApplicationsByUserId: Application[] }>(
    GET_APPLICATIONS_BY_USERID_QUERY,
    {
      variables: { userId },
      notifyOnNetworkStatusChange: true,
      skip: !userId,
      onError: (e: Error) => {
        captureWarning(e)
      },
    }
  )

const useGetApplicationsQuery = (currentStages?: ApplicationStage[]) =>
  useQuery<{ getApplications: ApplicationListResult }>(GET_APPLICATIONS_QUERY, {
    variables: { currentStages },
    skip: !currentStages,
    notifyOnNetworkStatusChange: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetMyApplicationsQuery = () =>
  useQuery<{ getMyApplications: Application[] }>(GET_MY_APPLICATIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetApplicationCountQuery = () =>
  useQuery<GetApplicationCountQuery>(GET_APPLICATION_COUNT_QUERY, {
    notifyOnNetworkStatusChange: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useCreateApplication = () =>
  useMutation<{ createApplication: Application }>(CREATE_APPLICATION, {
    refetchQueries: [
      {
        query: GET_MY_APPLICATIONS_QUERY,
      },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useUpdateApplication = (applicationId?: Application['_id']) =>
  useMutation<{ updateApplication: Application }>(UPDATE_APPLICATION, {
    refetchQueries: [
      { query: GET_APPLICATION_QUERY, variables: { id: applicationId } },
      { query: GET_MY_APPLICATIONS_QUERY },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useSubmitApplication = () =>
  useMutation<{ submitApplication: Application }>(SUBMIT_APPLICATION, {
    refetchQueries: [{ query: GET_MY_APPLICATIONS_QUERY }],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useApproveApplication = (applicationId?: Application['_id']) =>
  useMutation<{ approveApplication: Application }>(APPROVE_APPLICATION, {
    refetchQueries: [
      { query: GET_APPLICATION_QUERY, variables: { id: applicationId } },
      {
        query: GET_APPLICATIONS_QUERY,
        variables: {
          currentStages: ['UNDER_REVIEW'],
        },
      },
      {
        query: GET_APPLICATIONS_QUERY,
        variables: {
          currentStages: ['APPROVED'],
        },
      },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useDenyApplication = (applicationId?: Application['_id']) =>
  useMutation<{ denyApplication: Application }>(DENY_APPLICATION, {
    refetchQueries: [
      { query: GET_APPLICATION_QUERY, variables: { id: applicationId } },
      {
        query: GET_APPLICATIONS_QUERY,
        variables: {
          currentStages: ['DENIED'],
        },
      },
      {
        query: GET_APPLICATIONS_QUERY,
        variables: {
          currentStages: ['UNDER_REVIEW'],
        },
      },
      { query: GET_APPLICATION_COUNT_QUERY },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useSendBackApplication = (applicationId?: Application['_id']) =>
  useMutation<{ sendBackApplication: Application }>(SEND_BACK_APPLICATION, {
    refetchQueries: [
      { query: GET_APPLICATION_QUERY, variables: { id: applicationId } },
      {
        query: GET_APPLICATIONS_QUERY,
        variables: {
          currentStages: ['UNDER_REVIEW'],
        },
      },
      {
        query: GET_APPLICATIONS_QUERY,
        variables: {
          currentStages: ['MORE_INFORMATION_REQUIRED'],
        },
      },
      { query: GET_APPLICATION_COUNT_QUERY },
    ],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useCreateFinancingInfo = (applicationId: Application['_id']) =>
  useMutation<{ createFinancingInfo: FinancingInfo }>(CREATE_FINANCING_INFO, {
    refetchQueries: [
      { query: GET_APPLICATION_QUERY, variables: { id: applicationId } },
    ],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useUpdateFinancingInfo = (applicationId: Application['_id']) =>
  useMutation<{ updateFinancingInfo: FinancingInfo }>(UPDATE_FINANCING_INFO, {
    refetchQueries: [
      { query: GET_APPLICATION_QUERY, variables: { id: applicationId } },
    ],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useAcceptApplicationOffer = () =>
  useMutation<{ acceptApplicationOffer: Application }>(
    ACCEPT_APPLICATION_OFFER,
    {
      refetchQueries: [{ query: GET_MY_APPLICATIONS_QUERY }],
      awaitRefetchQueries: true,
      onError: (e: Error) => {
        captureWarning(e)
      },
    }
  )

const useApproveApplicationForFinancing = (applicationId: Application['_id']) =>
  useMutation<{ approveApplicationForFinancing: Application }>(
    APPROVE_APPLICATION_FOR_FINANCING,
    {
      refetchQueries: [
        { query: GET_APPLICATION_QUERY, variables: { id: applicationId } },
        {
          query: GET_APPLICATIONS_QUERY,
          variables: {
            currentStages: ['FUNDED'],
          },
        },
        {
          query: GET_APPLICATIONS_QUERY,
          variables: {
            currentStages: ['TERMS_REQUESTED'],
          },
        },
      ],
      awaitRefetchQueries: true,
      onError: (e: Error) => {
        captureWarning(e)
      },
    }
  )

const useCreateDownloadLinkQuery = () =>
  useMutation<CreateCompanyFileDownloadLinkMutation>(
    CREATE_COMPANY_FILE_DOWNLOAD_LINK,
    {
      onError: (e: Error) => captureWarning(e),
    }
  )

export {
  useGetUserByUserPoolSubQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useGetUsersQuery,
  useGetApplicationQuery,
  useGetApplicationsQuery,
  useGetMyApplicationsQuery,
  useGetApplicationsByUserIdQuery,
  useCreateApplication,
  useUpdateApplication,
  useSubmitApplication,
  useApproveApplication,
  useDenyApplication,
  useSendBackApplication,
  useGetApplicationCountQuery,
  useCreateFinancingInfo,
  useUpdateFinancingInfo,
  useAcceptApplicationOffer,
  useApproveApplicationForFinancing,
  useCreateDownloadLinkQuery,
}
