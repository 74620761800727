import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Form, Formik } from 'lib/formik'
import { LIQUIDLP_SUPPORT_EMAIL } from 'lib/config'
import { forgotPasswordSchema } from 'lib/validation'

import Alert from 'components/mui/Alert'
import Layout from 'components/Layout/Layout'
import LoadingButton from 'components/mui/LoadingButton'
import Link from 'components/mui/Link'
import Paper from 'components/mui/Paper/Paper'
import TextInput from 'components/mui/TextInput'

import { useAuth } from 'app/Auth'

const initialValues = {
  email: '',
}

function ForgotPassword() {
  const { forgotPasswordMutation } = useAuth()
  const { t } = useTranslation()

  const handleSubmit = (values: typeof initialValues) => {
    forgotPasswordMutation.mutate(values.email)
  }

  return (
    <Layout maxWidth="sm">
      <Grid container spacing={2} columns={1} justifyContent="center">
        <Typography textAlign="center" variant="h2" component="h1" my={2}>
          {t('user.forgot.title')}
        </Typography>

        <Grid item>
          <Paper hasBorder>
            <Formik
              initialValues={initialValues}
              validationSchema={forgotPasswordSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <Grid container spacing={3} columns={1}>
                    <Grid item xs={1} my={1}>
                      <TextInput
                        id="email"
                        name="email"
                        label={t('user.forgot.email')}
                        placeholder={t('user.forgot.emailPlaceholder')}
                        type="email"
                        required
                        inputMode="email"
                      />
                    </Grid>
                    {forgotPasswordMutation.isError && (
                      <Grid item>
                        <Alert title={t('common.error')}>
                          {(forgotPasswordMutation.error as Error).message ??
                            t('common.errorUnknown')}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item mb={4}>
                      <LoadingButton
                        loading={
                          forgotPasswordMutation.isPending ||
                          forgotPasswordMutation.isSuccess
                        }
                        type="submit"
                        mode="forward"
                      >
                        {t('user.forgot.resetPassword')}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            <Grid item>
              <Typography variant="subtitle1" color="secondary.lighter">
                {t('user.forgot.havingTrouble')}
              </Typography>
              <Typography variant="subtitle1" color="secondary.lighter">
                <Trans i18nKey="common.contact">
                  Contact us at
                  <Link
                    href={`mailto:${LIQUIDLP_SUPPORT_EMAIL}?subject=${t(
                      'common.resetEmailSubject'
                    )}`}
                    variant="h6"
                    sx={{ color: 'primary.darker' }}
                  >
                    partners@getopenstock.com
                  </Link>
                </Trans>
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ForgotPassword
