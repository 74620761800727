import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Application } from 'common/types'

import { SetLoanAmountProps } from 'views/member/setLoan/SetLoanAmount'
import BorderGridLayout from 'components/BorderGridLayout'
import LabelValueCard from 'components/LabelValueComponent'
import OfferDetailsCard, { LoanDetails } from 'components/loan/OfferDetailsCard'
import Paper from 'components/mui/Paper/Paper'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

interface Props {
  handleBack: () => void
  application?: Application
  values: SetLoanAmountProps
}

function ReviewLoanAndPayment({ handleBack, application, values }: Props) {
  const { t } = useTranslation()

  const financingInfo = application?.financingInfo
  const loanFrequency = financingInfo?.loanRepaymentFrequency
  const interestValue =
    loanFrequency === 'monthly'
      ? values.loanDetails.monthlyInterest
      : values.loanDetails.quarterlyInterest

  const loanDetails: LoanDetails = application
    ? [
        // Requested loan amount
        {
          heading: t('offer.loan.reviewLoan.loanAmount'),
          isLargeHeading: true,
          data: t('common.intlCurrencySimplifiedFormat', {
            value: values.requestedLoanAmount,
          }),
        },
        // Loan type
        {
          heading: t('offer.loan.reviewLoan.loanType.label'),
          data: t('offer.details.loanType.value'),
          notes: t('offer.loan.reviewLoan.loanType.note'),
        },
        // Loan term
        {
          heading: t('offer.details.loanTerm.label'),
          data: t('common.monthWithCount', {
            count: financingInfo?.financingTerm?.length,
          }),
        },
        // Total loan plus origination fee
        {
          heading: t('offer.loan.reviewLoan.originationFee.label'),
          data: t('common.intlCurrencySimplifiedFormat', {
            value: values.loanDetails.totalLoan,
          }),
          notes: t('offer.loan.reviewLoan.originationFee.note', {
            value: t('common.percentage', {
              value: financingInfo?.originationFee,
            }),
          }),
        },
        // Estimated yearly interest
        {
          heading: t('offer.loan.reviewLoan.yearlyInterest.label'),
          data: t('common.note', {
            value: t('common.intlCurrencySimplifiedFormat', {
              value: values.loanDetails.annualInterest,
            }),
          }),
          notes: t('offer.loan.reviewLoan.yearlyInterest.note'),
        },
        {
          heading: t(`offer.loan.reviewLoan.${loanFrequency}Interest.label`),
          data: t('common.note', {
            value: t('common.intlCurrencySimplifiedFormat', {
              value: interestValue,
            }),
          }),
          notes: t(`offer.loan.setLoan.details.${loanFrequency}Interest.note`, {
            value: t('common.percentage', {
              value: application?.financingInfo?.interestRate,
            }),
          }),
        },
      ]
    : []

  const paymentDetails = application
    ? [
        {
          label: 'Name',
          value: `${values.firstName} ${values.lastName}`,
        },
        {
          label: t(
            'offer.loan.paymentInformation.identificationInformation.address'
          ),
          value: values.streetAddress,
        },
        {
          label: t(
            'offer.loan.paymentInformation.identificationInformation.apartment'
          ),
          value: values.streetAddress2 === '' ? '-' : values.streetAddress2,
        },
        {
          label: t(
            'offer.loan.paymentInformation.identificationInformation.city'
          ),
          value: values.city,
        },
        {
          label: t(
            'offer.loan.paymentInformation.identificationInformation.country'
          ),
          value: values.country,
        },
        {
          label: t(
            'offer.loan.paymentInformation.identificationInformation.state'
          ),
          value: values.state,
        },
        {
          label: t(
            'offer.loan.paymentInformation.identificationInformation.zipCode'
          ),
          value: values.zipCode,
        },
      ]
    : []

  // const bankDetails = application
  //   ? [
  //       {
  //         label: t('offer.loan.paymentInformation.bankInformation.bankAccount'),
  //         value: values.bankAccountNumber,
  //       },
  //       {
  //         label: t('offer.loan.paymentInformation.bankInformation.routing'),
  //         value: values.ABA_routingNumber,
  //       },
  //     ]
  //   : []

  return (
    <Grid container rowGap={3}>
      <Grid item container xs={12} smd={8} rowGap={3}>
        <Paper>
          <BorderGridLayout mb={2}>
            <Typography variant="h3" mb={3}>
              {t('offer.loan.reviewLoan.title')}
            </Typography>

            <Grid item container>
              <OfferDetailsCard loanDetails={loanDetails} />
              <Typography variant="subtitle1" mt={2} color="secondary.dark">
                {t('offer.sofrDisclaimer')}
              </Typography>
            </Grid>
          </BorderGridLayout>

          <BorderGridLayout mb={{ xs: 2, sm: 6 }}>
            <Typography variant="h3" mb={4}>
              {t('offer.loan.reviewLoan.paymentInformation')}
            </Typography>
            <Grid item mb={4}>
              <LabelValueCard
                title={t(
                  'offer.loan.paymentInformation.identificationInformation.heading'
                )}
                details={paymentDetails}
              />
            </Grid>
            {/* <Grid item>
              <LabelValueCard
                title={t(
                  'offer.loan.paymentInformation.bankInformation.heading'
                )}
                details={bankDetails}
              />
            </Grid> */}
          </BorderGridLayout>

          <Grid item xs={12}>
            <ResponsiveStepperButtons handleBack={handleBack} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ReviewLoanAndPayment
