import MuiPhoneNumber from 'material-ui-phone-number'

import Typography from '@mui/material/Typography'
import { useField } from 'lib/formik'
import { defaultCountry } from 'lib/formConfig'
import { GenericObject } from 'common/types'

interface Props {
  id: string
  label: string
  name: string
  required: boolean
  variant?: 'outlined' | 'standard' | 'filled'
}

function PhoneInput({
  id,
  label,
  name,
  required,
  variant = 'outlined',
}: Props) {
  const [field, meta, helpers] = useField(name)

  const config: GenericObject = {
    ...field,
    id,
    name,
    required,
  }

  if (meta.touched && meta.error) {
    config.error = true
    config.helperText = meta.error
  }

  return (
    <>
      {label && (
        <Typography variant="h6" mb={1}>
          {label}
          {required && <span style={{ color: '#CF0C0C' }}> *</span>}
        </Typography>
      )}
      <MuiPhoneNumber
        {...config}
        variant={variant}
        defaultCountry={defaultCountry}
        onChange={(e) => {
          let valueParsed = (e as string).replace(/\D/g, '')
          let value = (e as string)
            .replaceAll('-', '')
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll(' ', '')

          valueParsed = valueParsed.replace(/\D/g, '')

          // If autofilling a 10 digit number, add the +1
          if (!field.value && valueParsed.length === 10) {
            value = `+1${valueParsed}`
          }

          helpers.setValue(value)
        }}
        sx={{
          svg: {
            height: '20px',
          },
        }}
        placeholder="1234"
        fullWidth
      />
    </>
  )
}

export default PhoneInput
