import { Trans, useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { LIQUIDLP_SUPPORT_EMAIL } from 'lib/config'

import Link from 'components/mui/Link'
import Paper from 'components/mui/Paper/Paper'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

function StepperSuccess({
  previousStepText,
  handleBack,
  handleForward,
  nextStepText,
  userType,
}: {
  previousStepText?: string
  handleBack?: () => void
  handleForward: () => void
  nextStepText: string
  userType: string
}) {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid item container xs={12} smd={7.5}>
        <Paper hasBorder>
          <Grid container mb={{ xs: 2, sm: 4 }}>
            <Typography>
              <Trans
                i18nKey={`member.newApplication.${userType}.success.description`}
              >
                Remember, if you need support, please reach out to us any time
                at &nbsp;
                <Link
                  href={`mailto:${LIQUIDLP_SUPPORT_EMAIL}?subject=${t(
                    'common.emailSubject'
                  )}`}
                  variant="body2"
                >
                  partners@getliquidlp.com
                </Link>
              </Trans>
            </Typography>
          </Grid>

          <ResponsiveStepperButtons
            previousStepText={previousStepText}
            handleBack={handleBack}
            handleForward={handleForward}
            nextStepText={nextStepText}
            isLastStep
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default StepperSuccess
