import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useMutation } from '@tanstack/react-query'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Form, Formik } from 'lib/formik'
import { verificationCodeDetailsSchema } from 'lib/validation'
import { useAuth } from 'app/Auth'

import Alert from 'components/mui/Alert'
import Layout from 'components/Layout/Layout'
import Link from 'components/mui/Link'
import LoadingButton from 'components/mui/LoadingButton'
import Paper from 'components/mui/Paper/Paper'
import TextInput from 'components/mui/TextInput'

const initialValues = {
  code: '',
}

function VerificationCode({
  isPaperComponent = true,
}: {
  isPaperComponent?: boolean
}) {
  const { sendCodeMutation } = useAuth()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const username = searchParams.get('username')

  const resendCodeMutation = useMutation({
    mutationFn: (credentials: string) => Auth.resendSignUp(credentials),
  })

  const handleSubmit = (values: typeof initialValues) => {
    sendCodeMutation.mutate({ username: username as string, code: values.code })
  }

  const handleResend = () => {
    resendCodeMutation.mutate(username as string)
  }

  const Component = isPaperComponent ? Paper : Grid
  const componentProps = isPaperComponent
    ? { hasBorder: true }
    : { sx: { p: { xs: 1, sm: 3, md: 7 } } }

  return (
    <Layout maxWidth="sm">
      <Grid container spacing={2} columns={1} justifyContent="center">
        <Grid item>
          <Typography variant="h2" component="h1" textAlign="center">
            {t('account.verification.title')}
          </Typography>
        </Grid>

        <Grid item>
          <Component {...componentProps}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t('account.verification.subHeading', {
                  email: username,
                }),
              }}
            />
            <Formik
              initialValues={initialValues}
              validationSchema={verificationCodeDetailsSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <Grid
                    container
                    spacing={3}
                    columns={1}
                    my={1}
                    justifyContent="start"
                    flexDirection="row-reverse"
                  >
                    <Grid item xs={1} mb={2}>
                      <TextInput
                        id="code"
                        name="code"
                        label={t('account.verification.label')}
                        placeholder={t(
                          'account.verification.verificationCodePlaceholder'
                        )}
                        maxLength={6}
                        required
                        inputMode="numeric"
                      />
                    </Grid>

                    {resendCodeMutation.isError && (
                      <Grid item xs={12}>
                        <Alert title={t('common.error')}>
                          {(resendCodeMutation.error as Error).message ??
                            t('common.errorUnknown')}
                        </Alert>
                      </Grid>
                    )}
                    {sendCodeMutation.isError && (
                      <Grid item xs={12}>
                        <Alert title={t('common.error')}>
                          {(sendCodeMutation.error as Error).message ??
                            t('common.errorUnknown')}
                        </Alert>
                      </Grid>
                    )}
                    {resendCodeMutation.isSuccess ? (
                      <Grid item xs={1}>
                        <Alert severity="success">
                          {t('account.verification.resent')}
                        </Alert>
                      </Grid>
                    ) : (
                      <Grid item container justifyContent="flex-end" xs={6}>
                        <Link
                          onClick={handleResend}
                          type="button"
                          variant="subtitle2"
                        >
                          {t('account.verification.resend')}
                        </Link>
                      </Grid>
                    )}
                    <Grid item container xs={6} alignSelf="start">
                      <LoadingButton
                        loading={
                          sendCodeMutation.isPending ||
                          resendCodeMutation.isPending ||
                          sendCodeMutation.isSuccess
                        }
                        type="submit"
                      >
                        {t('account.verification.logIn')}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Component>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default VerificationCode
