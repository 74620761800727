import { useTranslation } from 'react-i18next'

import { getRegionByCountry, priorityCountryList } from 'lib/countryRegion'
import { useFormikContext } from 'lib/formik'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import { SetLoanAmountProps } from 'views/member/setLoan/SetLoanAmount'
import AutocompleteInput from 'components/mui/AutocompleteInput'
import BorderGridLayout from 'components/BorderGridLayout'
import Paper from 'components/mui/Paper/Paper'
import RequiredNote from 'components/RequiredNote'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import TextInput from 'components/mui/TextInput'

const IdentificationInformationForm = () => {
  const { values, setFieldValue } = useFormikContext<SetLoanAmountProps>()
  const { t } = useTranslation()
  const regions = getRegionByCountry(values.country)

  return (
    <BorderGridLayout item container rowSpacing={3} mb={{ xs: 4, sm: 6 }}>
      <Typography variant="h3">
        {t('offer.loan.paymentInformation.identificationInformation.heading')}
      </Typography>
      <Grid item xs={12}>
        <RequiredNote />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="firstName"
          name="firstName"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.firstName'
          )}
          placeholder={t(
            'offer.loan.paymentInformation.identificationInformation.firstNamePlaceholder'
          )}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="lastName"
          name="lastName"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.lastName'
          )}
          placeholder={t(
            'offer.loan.paymentInformation.identificationInformation.lastNamePlaceholder'
          )}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="streetAddress"
          name="streetAddress"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.address'
          )}
          placeholder={t(
            'offer.loan.paymentInformation.identificationInformation.addressPlaceholder'
          )}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="streetAddress2"
          name="streetAddress2"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.apartment'
          )}
          placeholder={t(
            'offer.loan.paymentInformation.identificationInformation.apartmentPlaceholder'
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="city"
          name="city"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.city'
          )}
          placeholder={t(
            'offer.loan.paymentInformation.identificationInformation.cityPlaceholder'
          )}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          id="country"
          name="country"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.country'
          )}
          placeholder={t('common.selectPlaceholder')}
          options={priorityCountryList}
          onChangeCallback={() => {
            setFieldValue('state', '')
          }}
          inputProps={{
            autoComplete: 'country-name',
          }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          id="state"
          name="state"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.state'
          )}
          placeholder={t('common.selectPlaceholder')}
          options={regions}
          disabled={!values.country}
          inputProps={{
            autoComplete: 'address-level1',
          }}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="zipCode"
          name="zipCode"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.zipCode'
          )}
          placeholder={t(
            'offer.loan.paymentInformation.identificationInformation.zipCodePlaceholder'
          )}
          required
        />
      </Grid>
      {/* <Grid item xs={12}>
        <TextInput
          id="SSN"
          name="SSN"
          type="tel"
          label={t(
            'offer.loan.paymentInformation.identificationInformation.socialSecurityNumber'
          )}
          placeholder={t(
            'offer.loan.paymentInformation.identificationInformation.socialSecurityNumberPlaceholder'
          )}
          required
        />
      </Grid> */}
    </BorderGridLayout>
  )
}

//TODO: Disable for now
// const BankingInformationForm = () => {
//   const { t } = useTranslation()

//   return (
//     <BorderGridLayout item container rowSpacing={3} mb={{ xs: 4, sm: 4 }}>
//       <Typography variant="h3">
//         {t('offer.loan.paymentInformation.bankInformation.heading')}
//       </Typography>
//       <Grid item xs={12}>
//         <RequiredNote />
//       </Grid>

//       <Grid item xs={12}>
//         <TextInput
//           id="bankAccountNumber"
//           name="bankAccountNumber"
//           label={t('offer.loan.paymentInformation.bankInformation.bankAccount')}
//           placeholder={t(
//             'offer.loan.paymentInformation.bankInformation.bankAccountPlaceholder'
//           )}
//           required
//         />
//       </Grid>
//       <Grid item xs={12}>
//         <TextInput
//           id="ABA_routingNumber"
//           name="ABA_routingNumber"
//           label={t('offer.loan.paymentInformation.bankInformation.routing')}
//           placeholder={t(
//             'offer.loan.paymentInformation.bankInformation.routingPlaceholder'
//           )}
//           required
//         />
//       </Grid>
//     </BorderGridLayout>
//   )
// }

interface Props {
  handleBack: () => void
}

function PaymentInformation({ handleBack }: Props) {
  return (
    <Grid container rowGap={3}>
      <Grid item container xs={12} smd={8}>
        <Paper>
          <IdentificationInformationForm />
          {/* <BankingInformationForm /> */}

          <Grid item xs={12}>
            <ResponsiveStepperButtons handleBack={handleBack} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PaymentInformation
