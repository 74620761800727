import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid/Grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useAuth } from 'app/Auth'
import { useUser } from 'app/User'
import { DEFAULT_ROOT_ROUTE } from 'app/Routing'
import { DEFAULT_MEMBER_DASHBOARD_ROUTE } from 'routes/MemberDashboard'
import { ResponsiveBarMenuItem, ResponsiveBarMenuItems } from 'common/types'
import {
  FullLogoLinkButton,
  // SymbolLogoLinkButton,
} from 'components/LogoLinkButtons'
import UserAvatarMenu from 'components/headerBar/UserAvatarMenu'
import theme from 'styles/customTheme'
import styles from './responsiveHeaderBar.module.scss'
import {
  DEFAULT_ADMIN_ROUTE,
  DEFAULT_ADMIN_MEMBERS_ROUTE,
} from 'routes/AdminDashboard'

type Props = {
  children: JSX.Element
  showNavItems?: boolean
}

const HeaderBar = ({ children, showNavItems = true }: Props) => {
  const { t } = useTranslation()
  const { isLoggedIn } = useAuth()
  const {
    roles: { activeRole },
  } = useUser()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const smallScreenBreakpoint = 'md'
  const isSmallScreenBreakpoint = useMediaQuery(
    theme.breakpoints.down(smallScreenBreakpoint)
  )

  const navMenuItemsConfig: { [s: string]: ResponsiveBarMenuItems } =
    useMemo(() => {
      const items = {
        ADMIN: [
          {
            path: `${DEFAULT_ADMIN_MEMBERS_ROUTE}`,
            text: t('liquidlpAdmin.appBar.pages.members'),
          },
          {
            path: `${DEFAULT_ADMIN_ROUTE}/applications`,
            text: t('liquidlpAdmin.appBar.pages.applications'),
          },
        ],
        USER: [
          {
            path: DEFAULT_MEMBER_DASHBOARD_ROUTE,
            text: t('member.dashboard.pages.overview'),
          },
        ],
      }

      return items
    }, [t])

  const menuItems = activeRole && navMenuItemsConfig[activeRole]

  const isMenuItems = menuItems && menuItems.length >= 1
  //  && //TODO add this back when we have signed agreement
  // (hasSignedCompanyAgreement || !FEATURE_FLAGS.SIGN_COMPANY_AGREEMENT_LETTER)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const FullScreenNavLink = ({ path, text }: ResponsiveBarMenuItem) => (
    <NavLink
      to={path}
      style={{ margin: '0 0.8rem' }}
      className={({ isActive }) =>
        classNames(styles.navLink, { [styles.linkActive]: isActive })
      }
    >
      <Typography variant="body2">{text}</Typography>
    </NavLink>
  )

  const MobileNavLink = ({ path, text }: ResponsiveBarMenuItem) => (
    <MenuItem onClick={handleCloseNavMenu} sx={{ p: 0 }}>
      <NavLink
        to={path}
        className={({ isActive }) =>
          classNames(styles.mobileNavLink, {
            [styles.mobileLinkActive]: isActive,
          })
        }
      >
        <Typography>{text}</Typography>
      </NavLink>
    </MenuItem>
  )

  const LogoButton = () => (
    // isSmallScreenBreakpoint ? (
    //   <SymbolLogoLinkButton path={DEFAULT_ROOT_ROUTE} />
    // ) : (
    <FullLogoLinkButton
      path={DEFAULT_ROOT_ROUTE}
      sx={{ m: 0, mr: 1, p: 0 }}
      isWhiteLogo
    />
  )
  // )

  return (
    <div className={styles.root}>
      <AppBar
        position="static"
        sx={{
          '&.MuiAppBar-colorPrimary': {
            backgroundColor: 'primary.main',
          },
        }}
        elevation={0}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid container alignItems="center">
              <Grid item width="auto">
                <LogoButton />
              </Grid>
              {showNavItems && (
                <>
                  <Grid item flex={1} ml={2}>
                    {isMenuItems && (
                      <>
                        {isSmallScreenBreakpoint ? (
                          <Box sx={{ flexGrow: 1 }}>
                            <IconButton
                              size="large"
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={handleOpenNavMenu}
                              color="inherit"
                            >
                              <MenuIcon />
                            </IconButton>
                            <Menu
                              id="menu-appbar"
                              anchorEl={anchorElNav}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              open={Boolean(anchorElNav)}
                              onClose={handleCloseNavMenu}
                              sx={{
                                display: {
                                  xs: 'block',
                                  [smallScreenBreakpoint]: 'none',
                                },
                              }}
                            >
                              {menuItems.map((menuItem) => (
                                <MobileNavLink
                                  key={menuItem.text}
                                  path={menuItem.path}
                                  text={menuItem.text}
                                />
                              ))}
                            </Menu>
                          </Box>
                        ) : (
                          <Box sx={{ flexGrow: 1, mt: 1, display: 'flex' }}>
                            {menuItems.map((menuItem) => (
                              <FullScreenNavLink
                                key={menuItem.text}
                                path={menuItem.path}
                                text={menuItem.text}
                                icon={menuItem.icon}
                              />
                            ))}
                          </Box>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item width="auto">
                    {isLoggedIn ? (
                      <Grid
                        container
                        columnGap={{ xs: 2, sm: 6 }}
                        my={{ xs: 1, sm: 0 }}
                      >
                        <UserAvatarMenu />
                      </Grid>
                    ) : undefined}
                  </Grid>
                </>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </div>
  )
}
export default HeaderBar
