import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box/Box'
import DateRangeIcon from '@mui/icons-material/DateRange'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import QueryBoundary from 'app/QueryBoundary'
import useSofrRate from 'hooks/useSofrRate'
import { useGetApplicationQuery } from 'lib/apollo/hooks'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { loanRepaymentSchedules } from 'lib/formConfig'
import { getLabelByValue } from 'lib/helpers'
import { DEFAULT_MEMBER_ROUTE } from 'routes/MemberDashboard'

import BackLink from 'components/BackLink'
import Button from 'components/mui/Button'
import Layout from 'components/Layout/Layout'
import OfferDetailsCard, { LoanDetails } from 'components/loan/OfferDetailsCard'
import Paper from 'components/mui/Paper/Paper'

function ApplicationOfferDetails() {
  const { t } = useTranslation()
  const { applicationId } = useParams()

  const sofrRateQuery = useSofrRate()
  const sofrRate = sofrRateQuery.data?.percentRate

  const applicationQuery = useGetApplicationQuery(applicationId)
  const { data } = applicationQuery
  const application = data?.getApplication

  const financingInfo = application?.financingInfo

  const totalInterestRate = useMemo(
    () => financingInfo?.interestRate + sofrRate,
    [financingInfo?.interestRate, sofrRate]
  )

  const header = {
    title: t('offer.details.title'),
    description: t('offer.details.description'),
  }

  const loanDetails: LoanDetails = [
    {
      heading: t('offer.details.loan.label'),
      isLargeHeading: true,
      data: t('common.intlCurrencySimplifiedFormat', {
        value: application?.financingInfo?.commitment,
      }),
      notesElement: (
        <Box alignContent="center" display="flex" color="secondary.lighter">
          <DateRangeIcon sx={{ mr: 1.5 }} />
          <Typography pt={0.25} color="secondary.lighter">
            {t('offer.details.loan.note', {
              date: dayjs(financingInfo?.offerEndDate).format(
                monthDayFullYearSimplifiedFormat
              ),
            })}
          </Typography>
        </Box>
      ),
    },
    {
      heading: t('offer.details.loanType.label'),
      data: t('offer.details.loanType.value'),
      notes: t('offer.details.loanType.note'),
    },
    {
      heading: t('offer.details.loanTerm.label'),
      data: t('common.monthWithCount', {
        count: financingInfo?.financingTerm?.length,
      }),
    },
    {
      heading: t('offer.details.originationFee.label'),
      data: t('offer.details.originationFee.value', {
        value: financingInfo?.originationFee,
        amount: t('common.intlCurrencySimplifiedFormat', {
          value:
            ((financingInfo?.originationFee ?? 0) / 100) *
            (application?.financingInfo?.commitment ?? 0),
        }),
      }),
      notes: t('offer.details.originationFee.note'),
    },
    {
      heading: t('offer.details.loanInterestRate.label'),
      data: t('offer.details.loanInterestRate.value', {
        interestRate: financingInfo?.interestRate,
      }),
      notes: t('offer.details.loanInterestRate.note', {
        interestRate: totalInterestRate,
      }),
    },
    {
      heading: t('offer.details.repaymentSchedule.label'),
      data: financingInfo?.loanRepaymentFrequency
        ? getLabelByValue(
            financingInfo?.loanRepaymentFrequency,
            loanRepaymentSchedules
          )
        : '-',
      notes: t(
        `offer.details.repaymentSchedule.${financingInfo?.loanRepaymentFrequency}Note`
      ),
    },
  ]

  return (
    <QueryBoundary queries={[applicationQuery, sofrRateQuery]}>
      <Layout maxWidth="sm">
        <BackLink to={DEFAULT_MEMBER_ROUTE} />

        <Grid item xs={12} mb={4}>
          <Typography variant="h1" textAlign="center" mb={2}>
            {t('offer.heading', {
              name: `${application?.firstName} ${application?.lastName}`,
            })}
          </Typography>
          <Typography variant="h5" textAlign="center">
            {t('offer.subHeading')}
          </Typography>
        </Grid>

        <Paper hasBorder>
          <Grid item container xs={12} rowGap={3}>
            {/* Offer details */}
            <Grid item container>
              <OfferDetailsCard loanDetails={loanDetails} header={header} />
            </Grid>

            {application?.currentStage !== 'TERMS_REQUESTED' && (
              <Grid item mt={2}>
                <Button
                  mode="forward"
                  to={`${DEFAULT_MEMBER_ROUTE}/set-loan/${application?._id}`}
                >
                  {t('offer.button')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Layout>
    </QueryBoundary>
  )
}

export default ApplicationOfferDetails
