import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { LabelValuePair } from 'common/types'

interface Props {
  details: LabelValuePair[]
  title?: string
}

function LabelValueComponent({ details, title }: Props) {
  return (
    <Grid item container flexDirection="column" rowSpacing={3}>
      {title && (
        <Grid item>
          <Typography variant="body2">{title}</Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container rowSpacing={2.5}>
          {details.map(({ label, value, address, display = true, icon }) => {
            const Icon = icon

            return (
              display && (
                <Grid key={label} item xs={12}>
                  <Typography variant="h6" color="secondary.lighter">
                    {label}
                  </Typography>
                  {value && Array.isArray(value) ? (
                    value.map((item) => (
                      <Typography key={item}>{item}</Typography>
                    ))
                  ) : (
                    <Grid container>
                      <Typography>
                        {value ? value : !address ? '-' : ''}
                      </Typography>
                      {icon && Icon}
                    </Grid>
                  )}
                  {address &&
                    (![
                      address.streetAddress,
                      address.city,
                      address.state,
                      address.zipCode,
                      address.country,
                    ].some(Boolean) ? (
                      <Typography>-</Typography>
                    ) : (
                      <>
                        {
                          <Typography>
                            {address.streetAddress ?? '-'}
                          </Typography>
                        }
                        {<Typography>{address.streetAddress2}</Typography>}
                        {
                          <Typography>
                            {`${address.city ?? ''}${address.city ? ', ' : ''}${
                              address.state ?? ''
                            }`}
                          </Typography>
                        }
                        {<Typography>{address.zipCode ?? '-'}</Typography>}
                        {<Typography>{address.country ?? '-'}</Typography>}
                      </>
                    ))}
                </Grid>
              )
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LabelValueComponent
