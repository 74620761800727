import Grid from '@mui/material/Grid'
import Table, { TableProps } from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter/TableFooter'
import TableHead from '@mui/material/TableHead'
import TableRow, { TableRowProps } from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import Typography from '@mui/material/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import Paper from 'components/mui/Paper/Paper'
import theme from 'styles/customTheme'
import { HeadCell, responsiveCheckboxPadding } from './ResponsiveTable'
import { useMemo, useState } from 'react'

export type SortableHeadCell = HeadCell & {
  sortable?: boolean
}

export type SortableHeadCells = SortableHeadCell[]

type Props<I> = TableProps & {
  ariaLabel: string
  headCells: SortableHeadCell[]
  isCheckbox?: boolean
  isPaperComponent?: boolean
  list: I[]
  row: (row: I) => JSX.Element
  rowsPerPage?: number
  defaultSort?: keyof I
  defaultDirection?: 'asc' | 'desc'
}

export function PaginatedTableRow({ children, ...props }: TableRowProps) {
  return (
    <TableRow
      tabIndex={-1}
      sx={{
        borderBottom: { xs: 0.5, smd: 'none' },
        borderColor: { xs: 'secondary.light' },
        '&:hover': {
          cursor: props.onClick ? 'pointer' : 'default',
        },
      }}
      {...props}
    >
      {children}
    </TableRow>
  )
}

export default function PaginatedTable<I extends Record<string, any>>({
  ariaLabel,
  headCells,
  isCheckbox = false,
  isPaperComponent = true,
  list = [],
  row,
  rowsPerPage = 25,
  defaultSort,
  defaultDirection,
  ...props
}: Props<I>) {
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState(defaultSort)
  const [direction, setDirection] = useState(defaultDirection ?? 'asc')

  const isSmdDown = useMediaQuery(theme.breakpoints.down('smd'))

  const WrapperComponent = isPaperComponent ? Paper : Grid

  const slice = useMemo(() => {
    const d = direction === 'asc' ? 1 : -1
    const sorted = sort
      ? [...list].sort((a, b) => {
          let akey = a[sort]
          let bkey = b[sort]
          if (typeof akey === 'string') akey = akey.toLowerCase()
          if (typeof bkey === 'string') bkey = bkey.toLowerCase()
          return akey > bkey ? d : akey < bkey ? -d : 0
        })
      : list
    return sorted.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  }, [list, page, rowsPerPage, sort, direction])

  return (
    <WrapperComponent
      sx={{
        p: 0,
        width: '100%',
      }}
    >
      <TableContainer>
        <Table
          aria-label={ariaLabel}
          stickyHeader
          size={isSmdDown ? 'small' : 'medium'}
          {...props}
        >
          <TableHead>
            <TableRow
              sx={{
                verticalAlign: 'top',
                '.MuiTableCell-root': {
                  backgroundColor: '#fff',
                  borderBottom: 0.5,
                  borderColor: 'secondary.light',
                },
              }}
            >
              {isCheckbox && (
                <TableCell sx={{ ...responsiveCheckboxPadding }} />
              )}
              {headCells?.map((headCell: SortableHeadCell) => (
                <TableCell
                  key={headCell.id}
                  style={{
                    borderBottom:
                      headCell.headCellBorderColor &&
                      `4px solid ${headCell.headCellBorderColor}`,
                    color: '#B5B7C0',
                  }}
                  sx={{
                    width: headCell.width,
                    backgroundColor: isPaperComponent
                      ? headCell.backgroundColor
                      : 'transparent',
                  }}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={sort === headCell.id}
                      direction={sort === headCell.id ? direction : 'asc'}
                      onClick={() => {
                        setSort(headCell.id)
                        setDirection(
                          sort === headCell.id && direction === 'asc'
                            ? 'desc'
                            : 'asc'
                        )
                      }}
                    >
                      <Typography variant="body2">{headCell.label}</Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography variant="body2">{headCell.label}</Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{slice.map(row)}</TableBody>
          <TableFooter
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              '.MuiTableCell-root': {
                borderTop: 0.5,
                borderBottom: 'none',
                borderColor: 'secondary.light',
              },
            }}
          >
            <TableRow>
              <TablePagination
                rowsPerPage={rowsPerPage}
                count={list.length}
                page={page}
                onPageChange={(_e, page) => setPage(page)}
                rowsPerPageOptions={[rowsPerPage]}
                showFirstButton={list.length > rowsPerPage * 2}
                showLastButton={list.length > rowsPerPage * 2}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </WrapperComponent>
  )
}
