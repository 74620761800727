import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import i18n from 'locale/i18n'

import { getApplicationOfferStage } from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'

import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

import { DEFAULT_ADMIN_ROUTE } from 'routes/AdminDashboard'
import { Application } from 'common/types'
import PaginatedTable, {
  SortableHeadCells,
  PaginatedTableRow,
} from 'components/mui/PaginatedTable'

function ApplicationsTableRow({
  application,
  currentStage,
}: {
  application: Application
  currentStage: Application['currentStage']
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNavigate = (applicationId: string) =>
    navigate(`${DEFAULT_ADMIN_ROUTE}/applications/${applicationId}`)

  const { isApplicationOfferAccepted, isApplicationPreApproved } = useMemo(
    () => getApplicationOfferStage(currentStage),
    [currentStage]
  )

  return (
    <PaginatedTableRow onClick={() => handleNavigate(application._id)} hover>
      <TableCell component="th" id={application._id} scope="row">
        <Typography>
          {dayjs(application?.createdAt).format(
            monthDayFullYearSimplifiedFormat
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {application?.financingInfo?._id
            ? dayjs(application.financingInfo?.offerEndDate).format(
                monthDayFullYearSimplifiedFormat
              )
            : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{`${application?.firstName} ${application?.lastName}`}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {application?.amount !== null
            ? t('common.intlCurrencySimplifiedFormat', {
                value: application?.amount,
              })
            : '-'}
        </Typography>
      </TableCell>
      {(isApplicationOfferAccepted || isApplicationPreApproved) && (
        <TableCell>
          <Typography>
            {application?.financingInfo?.commitment !== null
              ? t('common.intlCurrencySimplifiedFormat', {
                  value: application?.financingInfo?.commitment,
                })
              : '-'}
          </Typography>
        </TableCell>
      )}
      {isApplicationOfferAccepted && (
        <TableCell>
          <Typography>
            {application?.actualAmount !== null
              ? t('common.intlCurrencySimplifiedFormat', {
                  value: application?.actualAmount,
                })
              : '-'}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        <Typography>{`${application?.user?.firstName} ${application?.user?.lastName}`}</Typography>
      </TableCell>
    </PaginatedTableRow>
  )
}

export default function ApplicationsTable({
  applications,
  currentStage = 'UNDER_REVIEW',
}: {
  applications: Application[]
  currentStage?: Application['currentStage']
}) {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { userId } = useParams()

  const filteredApplications = useMemo(
    () =>
      search
        ? applications.filter((a) =>
            `${a.user.firstName} ${a.user.lastName} ${a.firstName} ${a.lastName} ${a.companyName}`
              .toLowerCase()
              .match(search.toLowerCase())
          )
        : applications,
    [applications, search]
  )

  const { isApplicationOfferAccepted, isApplicationPreApproved } = useMemo(
    () => getApplicationOfferStage(currentStage),
    [currentStage]
  )

  const headCells: SortableHeadCells = [
    {
      id: 'createdAt',
      label: i18n.t('liquidlpAdmin.applications.table.tableHeadCell1'),
      width: '12%',
      sortable: true,
    },
    {
      id: 'offerEndDate',
      label: i18n.t('liquidlpAdmin.applications.table.tableHeadCell2'),
      width: '12%',
    },
    {
      id: 'firstName',
      label: i18n.t('liquidlpAdmin.applications.table.tableHeadCell3'),
      width: '12%',
      sortable: true,
    },
    {
      id: 'amount',
      label: i18n.t('liquidlpAdmin.applications.table.tableHeadCell4'),
      width: '15%',
      sortable: true,
    },
    ...(isApplicationOfferAccepted || isApplicationPreApproved
      ? [
          {
            id: 'preauthorizedAmount',
            label: i18n.t('liquidlpAdmin.applications.table.tableHeadCell5'),
            width: '15%',
          },
        ]
      : []),
    ...(isApplicationOfferAccepted
      ? [
          {
            id: 'actualAmount',
            label: i18n.t('liquidlpAdmin.applications.table.tableHeadCell6'),
            width: '12%',
          },
        ]
      : []),

    {
      id: 'user.firstName',
      label: i18n.t('liquidlpAdmin.applications.table.tableHeadCell7'),
      width: '15%',
    },
  ]

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        mb={{ xs: 0, sm: 2 }}
      >
        <Grid item>
          <Typography variant="h2" ml={{ xs: 0, sm: 2 }} mb={{ xs: 0, sm: 2 }}>
            {t(
              `liquidlpAdmin.applications.table.${
                userId ? 'headingMember' : 'heading'
              }`
            )}
          </Typography>
        </Grid>

        <Grid item xs={true} sx={{ textAlign: 'right' }}>
          <TextField
            placeholder={t('liquidlpAdmin.allMembersTable.search')}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <PaginatedTable
        ariaLabel={t('liquidlpAdmin.applications.table.ariaLabel')}
        headCells={headCells}
        isPaperComponent={false}
        rowsPerPage={10}
        list={filteredApplications}
        row={(application) => (
          <ApplicationsTableRow
            key={application._id}
            application={application}
            currentStage={currentStage}
          />
        )}
        defaultSort="createdAt"
        defaultDirection="desc"
      />
    </>
  )
}
