import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'

import { useUser } from 'app/User'
import { Application, CompanyDocumentUploads, UserType } from 'common/types'
import { Form, Formik, FormikHelpers, useFormikContext } from 'lib/formik'
import {
  UPLOAD_DOCUMENTS_RIA_LIST,
  UPLOAD_DOCUMENTS_GP_LIST,
} from 'lib/formConfig'
import { uploadDocumentsSchema } from 'lib/validation'
import useFileUpload from 'hooks/useFileUpload'

import AlertDialog from 'components/AlertDialog'
import Paper from 'components/mui/Paper/Paper'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import { ApplicationInputForm } from 'views/member/RiaNewApplication'
import UploadDocumentsForm from 'views/companyAdmin/companyInfo/UploadDocumentsForm'

export const CREATE_COMPANY_ROOT_ROUTE = '/account/company'

interface Props {
  type: UserType
  application?: Application
  applicationId: string
  handlePrevious?: () => void
  handleSaveNext: (values: ApplicationInputForm) => void
  isSubmittingApplication?: boolean
}

function UploadDisclosureDocuments({
  type,
  application,
  applicationId,
  handlePrevious,
  handleSaveNext,
  isSubmittingApplication,
}: Props) {
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const { t } = useTranslation()

  const { values: formValues } = useFormikContext<ApplicationInputForm>()

  const {
    data: { _id },
  } = useUser()

  const fileUploadMutation = useFileUpload(_id, applicationId)
  const fileList = useMemo(
    () =>
      type === 'GP' ? UPLOAD_DOCUMENTS_GP_LIST : UPLOAD_DOCUMENTS_RIA_LIST,
    [type]
  )

  const initialFormData = useMemo(
    () =>
      fileList.reduce((list, { name }) => {
        list[name] = null
        return list
      }, {} as CompanyDocumentUploads),
    [fileList]
  )

  const handleSubmitFiles = async (
    values: Record<string, File[]>,
    formik?: FormikHelpers<CompanyDocumentUploads>
  ) => {
    const filesToUpload = fileList
      .filter(
        (document) =>
          values.hasOwnProperty(document.name) && values[document.name]
      )
      .reduce(
        (files, document) => [
          ...files,
          ...values[document.name].map((file, n) => ({
            file,
            name: document.name + (n > 0 ? `-${n + 1}` : ''),
          })),
        ],
        [] as { file: File; name: string }[]
      )

    if (filesToUpload.length > 0) {
      await fileUploadMutation.mutateAsync(
        {
          endpoint: 'companyfilesupload',
          documents: filesToUpload,
        },
        {
          onSuccess: (data) => {
            const documentIds = (data as any)?.data?.map(
              (file: any) => file._id
            )

            handleSaveNext({
              ...formValues,
              documents: documentIds,
            })
          },
        }
      )
    } else
      handleSaveNext({
        ...formValues,
      })

    formik?.setTouched({}, false)
    formik?.resetForm()
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    fileUploadMutation.reset()
  }

  useEffect(() => {
    if (fileUploadMutation.isSuccess) {
      console.log({ fileUploadMutation })
    }
  }, [fileUploadMutation, fileUploadMutation.isSuccess])

  useEffect(() => {
    if (fileUploadMutation.isError) {
      setShowAlertDialog(true)
    }
  }, [fileUploadMutation.isError])

  return (
    <Grid container>
      <Grid item container xs={12} smd={8}>
        <Paper hasBorder>
          <Formik
            initialValues={initialFormData}
            validationSchema={uploadDocumentsSchema(fileList)}
            onSubmit={handleSubmitFiles}
          >
            {({ values, isValid }) => (
              <>
                <Form promptOnLeave>
                  <UploadDocumentsForm type={type} />

                  <Grid item xs={12}>
                    <ResponsiveStepperButtons
                      isSubmitting={isSubmittingApplication}
                      isUploading={fileUploadMutation.isPending}
                      uploadProgress={fileUploadMutation.progress}
                      nextStepText={t('disclosureDocuments.form.submitButton')}
                      handleForward={() => handleSubmitFiles(values)}
                      handleBack={() => handlePrevious?.()}
                      isForwardDisabled={
                        (!Object.values(values).some((v: any) => v) ||
                          !isValid) &&
                        !application?.documents?.length
                      }
                    />
                  </Grid>
                </Form>
                {/* Shows mutation error when submitting form */}
                <AlertDialog
                  isOpen={showAlertDialog}
                  onClose={() => setShowAlertDialog(false)}
                  primaryButtonAction={() => {
                    handleSubmitFiles(values)
                    setShowAlertDialog(false)
                  }}
                  secondaryButtonAction={handleResetMutation}
                />
                <AlertDialog
                  isOpen={showToast}
                  mode="success"
                  title={t('disclosureDocuments.form.success.title')}
                  description={t(
                    'disclosureDocuments.form.success.description'
                  )}
                  onClose={() => setShowToast(false)}
                  primaryButtonText="OK"
                  primaryButtonAction={() => setShowToast(false)}
                />
              </>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default UploadDisclosureDocuments
