import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { SOFR_RATE_API_URL } from 'lib/config'

const useSofrRate = () =>
  useQuery({
    queryKey: ['sofrRate'],
    queryFn: () => axios.get(SOFR_RATE_API_URL).then((res) => res.data),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60 * 6,
    gcTime: Infinity,
    refetchInterval: 1000 * 60 * 60 * 12,
  })

export default useSofrRate
