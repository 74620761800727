import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Application } from 'common/types'
import {
  useCreateFinancingInfo,
  useUpdateFinancingInfo,
} from 'lib/apollo/hooks'
import { calculatePreauthorizedAmount } from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { loanDurationOptions, loanRepaymentSchedules } from 'lib/formConfig'
import { Form, Formik, FormikHelpers } from 'lib/formik'
import { financingInfoSchema } from 'lib/validation'

import AlertDialog from 'components/AlertDialog'
import BorderGridLayout from 'components/BorderGridLayout'
import Button from 'components/mui/Button'
import DateInput from 'components/mui/DateInput'
import LoadingButton from 'components/mui/LoadingButton'
import RequiredNote from 'components/RequiredNote'
import SelectInput from 'components/mui/SelectInput'
import TextInput from 'components/mui/TextInput'

export interface FinancingInfoInputForm {
  assetsPledged: string
  preauthorizedAmount: number
  ltv: number
  originationFee: number
  interestRate: number
  financingTermLength: string
  loanRepaymentFrequency: string
  offerEndDate: string
}

function FinancingForm({ application }: { application: Application }) {
  const { t } = useTranslation()
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const [createFinancingInfo, createFinancingInfoMutation] =
    useCreateFinancingInfo(application._id)
  const [updateFinancingInfo, updateFinancingInfoMutation] =
    useUpdateFinancingInfo(application._id)

  const { financingInfo } = application

  const isUpdating = application.financingInfo !== null
  const isSubmitting =
    createFinancingInfoMutation.loading || updateFinancingInfoMutation.loading

  const isMutationError =
    createFinancingInfoMutation.error !== undefined ||
    updateFinancingInfoMutation.error !== undefined

  const handleClearParams = useCallback(() => {
    searchParams.delete('status')
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    createFinancingInfoMutation.reset()
    updateFinancingInfoMutation.reset()
  }

  const handleSubmit = async (
    values: FinancingInfoInputForm,
    formik?: FormikHelpers<FinancingInfoInputForm>
  ) => {
    const data = {
      financingInfo: {
        fundSize: values.assetsPledged,
        commitment: calculatePreauthorizedAmount({
          assetsPledged: Number(values.assetsPledged),
          LTV: Number(values.ltv),
        }),
        LTV: Number(values.ltv),
        estimatedLoanAvailable: 0,
        originationFee: Number(values.originationFee),
        interestRate: Number(values.interestRate),
        financingTerm: {
          //Remove when backend is updated
          start: dayjs().toISOString(),
          end: dayjs().toISOString(),
          length: values.financingTermLength,
        },
        offerEndDate: dayjs(values.offerEndDate).toISOString(),
        loanRepaymentFrequency: values.loanRepaymentFrequency,
      },
    }

    if (!createFinancingInfoMutation.data && !isUpdating) {
      const { errors } = await createFinancingInfo({
        variables: {
          financingInfo: data.financingInfo,
          applicationId: application._id,
        },
      })
      if (errors) return
    }

    if (!updateFinancingInfoMutation.data && isUpdating) {
      const { errors } = await updateFinancingInfo({
        variables: {
          financingInfo: data.financingInfo,
          applicationId: application._id,
        },
        onCompleted: () => handleClearParams(),
      })

      if (errors) return
      formik?.resetForm()
    }

    formik?.setTouched({}, false)
    updateFinancingInfoMutation.reset()
  }

  useEffect(() => {
    if (isMutationError) {
      setShowAlertDialog(true)
    }
  }, [isMutationError])

  const initialValues = {
    assetsPledged: financingInfo?.fundSize ?? '',
    preauthorizedAmount: financingInfo?.commitment ?? '',
    ltv: financingInfo?.LTV ?? '',
    originationFee: financingInfo?.originationFee ?? '',
    interestRate: financingInfo?.interestRate ?? '',
    financingTermLength: financingInfo?.financingTerm?.length ?? '',
    loanRepaymentFrequency: financingInfo?.loanRepaymentFrequency ?? '',
    offerEndDate: financingInfo?.offerEndDate
      ? dayjs(financingInfo?.offerEndDate).format(
          monthDayFullYearSimplifiedFormat
        )
      : '',
  } as FinancingInfoInputForm

  return (
    <BorderGridLayout container maxWidth="420px">
      <Typography variant="h3" mb={2}>
        {t('liquidlpAdmin.applications.financingInfo.heading')}
      </Typography>

      <RequiredNote />

      <Formik
        initialValues={initialValues}
        validationSchema={financingInfoSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values }) => (
          <Form promptOnLeave={!isUpdating}>
            <Grid container rowSpacing={3} mt={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  {t(
                    'liquidlpAdmin.applications.applicationInfo.loan.labels.amount'
                  )}
                </Typography>
                <Typography ml={2}>
                  {application?.amount !== null
                    ? t('common.intlCurrencySimplifiedFormat', {
                        value: application?.amount,
                      })
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="assetsPledged"
                  name="assetsPledged"
                  label={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.assetsPledged'
                  )}
                  placeholder={t('common.decimalPlaceholder')}
                  helperText={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.assetsPledgedHelperText'
                  )}
                  delimiter="$"
                  inputMode="decimal"
                  isNumericFormat
                  hasDecimalScale={false}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="ltv"
                  name="ltv"
                  label={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.ltv'
                  )}
                  helperText={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.ltvHelperText'
                  )}
                  placeholder={t('common.numberPlaceholder')}
                  delimiter="%"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  {t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.preauthorizedAmount'
                  )}
                </Typography>
                <Typography ml={2}>
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: calculatePreauthorizedAmount({
                      assetsPledged: Number(values.assetsPledged),
                      LTV: Number(values.ltv),
                    }),
                  })}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="secondary.dark"
                  ml={1}
                  my={1}
                >
                  {t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.preauthorizedAmountHelperText'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="originationFee"
                  name="originationFee"
                  label={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.originationFee'
                  )}
                  helperText={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.originationHelperText'
                  )}
                  placeholder={t('common.decimalPlaceholder')}
                  delimiter="%"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="interestRate"
                  name="interestRate"
                  label={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.interestRate'
                  )}
                  helperText={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.interestRateHelperText'
                  )}
                  placeholder={t('common.numberPlaceholder')}
                  delimiter="%"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  id="financingTermLength"
                  name="financingTermLength"
                  label={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.financingTerm'
                  )}
                  helperText={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.financingTermHelperText'
                  )}
                  placeholder={t(
                    'member.newApplication.requestedLoanAmount.labels.loanLengthPlaceholder'
                  )}
                  value={values.financingTermLength}
                  options={loanDurationOptions}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  id="loanRepaymentFrequency"
                  name="loanRepaymentFrequency"
                  label={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.loanRepaymentSchedule'
                  )}
                  placeholder={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.loanRepaymentSchedulePlaceholder'
                  )}
                  helperText={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.loanRepaymentScheduleHelperText'
                  )}
                  value={values.loanRepaymentFrequency}
                  options={loanRepaymentSchedules}
                  required
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <DateInput
                  id="offerEndDate"
                  name="offerEndDate"
                  label={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.offerEndDate'
                  )}
                  helperText={t(
                    'liquidlpAdmin.applications.financingInfo.form.labels.offerEndDateHelperText'
                  )}
                  required
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                rowGap={2}
                justifyContent="space-between"
              >
                {isUpdating && (
                  <Button
                    onClick={handleClearParams}
                    variant="outlined"
                    sx={{ mr: 0.5 }}
                  >
                    {t('common.cancel')}
                  </Button>
                )}
                <LoadingButton
                  type="submit"
                  sx={{ ml: 0.5 }}
                  loading={isSubmitting}
                >
                  {isUpdating ? t('common.update') : t('common.save')}
                </LoadingButton>
              </Grid>
            </Grid>

            <AlertDialog
              isOpen={showAlertDialog}
              onClose={() => setShowAlertDialog(false)}
              primaryButtonAction={() => {
                submitForm()
                setShowAlertDialog(false)
              }}
              secondaryButtonAction={handleResetMutation}
              description={createFinancingInfoMutation.error?.message}
            />
          </Form>
        )}
      </Formik>
    </BorderGridLayout>
  )
}

export default FinancingForm
