import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import i18n from 'locale/i18n'
import { getApplicationOfferStage } from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'

import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { DEFAULT_MEMBER_ROUTE } from 'routes/MemberDashboard'
import { Application, UserType } from 'common/types'
import Button from 'components/mui/Button'
import PaginatedTable, {
  PaginatedTableRow,
  SortableHeadCells,
} from 'components/mui/PaginatedTable'
import Paper from 'components/mui/Paper/Paper'
import StatusIndicator from 'components/StatusIndicator'

function MemberApplicationsTableRow({
  application,
  handleNavigate,
  type,
}: {
  application: Application
  handleNavigate: (application: Application) => void
  type: UserType
}) {
  const { t } = useTranslation()
  const { isApplicationOfferAccepted, isApplicationPreApproved } =
    getApplicationOfferStage(application.currentStage)
  const isClickable =
    application.currentStage === 'INITIAL' ||
    application.currentStage === 'MORE_INFORMATION_REQUIRED' ||
    application.currentStage === 'APPROVED'

  const onClickHandler = isClickable
    ? () => handleNavigate(application)
    : undefined

  return (
    <PaginatedTableRow onClick={onClickHandler} hover={isClickable}>
      <TableCell component="th" id={application._id} scope="row">
        <Typography>
          {dayjs(application.createdAt).format(
            monthDayFullYearSimplifiedFormat
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {application?.financingInfo?._id
            ? dayjs(application.financingInfo?.offerEndDate).format(
                monthDayFullYearSimplifiedFormat
              )
            : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{`${application.firstName} ${application.lastName}`}</Typography>
      </TableCell>
      {(type === 'RIA' || type === 'LP' || type === 'Customer') && (
        <>
          <TableCell>
            <Typography>{application.phone}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{application.email}</Typography>
          </TableCell>
        </>
      )}
      {type === 'GP' && (
        <>
          <TableCell>
            <Typography>
              {application?.amount
                ? t('common.intlCurrencySimplifiedFormat', {
                    value: application?.amount,
                  })
                : '-'}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {isApplicationPreApproved || isApplicationOfferAccepted
                ? t('common.intlCurrencySimplifiedFormat', {
                    value: application?.financingInfo?.commitment,
                  })
                : '-'}
            </Typography>
          </TableCell>
        </>
      )}
      <TableCell>
        <StatusIndicator
          status={application.currentStage}
          category="application"
        />
      </TableCell>
    </PaginatedTableRow>
  )
}

export default function MemberApplicationsTable({
  applications,
  type,
}: {
  applications: Application[]
  type: UserType
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  const userType = type.toLowerCase()

  const filteredApplications = useMemo(
    () =>
      search
        ? applications.filter((a) =>
            `${a.firstName} ${a.lastName}`
              .toLowerCase()
              .match(search.toLowerCase())
          )
        : applications,
    [applications, search]
  )

  const handleNavigate = (application: Application) => {
    application?.currentStage === 'APPROVED'
      ? navigate(`${DEFAULT_MEMBER_ROUTE}/offer/${application._id}`)
      : navigate(
          `${DEFAULT_MEMBER_ROUTE}/${userType}/create-application?applicationId=${application._id}`
        )
  }

  const headCells: SortableHeadCells = [
    {
      id: 'createdAt',
      label: i18n.t(`member.applications.${userType}.tableHeadCell1`),
      width: '12%',
      sortable: true,
    },
    {
      id: 'offerEndDate',
      label: i18n.t(`member.applications.${userType}.tableHeadCell2`),
      width: '12%',
    },
    {
      id: 'firstName',
      label: i18n.t(`member.applications.${userType}.tableHeadCell3`),
      width: '15%',
      sortable: true,
    },
    {
      id: 'phoneNumber',
      label: i18n.t(`member.applications.${userType}.tableHeadCell4`),
      width: '12%',
    },
    {
      id: 'email',
      label: i18n.t(`member.applications.${userType}.tableHeadCell5`),
      width: '12%',
    },
    {
      id: 'status',
      label: i18n.t(`member.applications.${userType}.tableHeadCell6`),
      width: '17%',
    },
  ]

  return (
    <Paper hasBorder>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h2" ml={{ xs: 0, sm: 2 }}>
          {t('member.applications.heading')}
        </Typography>

        <Grid item xs={true} sx={{ textAlign: 'right' }}>
          <TextField
            placeholder={t('common.search')}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {type !== 'Customer' && (
          <Grid item>
            <Button
              mode="forward"
              to={`${DEFAULT_MEMBER_ROUTE}/${
                type === 'RIA' ? 'ria/client-type' : userType
              }/create-application`}
              sx={{
                py: 0.6,
                width: 'fit-content',
                mx: 'auto',
                my: 5,
                backgroundColor: 'secondary.darker',
              }}
            >
              {t('member.applications.newApplicationButtonLabel')}
            </Button>
          </Grid>
        )}
      </Grid>

      <PaginatedTable
        ariaLabel={t('member.applications.ariaLabel')}
        headCells={headCells}
        isPaperComponent={false}
        rowsPerPage={10}
        list={filteredApplications}
        row={(application) => (
          <MemberApplicationsTableRow
            key={application._id}
            application={application}
            handleNavigate={handleNavigate}
            type={type}
          />
        )}
        defaultSort="createdAt"
        defaultDirection="desc"
      />
    </Paper>
  )
}
