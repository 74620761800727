import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { FileVersion } from 'common/types'
import BorderGridLayout from 'components/BorderGridLayout'
import DownloadFile from 'components/files/DownloadFile'

function DisclosureFiles({ files }: { files: any; name: string }) {
  const { t } = useTranslation()

  const fileList = useMemo(
    () =>
      (files || []).reduce(
        (list: FileVersion[], file: any) => [
          ...list,
          ...file.versions.map((v: any, n: number) => ({
            label: file.label,
            _id: file._id,
            nameOriginal: v.nameOriginal,
            version: n + 1,
            createdAt: v.createdAt,
          })),
        ],
        []
      ) as FileVersion[],
    [files]
  )

  return (
    <BorderGridLayout container>
      <Typography variant="h2">{t('disclosureDocuments.heading')}</Typography>

      <Grid
        item
        container
        sx={{
          backgroundColor: 'secondary.light',
          borderRadius: '8px',
          py: 1,
          px: 2,
        }}
        my={3}
      >
        <Typography>
          {t('disclosureDocuments.files.description', {
            count: fileList.length,
          })}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {(fileList || []).map((file) => (
          <Grid
            key={file._id + file.version}
            container
            item
            xs={12}
            sm={6}
            md={4}
            gap={1}
            alignContent="flex-start"
          >
            <DownloadFile
              title={t(
                `disclosureDocuments.form.types.${file.label.replace(
                  /-\d+$/,
                  ''
                )}.title`
              )}
              file={file}
            />
          </Grid>
        ))}
      </Grid>
    </BorderGridLayout>
  )
}

export default DisclosureFiles
