import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useReducer,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { usePrevious } from 'react-use'
import { t } from 'i18next'

import { useAuth } from 'app/Auth'
import { Role, User, GenericObject } from 'common/types'
import { KEY_NEW_USER, KEY_USER, KEY_USER_ACTIVE_ROLE } from 'lib/config'
import { useGetUserByUserPoolSubQuery } from 'lib/apollo/hooks'
import AlertDialog from 'components/AlertDialog'
import LoadingIndicator from 'components/LoadingIndicator'
import {
  DEFAULT_ADMIN_ROUTE,
  DEFAULT_ADMIN_MEMBERS_ROUTE,
} from 'routes/AdminDashboard'
import { DEFAULT_MEMBER_DASHBOARD_ROUTE } from 'routes/MemberDashboard'

type UserState = {
  activeRole: Role | null
  isNewUser: boolean | null
}
type Actions =
  | { type: 'set_active_role'; payload: UserState['activeRole'] }
  | { type: 'set_new_user'; payload: UserState['isNewUser'] }
type UserProviderProps = { children: React.ReactNode }

interface UserProviderState {
  state: UserState
  dispatch: (action: Actions) => void
  subId: string
  data: User
  roles: {
    userRoles: Role[] | null
    activeRole: Role | null
    setActiveRole: (role: Role) => void
    isOpenstockAdmin: boolean
    isShareholder: boolean
  }
  roleNavigationConfig: GenericObject
}

const UserStateContext = createContext(({} as UserProviderState) || undefined)

function userReducer(state: UserState, action: Actions) {
  switch (action.type) {
    case 'set_active_role':
      return { ...state, activeRole: action.payload }
    case 'set_new_user':
      return { ...state, isNewUser: action.payload }

    default: {
      throw new Error('Unhandled action type in userReducer')
    }
  }
}

function UserProvider({ children }: UserProviderProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const apolloClient = useApolloClient()

  const [state, dispatch] = useReducer(userReducer, {
    activeRole:
      JSON.parse(localStorage.getItem(KEY_USER_ACTIVE_ROLE) as string) ?? null,
    isNewUser: JSON.parse(localStorage.getItem(KEY_NEW_USER) as string) ?? true,
  })
  const { activeRole } = state
  const [userData, setUserData] = useState<User | null>(null)
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const { t } = useTranslation()
  const { user, isLoggedIn, logoutMutation } = useAuth()
  const previousIsLoggedIn = usePrevious(isLoggedIn)
  const subId = user?.attributes.sub

  // NOTE: Do not use data directly unless needed, this data is persisted in userData and this fetch is not guaranteed to happen
  const { data, loading, error, refetch } = useGetUserByUserPoolSubQuery(subId)

  const userRoles = userData?.roles ?? null

  const setActiveRole = (role: Role | null) =>
    dispatch({
      type: 'set_active_role',
      payload: role,
    })

  const handleAlertSecondaryButtonAction = () => {
    setShowAlertDialog(false)
    logoutMutation.mutate()
  }

  const handleAlertOnClose = () => null

  const roleNavigationConfig = useMemo(() => {
    if (!userData || !userRoles) {
      return null
    }

    const roles = {
      ADMIN: {
        label: t('roleSelect.liquidlpAdmin'),
        action: () => {
          navigate(DEFAULT_ADMIN_MEMBERS_ROUTE, { replace: true })
        },
        path: DEFAULT_ADMIN_ROUTE,
      },
      USER: {
        label: t('roleSelect.member'),
        action: () => {
          navigate(DEFAULT_MEMBER_DASHBOARD_ROUTE, { replace: true })
        },
        path: DEFAULT_MEMBER_DASHBOARD_ROUTE,
      },
    } as any

    // Delete unmatched roles
    Object.keys(roles)
      .filter((key: any) => !userRoles.includes(key))
      .forEach((key) => roles.hasOwnProperty(key) && delete roles[key])

    return roles
  }, [navigate, t, userData, userRoles])

  // Clear apollo data if user logs out
  useEffect(() => {
    if (!isLoggedIn && previousIsLoggedIn) {
      apolloClient.clearStore()
    }
  }, [apolloClient, isLoggedIn, previousIsLoggedIn])

  useEffect(() => {
    if (error) {
      setShowAlertDialog(true)
    } else {
      setShowAlertDialog(false)
    }
  }, [error])

  // Track if new user, and persist
  useEffect(() => {
    if (!isLoggedIn) return setUserData(null)

    // Get user from localStorage
    const getUser = localStorage.getItem(KEY_USER)
    if (getUser) {
      const parsedGetUser = JSON.parse(getUser)
      setUserData(parsedGetUser)
    }

    // Set local storage new user to false when user is logged in
    localStorage.setItem(KEY_NEW_USER, JSON.stringify(false))
  }, [isLoggedIn])

  // Persist activeRole
  useEffect(() => {
    if (!isLoggedIn && activeRole) return setActiveRole(null)

    // Set local storage new user to false when user is logged in
    localStorage.setItem(KEY_USER_ACTIVE_ROLE, JSON.stringify(activeRole))
  }, [activeRole, isLoggedIn])

  // Persist user to local storage to speed up initial page load
  useEffect(() => {
    localStorage.setItem(KEY_USER, JSON.stringify(userData))
  }, [userData])

  useEffect(() => {
    const user = data?.getUserByUserPoolSub

    if (user && JSON.stringify(userData) !== JSON.stringify(user)) {
      setUserData(user)
    }
  }, [data, userData])

  useEffect(() => {
    if (!userData || !userRoles) return

    let newActiveRole: Role | null = null
    userRoles?.forEach((role) => {
      // Find active role in the url

      if (role in roleNavigationConfig) {
        if (location.pathname.startsWith(roleNavigationConfig[role].path)) {
          newActiveRole = role
        }
      }
    })

    if (newActiveRole) {
      setActiveRole(newActiveRole)
    } else if (userData?.company && !activeRole && userRoles?.[0]) {
      // When multiple roles and no activeRole, send to role selector
      if (userRoles.length > 1) return

      // When there is no activeRole, defaults to highest role
      setActiveRole(userRoles?.[0])
    }
  }, [
    activeRole,
    location.pathname,
    navigate,
    roleNavigationConfig,
    userData,
    userRoles,
  ])

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      subId,
      data: userData as User,
      roles: {
        userRoles,
        activeRole,
        setActiveRole,
        isOpenstockAdmin: activeRole === 'ADMIN',
        isShareholder: activeRole === 'USER',
      },
      roleNavigationConfig,
    }),
    [activeRole, roleNavigationConfig, state, subId, userData, userRoles]
  )

  return (
    <UserStateContext.Provider value={contextValue}>
      {(error || !userData || (userData === null && loading)) && isLoggedIn ? (
        <LoadingIndicator />
      ) : (
        children
      )}
      <AlertDialog
        isOpen={showAlertDialog}
        onClose={handleAlertOnClose}
        description={t('user.userNotLoaded')}
        primaryButtonAction={() => refetch()}
        secondaryButtonText={t('user.logout')}
        secondaryButtonAction={handleAlertSecondaryButtonAction}
      />
    </UserStateContext.Provider>
  )
}

function useUser() {
  const context = useContext(UserStateContext)

  if (context === undefined)
    throw new Error('useUser must be used with UserProvider')

  return context
}

const getUserRoleString = (role: string) => {
  switch (role) {
    case 'ADMIN':
      return t('user.roles.liquidlpAdmin')
    case 'USER':
      return t('user.roles.member')
    default:
      return
  }
}

export { UserProvider, useUser, getUserRoleString }
