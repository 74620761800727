import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { useAuth } from 'app/Auth'
import { Form, Formik } from 'lib/formik'
import { passwordSchema } from 'lib/validation'

import Alert from 'components/mui/Alert'
import BrandLayout from 'components/BrandLayout/BrandLayout'
import Link from 'components/mui/Link'
import LoadingButton from 'components/mui/LoadingButton'
import Paper from 'components/mui/Paper/Paper'
import TextInput from 'components/mui/TextInput'

const initialValues = {
  profilePassword: '',
  profileConfirmPassword: '',
}

function ActivateAccount() {
  const { t } = useTranslation()
  const { activateProfileMutation } = useAuth()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')

  const handleSubmit = (values: typeof initialValues) => {
    activateProfileMutation.mutate({
      username: email,
      password: values.profilePassword,
      attributes: {
        given_name: firstName,
        family_name: lastName,
        email,
      },
      autoSignIn: {
        enabled: true,
      },
    })
  }

  return (
    <BrandLayout>
      <Paper hasBorder>
        <Grid container alignContent="center" justifyContent="center">
          <Typography variant="h1" textAlign="center">
            {t('account.activate.title')}
          </Typography>
        </Grid>

        <Formik
          initialValues={initialValues}
          validationSchema={passwordSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Grid container rowSpacing={3} mt={2}>
                <Grid item xs={12}>
                  <TextInput
                    id="profile-password"
                    name="profilePassword"
                    label={t('accountSettings.changePassword.newPassword')}
                    placeholder={t(
                      'accountSettings.changePassword.newPasswordPlaceholder'
                    )}
                    helperText={
                      <Typography
                        variant="subtitle1"
                        component={'span'}
                        dangerouslySetInnerHTML={{
                          __html: t('common.passwordHelperText'),
                        }}
                      />
                    }
                    type="password"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="profile-confirm-password"
                    name="profileConfirmPassword"
                    label={t(
                      'accountSettings.changePassword.confirmNewPassword'
                    )}
                    placeholder={t(
                      'accountSettings.changePassword.newPasswordPlaceholder'
                    )}
                    type="password"
                    required
                  />
                </Grid>
                {activateProfileMutation.isError && (
                  <Grid item xs={12}>
                    <Alert title={t('common.error')}>
                      {(activateProfileMutation.error as Error).message ??
                        t('common.errorUnknown')}
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12} my={2}>
                  <Typography>
                    <Trans i18nKey="account.profile.agreement">
                      By activating your account, you acknowledge that all
                      information you provide will be kept secure and
                      confidential, in accordance with our
                      <Link href="/confidentiality" target="_blank">
                        confidentiality statement
                      </Link>
                      .
                    </Trans>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    type="submit"
                    mode="forward"
                    loading={activateProfileMutation.isPending}
                  >
                    {t('account.activate.buttonLabel')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </BrandLayout>
  )
}

export default ActivateAccount
