import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { useUser } from 'app/User'
import { useAuth } from 'app/Auth'

function UserAvatarMenu() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logoutMutation } = useAuth()
  const {
    data: { firstName, lastName },
    roles: { userRoles, activeRole },
    roleNavigationConfig,
  } = useUser()

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    logoutMutation.mutate()
  }

  const isDisplayRoleOptions = userRoles && userRoles.length > 1

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title={t('dashboard.appBar.accountMenuToolTip')}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            sx={{
              backgroundColor: 'white',
              color: 'primary.main',
              textTransform: 'capitalize',
            }}
            alt={`${firstName} ${lastName}`}
          >
            {firstName.charAt(0)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {isDisplayRoleOptions && (
          <MenuList>
            <MenuItem disabled>{t('common.menuTitle')}</MenuItem>
            {userRoles.map((role: string) => {
              const isCurrentRole = activeRole === role
              const roleConfig = roleNavigationConfig[role]

              if (!roleConfig) {
                return null
              }

              return (
                <MenuItem
                  key={role}
                  onClick={() => {
                    handleCloseUserMenu()
                    roleConfig.action()
                  }}
                  selected={isCurrentRole}
                >
                  <Typography
                    color={isCurrentRole ? 'primary' : 'secondary.darkest'}
                    variant={isCurrentRole ? 'body2' : 'body1'}
                  >
                    {roleConfig.label}
                  </Typography>
                </MenuItem>
              )
            })}
            <Divider />
          </MenuList>
        )}
        <MenuItem
          onClick={() => {
            handleCloseUserMenu()
            navigate('/account-settings')
          }}
        >
          <Typography textAlign="center">
            {t('dashboard.settings.account')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Typography textAlign="center">
            {t('dashboard.settings.logout')}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default UserAvatarMenu
