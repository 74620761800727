import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'

import { useOfProceeds, loanDurationOptions } from 'lib/formConfig'
import { useFormikContext } from 'lib/formik'
import { ApplicationInputForm } from 'views/member/RiaNewApplication'
import Paper from 'components/mui/Paper/Paper'
import RequiredNote from 'components/RequiredNote'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import SelectInput from 'components/mui/SelectInput'
import TextInput from 'components/mui/TextInput'

interface Props {
  handlePrevious: () => void
  isSubmittingApplication: boolean
}

function RequestedLoanAmount({
  handlePrevious,
  isSubmittingApplication,
}: Props) {
  const { t } = useTranslation()

  const { values } = useFormikContext<ApplicationInputForm>()

  return (
    <Grid container>
      <Grid item container xs={12} smd={7.5}>
        <Paper hasBorder>
          <RequiredNote />

          <Grid container rowSpacing={3} mt={2}>
            <Grid item xs={12}>
              <TextInput
                id="amount"
                name="amount"
                label={t(
                  'member.newApplication.requestedLoanAmount.labels.loanAmount'
                )}
                placeholder={t('common.decimalPlaceholder')}
                delimiter="$"
                inputMode="decimal"
                isNumericFormat
                hasDecimalScale={false}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                id="useOfProceeds"
                name="useOfProceeds"
                label={t(
                  'member.newApplication.requestedLoanAmount.labels.useOfProceeds.title'
                )}
                placeholder={t(
                  'member.newApplication.requestedLoanAmount.labels.useOfProceeds.placeholder'
                )}
                value={values.useOfProceeds}
                options={useOfProceeds}
                required
              />
            </Grid>
            {/* Add a a field when useOfProceeds is 'Other', future feature */}
            {/* {values.useOfProceeds === 'Other' && (
              <Grid item xs={12}>
                <TextInput
                  id="useOfProceedsOther"
                  name="useOfProceedsOther"
                  placeholder={t(
                    'member.newApplication.requestedLoanAmount.labels.useOfProceeds.options.otherPlaceholder'
                  )}
                  required
                />
              </Grid>
            )} */}
            <Grid item xs={12}>
              <SelectInput
                id="duration"
                name="duration"
                label={t(
                  'member.newApplication.requestedLoanAmount.labels.loanLength'
                )}
                placeholder={t(
                  'member.newApplication.requestedLoanAmount.labels.loanLengthPlaceholder'
                )}
                value={values.duration}
                options={loanDurationOptions}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="comments"
                name="comments"
                placeholder={t(
                  'member.newApplication.requestedLoanAmount.labels.commentsPlaceholder'
                )}
                multiline
                minRows={6}
                required
              />
            </Grid>

            <Grid item xs={12} mt={4}>
              <ResponsiveStepperButtons
                isSubmitting={isSubmittingApplication}
                previousStepText={t('common.previous')}
                handleBack={handlePrevious}
                isLastStep
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RequestedLoanAmount
