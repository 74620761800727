import React, { createContext, useContext, useEffect, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Slide,
  ToastContainer,
  ToastOptions,
  TypeOptions,
  toast,
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface ToastContextType {
  toast: (message: string, options?: ToastOptions) => void
}

const ToastContext = createContext<ToastContextType | undefined>(undefined)

export const useToast = () => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const toastMessage = location.state?.toastMessage
  const toastType: TypeOptions = location.state?.toastType ?? 'default'

  useEffect(() => {
    switch (toastType) {
      case 'error':
        toast.error(toastMessage, { toastId: toastMessage })
        break
      case 'info':
        toast.info(toastMessage, { toastId: toastMessage })
        break
      case 'warning':
        toast.warn(toastMessage, { toastId: toastMessage })
        break
      default:
        toast.success(toastMessage, { toastId: toastMessage })
        break
    }
  }, [toastMessage, toastType])

  useEffect(() => {
    if (location.state) {
      // Replace the current entry in the history stack without state
      navigate(location.pathname, { replace: true })
    }
  }, [navigate, location])

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <ToastContainer transition={Slide} />
    </ToastContext.Provider>
  )
}
