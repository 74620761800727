import i18n from 'locale/i18n'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function RequiredNote({
  label = i18n.t('common.requiredField'),
}: {
  label?: string
}) {
  return (
    <Box
      sx={{
        backgroundColor: '#dcdcdc40',
        borderRadius: '5px',
        px: 1,
        py: 1.5,
        width: '100%',
      }}
    >
      <Typography
        variant="subtitle1"
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      />
    </Box>
  )
}

export default RequiredNote
