import { gql } from '@apollo/client'

export const GET_USER_BY_USER_POOL_SUB_QUERY = gql`
  query GET_USER_BY_USER_POOL_SUB_QUERY($sub: ID!) {
    getUserByUserPoolSub(sub: $sub) {
      firstName
      lastName
      emailAddress
      phoneNumber
      address {
        streetAddress
        state
        country
        zipCode
      }
      roles
      userType
      offers {
        _id
      }
      userpoolSub
      # createdAt
      # updatedAt
      _id
    }
  }
`

export const GET_USERS_QUERY = gql`
  query GET_USERS_QUERY {
    getUsers {
      firstName
      lastName
      phoneNumber
      company
      userType
      emailAddress
      roles
      createdAt
      userpoolSub
      _id
    }
  }
`

export const GET_USER_QUERY = gql`
  query GET_USER_QUERY($userId: ID!) {
    getUser(id: $userId) {
      firstName
      lastName
      emailAddress
      phoneNumber
      address {
        streetAddress
        state
        city
        country
        zipCode
      }
      roles
      userType
      company
      offers {
        kind
        startDate
        expiryDate
        liquidityAvailable
        liquidityPercentPerShareholder
        maxAvailableShares
        status
        createdAt
        updatedAt
        _id
      }
      createdAt
      updatedAt
      files {
        _id
        label
        versions {
          _id
          nameOriginal
          nameOnDisk
          fileType
          size
          createdAt
        }
      }
      _id
    }
  }
`

export const GET_APPLICATION_QUERY = gql`
  query GET_APPLICATION_QUERY($id: ID!) {
    getApplication(id: $id) {
      _id
      clientType
      firstName
      lastName
      phone
      email
      address {
        streetAddress
        streetAddress2
        state
        city
        country
        zipCode
        #   __typename
      }
      companyName
      companyEin
      website
      documents {
        label
        # owner {
        #   firstName
        #   lastName
        #   emailAddress
        #   phoneNumber
        #   roles
        #   userType
        #   company
        #   userpoolSub
        #   createdAt
        #   updatedAt
        #   _id
        #   __typename
        # }
        versions {
          fileType
          nameOriginal
          nameOnDisk
          size
          createdAt
          updatedAt
          _id
          __typename
        }
        current {
          fileType
          nameOriginal
          nameOnDisk
          size
          createdAt
          updatedAt
          _id
          __typename
        }
        _id
        __typename
      }
      amount
      useOfProceeds
      duration
      comments
      actualAmount
      bankingInfo {
        SSN
        bankAccountNumber
        ABA_routingNumber
        __typename
      }
      revisionComments
      stage {
        stage
        revisionComments
        createdAt
        updatedAt
        __typename
      }
      currentStage
      fund {
        name
        website
        fundSizeRaised
        currentAUM
        LPsNumber
        __typename
      }
      financingInfo {
        _id
        fundSize
        commitment
        estimatedLoanAvailable
        LTV
        originationFee
        interestRate
        financingTerm {
          length
          __typename
        }
        loanRepaymentFrequency
        offerEndDate
        createdAt
        updatedAt
        __typename
      }
      user {
        firstName
        lastName
        emailAddress
        phoneNumber
        roles
        userType
        company
        userpoolSub
        createdAt
        updatedAt
        _id
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`

export const GET_APPLICATIONS_BY_USERID_QUERY = gql`
  query GET_APPLICATIONS_BY_USERID_QUERY($userId: ID!) {
    getApplicationsByUserId(userId: $userId) {
      _id
      firstName
      lastName
      phone
      email
      amount
      actualAmount
      address {
        streetAddress
        streetAddress2
        state
        city
        country
        zipCode
        __typename
      }
      user {
        firstName
        lastName
        emailAddress
        phoneNumber
        userpoolSub
        createdAt
        updatedAt
        _id
        __typename
      }
      currentStage
      companyName
      website
    }
  }
`

export const GET_APPLICATIONS_QUERY = gql`
  query GET_APPLICATIONS_QUERY($currentStages: [String]) {
    getApplications(currentStages: $currentStages) {
      applications {
        _id
        firstName
        lastName
        phone
        email
        address {
          streetAddress
          streetAddress2
          state
          city
          country
          zipCode
          __typename
        }
        companyName
        companyEin
        website
        user {
          firstName
          lastName
        }
        amount
        useOfProceeds
        duration
        termLoanOrDrawDown
        comments
        actualAmount
        bankingInfo {
          SSN
          bankAccountNumber
          ABA_routingNumber
          __typename
        }
        revisionComments
        stage {
          stage
          revisionComments
          createdAt
          updatedAt
          __typename
        }
        currentStage
        fund {
          name
          website
          fundSizeRaised
          # currentAUM
          LPsNumber
          __typename
        }
        financingInfo {
          _id
          commitment
          offerEndDate
        }
        createdAt
        updatedAt
        __typename
      }
      totalCount
      totalAmount
      __typename
    }
  }
`

export const GET_MY_APPLICATIONS_QUERY = gql`
  query GET_MY_APPLICATIONS_QUERY {
    getMyApplications {
      _id
      firstName
      lastName
      phone
      email
      address {
        streetAddress
        streetAddress2
        state
        city
        country
        zipCode
        __typename
      }
      companyName
      companyEin
      website
      amount
      useOfProceeds
      duration
      termLoanOrDrawDown
      comments
      actualAmount
      bankingInfo {
        SSN
        bankAccountNumber
        ABA_routingNumber
        __typename
      }
      revisionComments
      stage {
        stage
        revisionComments
        createdAt
        updatedAt
        __typename
      }
      currentStage
      fund {
        name
        website
        fundSizeRaised
        currentAUM
        LPsNumber
        __typename
      }
      financingInfo {
        _id
        commitment
        offerEndDate
      }
      createdAt
      updatedAt
      __typename
    }
  }
`

export const GET_APPLICATION_COUNT_QUERY = gql`
  query getApplicationCount {
    getApplicationCount {
      stage
      count
      __typename
    }
  }
`
