import { Navigate, Route, Routes } from 'react-router-dom'

import ApplicationOfferDetails from 'views/member/ApplicationOfferDetails'
import GpNewApplication from 'views/member/GpNewApplication'
import LpNewApplication from 'views/member/LpNewApplication'
import MemberOverview from 'views/member/MemberOverview'
import PageNotFound from 'views/PageNotFound'
import RiaNewApplication from 'views/member/RiaNewApplication'
import SelectClientType from 'views/member/newApplication/SelectClientType'
import SetLoanAmount from 'views/member/setLoan/SetLoanAmount'

export const DEFAULT_MEMBER_ROUTE = '/member'
export const DEFAULT_MEMBER_DASHBOARD_ROUTE = '/member/dashboard'

function MemberDashboard() {
  return (
    <Routes>
      <Route path="/dashboard/*" element={<MemberOverview />} />
      <Route path="/ria/client-type/*" element={<SelectClientType />} />
      <Route path="/ria/create-application/*" element={<RiaNewApplication />} />
      <Route path="/gp/create-application/*" element={<GpNewApplication />} />
      <Route path="/lp/create-application/*" element={<LpNewApplication />} />
      <Route
        path="/offer/:applicationId"
        element={<ApplicationOfferDetails />}
      />
      <Route path="/set-loan/:applicationId" element={<SetLoanAmount />} />
      <Route path="/" element={<Navigate to="dashboard" />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
export default MemberDashboard
