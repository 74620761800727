import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import { Application } from 'common/types'
import { getApplicationOfferStage } from 'lib/data'

import BorderGridLayout from 'components/BorderGridLayout'
import DisclosureFiles from 'components/files/DisclosureFiles'
import LabelValueComponent from 'components/LabelValueComponent'
import Button from 'components/mui/Button'

function ApplicationInformation({ application }: { application: Application }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate('../financing-information')
  }

  const userType = application?.user?.userType
  const type = userType === 'RIA' ? 'ria' : 'general'

  const { user: member } = application

  const { isApplicationOfferAccepted, isApplicationOfferCreated } = useMemo(
    () => getApplicationOfferStage(application?.currentStage),
    [application?.currentStage]
  )

  const clientInformation = [
    {
      label: t(
        `liquidlpAdmin.applications.applicationInfo.contact.${type}.labels.name`
      ),
      value: `${application?.firstName} ${application?.lastName}`,
    },
    {
      label: t(
        `liquidlpAdmin.applications.applicationInfo.contact.${type}.labels.phone`
      ),
      value: application?.phone,
    },
    {
      label: t(
        `liquidlpAdmin.applications.applicationInfo.contact.${type}.labels.email`
      ),
      value: application?.email,
    },
    {
      label: t(
        `liquidlpAdmin.applications.applicationInfo.contact.${type}.labels.companyName`
      ),
      value: application?.companyName,
      display: application?.clientType === 'entity' || userType === 'LP',
    },
    {
      label: t(
        `liquidlpAdmin.applications.applicationInfo.contact.${type}.labels.companyWebsite`
      ),
      value: application?.website,
      display: application?.clientType === 'entity' || userType === 'LP',
    },
    {
      label: t(
        `liquidlpAdmin.applications.applicationInfo.contact.${type}.labels.address`
      ),
      address: application?.address,
    },
  ]

  const loanInformation = [
    {
      label: t('liquidlpAdmin.applications.applicationInfo.loan.labels.amount'),
      value:
        application?.amount !== null
          ? t('common.intlCurrencySimplifiedFormat', {
              value: application?.amount,
            })
          : '-',
    },
    {
      label: t(
        'liquidlpAdmin.applications.applicationInfo.loan.labels.preauthorizedAmount'
      ),
      value:
        application?.financingInfo !== null
          ? t('common.intlCurrencySimplifiedFormat', {
              value: application?.financingInfo?.commitment,
            })
          : '-',
      display: application?.financingInfo !== null,
    },
    {
      label: t(
        'liquidlpAdmin.applications.applicationInfo.loan.labels.actualAmount'
      ),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: application?.actualAmount,
      }),
      display: isApplicationOfferAccepted,
    },
    {
      label: t(
        'liquidlpAdmin.applications.applicationInfo.loan.labels.proceeds'
      ),
      value: application?.useOfProceeds,
    },
    {
      label: t('liquidlpAdmin.applications.applicationInfo.loan.labels.term'),
      value: application?.duration
        ? t('common.monthWithCount', {
            count: application?.duration,
          })
        : '-',
    },
    {
      label: t('liquidlpAdmin.applications.applicationInfo.loan.labels.notes'),
      value: application?.comments,
    },
  ]

  const fundInformation = [
    {
      label: t('liquidlpAdmin.applications.applicationInfo.fund.labels.name'),
      value: application?.fund?.name ?? '-',
    },
    {
      label: t(
        'liquidlpAdmin.applications.applicationInfo.fund.labels.website'
      ),
      value: application?.fund?.website ?? '-',
    },
    {
      label: t('liquidlpAdmin.applications.applicationInfo.fund.labels.size'),
      value: application?.fund?.fundSizeRaised
        ? t('common.intlCurrencySimplifiedFormat', {
            value: application?.fund?.fundSizeRaised,
          })
        : '-',
    },
    {
      label: t(
        'liquidlpAdmin.applications.applicationInfo.fund.labels.currentAum'
      ),
      value: application?.fund?.currentAUM
        ? t('common.intlCurrencySimplifiedFormat', {
            value: application?.fund?.currentAUM,
          })
        : '-',
    },
    {
      label: t(
        'liquidlpAdmin.applications.applicationInfo.fund.labels.numOfShareholders'
      ),
      value: application?.fund?.LPsNumber ?? '-',
    },
    {
      label: t(
        'liquidlpAdmin.applications.applicationInfo.fund.labels.address'
      ),
      address: application?.address,
    },
  ]

  return (
    <>
      <Grid container mb={4}>
        <Grid item container justifyContent="space-between">
          <BorderGridLayout
            item
            container
            xs={12}
            sm={5.9}
            md={member?.userType === 'GP' ? 3.8 : 4}
            mb={{ xs: 2, md: 0 }}
            alignContent="flex-start"
          >
            <Typography variant="h3" mb={2}>
              {t(
                `liquidlpAdmin.applications.applicationInfo.contact.${type}.heading`
              )}
            </Typography>
            <LabelValueComponent details={clientInformation} />
          </BorderGridLayout>

          {member?.userType === 'GP' && (
            <BorderGridLayout
              item
              container
              xs={12}
              md={4}
              mb={{ xs: 2, md: 0 }}
              alignContent="flex-start"
            >
              <Typography variant="h3" mb={2}>
                {t('liquidlpAdmin.applications.applicationInfo.fund.heading')}
              </Typography>
              <LabelValueComponent details={fundInformation} />
            </BorderGridLayout>
          )}

          <BorderGridLayout
            item
            container
            xs={12}
            sm={5.9}
            md={member?.userType !== 'GP' ? 7.8 : 3.9}
            alignContent="flex-start"
          >
            <Typography variant="h3" mb={2}>
              {t('liquidlpAdmin.applications.applicationInfo.loan.heading')}
            </Typography>
            <LabelValueComponent details={loanInformation} />
          </BorderGridLayout>
        </Grid>
      </Grid>

      <DisclosureFiles
        files={application?.documents}
        name={`${member?.firstName} ${member?.lastName}`}
      />

      {isApplicationOfferCreated && (
        <Button mode="forward" sx={{ marginTop: 4 }} onClick={handleNavigate}>
          {t(
            'liquidlpAdmin.applications.financingInfo.enterFinancingInfoButtonLabel'
          )}
        </Button>
      )}
    </>
  )
}

export default ApplicationInformation
