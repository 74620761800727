import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { ReactComponent as LiquidLPLogo } from 'assets/LiquidLPLogo.svg'
import { ReactComponent as Illustration } from 'assets/vectors/illustration.svg'
import Container from 'components/mui/Container/Container'

import styles from './brandLayout.module.scss'

function BrandLayout({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation()

  return (
    <Grid container className={styles.container}>
      <Container maxWidth="lg">
        <Grid item container className={styles.gridContainer} my={6}>
          <Grid item container xs={12} sm={7} justifyContent="center">
            {children}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={5}
            mt={{ xs: 4, sm: 0 }}
            sx={{ borderRadius: { xs: '0 0 24px 24px', sm: '0 24px 24px 0' } }}
            className={styles.sideContainer}
            justifyContent="center"
          >
            <Grid item container mt={{ xs: 3 }} justifyItems="space-between">
              <Grid item container justifyContent="center">
                <Grid item p={2}>
                  <LiquidLPLogo />
                </Grid>
                <Grid item py={2} px={{ xs: 2, md: 6 }}>
                  <Typography
                    variant="h1"
                    color="white"
                    textAlign="center"
                    my={4}
                  >
                    {t('user.login.header')}
                  </Typography>
                  <Typography color="white" textAlign="center">
                    {t('user.login.subHeader')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="flex-end"
                mt={{ xs: 2, sm: 4 }}
                sx={{
                  borderRadius: { xs: '0 0 24px 24px', sm: '0 24px 24px 0' },
                }}
              >
                <Illustration />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}

export default BrandLayout
