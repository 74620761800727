import { Trans, useTranslation } from 'react-i18next'
import { Navigate, useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Form, Formik } from 'lib/formik'
import { LIQUIDLP_SUPPORT_EMAIL } from 'lib/config'
import { resetPasswordSchema } from 'lib/validation'
import { useAuth } from 'app/Auth'

import Alert from 'components/mui/Alert'
import Layout from 'components/Layout/Layout'
import LoadingButton from 'components/mui/LoadingButton'
import Link from 'components/mui/Link'
import Paper from 'components/mui/Paper/Paper'
import RequiredNote from 'components/RequiredNote'
import TextInput from 'components/mui/TextInput'

const initialValues = {
  profilePassword: '',
  profileConfirmPassword: '',
  code: '',
}

function ResetPassword() {
  const { forgotPasswordSubmitMutation, loginMutation } = useAuth()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const handleSubmit = (values: typeof initialValues) => {
    forgotPasswordSubmitMutation.mutate({
      username: searchParams.get('username'),
      code: values.code,
      password: values.profilePassword,
    })
  }

  if (loginMutation.isError) {
    return <Navigate to="/login" replace />
  }

  return (
    <Layout maxWidth="sm">
      <Grid container spacing={2} columns={1} justifyContent="center">
        <Grid item>
          <Typography variant="h2" component="h1" textAlign="center">
            {t('user.reset.title')}
          </Typography>
          {searchParams.get('status') === 'forced' ? (
            <Alert severity="info" sx={{ mt: 3 }}>
              {t('user.reset.forcedReset')}
            </Alert>
          ) : (
            <Typography my={2} textAlign="center">
              {t('user.reset.checkEmailDescription')}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Paper hasBorder>
            <RequiredNote />
            <Formik
              initialValues={initialValues}
              validationSchema={resetPasswordSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <Grid container spacing={3} columns={1} my={1}>
                    <Grid item xs={1}>
                      <TextInput
                        id="code"
                        name="code"
                        label={t('account.verification.label')}
                        placeholder={t(
                          'account.verification.verificationCodePlaceholder'
                        )}
                        maxLength={6}
                        required
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextInput
                        id="profile-password"
                        name="profilePassword"
                        label={t('account.profile.labels.newPassword')}
                        placeholder={t(
                          'account.verification.passwordPlaceholder'
                        )}
                        helperText={
                          <Typography
                            variant="subtitle1"
                            component={'span'}
                            dangerouslySetInnerHTML={{
                              __html: t('common.passwordHelperText'),
                            }}
                          />
                        }
                        type="password"
                        required
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextInput
                        id="profile-confirm-password"
                        name="profileConfirmPassword"
                        label={t('account.verification.confirmNewPassword')}
                        placeholder={t(
                          'account.verification.passwordPlaceholder'
                        )}
                        type="password"
                        required
                        autoComplete="new-password"
                      />
                    </Grid>
                    {forgotPasswordSubmitMutation.isError && (
                      <Grid item>
                        <Alert title={t('common.error')}>
                          {(forgotPasswordSubmitMutation.error as Error)
                            .message ?? t('common.errorUnknown')}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item>
                      <LoadingButton
                        type="submit"
                        mode="forward"
                        sx={{ my: 2 }}
                        loading={
                          forgotPasswordSubmitMutation.isPending ||
                          forgotPasswordSubmitMutation.isSuccess ||
                          loginMutation.isPending
                        }
                      >
                        {t('user.reset.changePassword')}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>

            <Grid item>
              <Typography variant="subtitle1" color="secondary.lighter">
                {t('user.forgot.havingTrouble')}
              </Typography>
              <Typography variant="subtitle1" color="secondary.lighter">
                <Trans i18nKey="common.contact">
                  Contact us at
                  <Link
                    href={`mailto:${LIQUIDLP_SUPPORT_EMAIL}?subject=${t(
                      'common.resetEmailSubject'
                    )}`}
                    variant="h6"
                    sx={{ color: 'primary.darker' }}
                  >
                    partners@getopenstock.com
                  </Link>
                </Trans>
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ResetPassword
