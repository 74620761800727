import { useField } from 'formik'
import { GenericObject } from 'common/types'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField, { BaseTextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

type Props = BaseTextFieldProps & {
  options: GenericObject[]
  name: string
  label: string
  onChangeCallback?: (value: any) => void
}

function SelectInput({
  name,
  label,
  options,
  onChangeCallback,
  ...props
}: Props) {
  const [field, meta, helpers] = useField(name)

  const handleChange = (e: any) => {
    const { value } = e.target
    onChangeCallback?.(value)
    helpers.setValue(value)
  }

  const config = {
    ...field,
    ...props,
    value: field.value ?? '',
  }

  if (meta.touched && meta.error) {
    config.error = true
    config.helperText = meta.error
  }

  return (
    <Grid container>
      {label && (
        <Grid container item>
          <Typography variant="h6" mb={1}>
            {label}
            {props.required && <span style={{ color: '#CF0C0C' }}> *</span>}
          </Typography>
        </Grid>
      )}
      <TextField
        {...config}
        select
        fullWidth
        variant="outlined"
        onChange={handleChange}
        SelectProps={{
          displayEmpty: true,
        }}
      >
        <MenuItem disabled value={''}>
          <Typography color="secondary.lighter">{props.placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )
}

export default SelectInput
