import createTheme from '@mui/material/styles/createTheme'

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smd: 725,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          letterSpacing: '0.009rem',
          fontSize: '0.813rem',
          fontWeight: 600,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          '&[disabled]': {
            color: 'grey',
            pointerEvents: 'none',
            textDecorationColor: 'grey',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 8,
        },
        track: {
          border: 'none',
        },
        rail: {
          opacity: 1,
          backgroundColor: '#EAECF0',
        },
        markLabel: {
          fontWeight: 400,
        },
        thumb: {
          backgroundColor: '#3F71BE',
          height: 24,
          width: 24,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#CF0C0C',
          '&$error': {
            color: '#CF0C0C',
          },
        },
      },
    },
  },

  shape: {
    borderRadius: 20,
  },
  // Comments below correspond to the Figma style guide.
  palette: {
    primary: {
      darker: '#115194', //Link Blue
      dark: '#001933', // Blue Dark
      main: '#013147', // Blue Primary
      light: '#3F71BE', // Blue Secondary
      lighter: '#7299D5', // Blue Tertiary
      lightest: '#EAEEF6', // Blue Light
      contrastText: '#FFF',
    },
    secondary: {
      darkest: '#333333', // Dark Grey
      darker: '#28282A',
      dark: '#696969', // Grey
      main: '#333333', // Disabled Grey
      light: '#DCDCDC40', // Light Grey
      lighter: '#949494', // Form Field Gray
      lightest: '#F0EFEC', // Light Background
      contrastText: '#FFF',
    },
    info: {
      dark: '#333333',
      main: '#115194',
      light: '#EAEEF6',
    },
    error: {
      dark: '#660000',
      main: '#CF0C0C',
      light: '#FFEBEB',
    },
    success: {
      dark: '#26570F',
      main: '#55B32A',
      light: '#ECFAE5',
      contrastText: '#FFF',
    },
    warning: {
      dark: '#805C00',
      main: '#F8C544',
      light: '#FEF6E2',
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    // Display Title 1
    h1: {
      fontSize: '1.75rem',
      lineHeight: '2.5rem',
      fontWeight: 600,
      '@media (min-width:900px)': {
        fontSize: '2.125rem',
        lineHeight: '3rem',
      },
    },
    // Display Title 2
    h2: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: '1.75rem',
        lineHeight: '2.5rem',
      },
    },
    // Display Title 3
    h3: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: '1.375rem',
        lineHeight: '1.875rem',
      },
    },
    // Card Title
    h4: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    // Heading
    h5: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
    },
    // Field title
    h6: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: 600,
    },
    // Subtitle
    subtitle1: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: 400,
    },
    // Link small
    subtitle2: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: 600,
    },
    // Body regular
    body1: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
      // Prevent auto zooming on form inputs for touch screen users
      '@media (hover: none)': {
        fontSize: 'max(1rem, 16px)',
      },
    },
    // Body bold
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: 600,
    },
    button: {
      textTransform: 'inherit',
      fontWeight: 600,
    },
    placeholder: {
      fontWeight: 400,
      fontSize: '1rem',
      color: '#afafaf',
    },
  },
} as const

export type CustomTheme = {
  [Key in keyof typeof theme]: (typeof theme)[Key]
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    button: true
    placeholder: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    smd: true
  }
}

declare module '@mui/material/styles/createTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends CustomTheme {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends CustomTheme {}
}

export default createTheme(theme)
