import { useParams } from 'react-router-dom'

import { useGetApplicationsByUserIdQuery } from 'lib/apollo/hooks'
import QueryBoundary from 'app/QueryBoundary'
import ApplicationsTable from 'components/tables/ApplicationsTable'
import Layout from 'components/Layout/Layout'
import Paper from 'components/mui/Paper/Paper'

function Applications() {
  const { userId } = useParams()

  const applicationsQuery = useGetApplicationsByUserIdQuery(userId)
  const { data } = applicationsQuery
  const applications = data?.getApplicationsByUserId

  return (
    <QueryBoundary queries={[applicationsQuery]}>
      <Layout maxWidth="lg">
        <Paper hasBorder>
          <ApplicationsTable applications={applications ?? []} />
        </Paper>
      </Layout>
    </QueryBoundary>
  )
}

export default Applications
