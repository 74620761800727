import { useDebounce } from 'react-use'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import { Application } from 'common/types'
import { calculateLoanDetails } from 'lib/data'
import { minimumLoanAmount } from 'lib/formConfig'
import { useFormikContext } from 'lib/formik'
import { DEFAULT_MEMBER_ROUTE } from 'routes/MemberDashboard'

import { SetLoanAmountProps } from 'views/member/setLoan/SetLoanAmount'
import BorderGridLayout from 'components/BorderGridLayout'
import CurrencySlider from 'components/CurrencySlider'
import OfferDetailsCard, { LoanDetails } from 'components/loan/OfferDetailsCard'
import Paper from 'components/mui/Paper/Paper'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

function SetLoanAmountForm({
  handleNext,
  values,
  application,
  sofrRate,
}: {
  handleNext: () => void
  values: SetLoanAmountProps
  application?: Application
  sofrRate: number
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setFieldValue, errors } = useFormikContext()

  const loanFrequency = application?.financingInfo?.loanRepaymentFrequency
  const interestValue =
    loanFrequency === 'monthly'
      ? values.loanDetails.monthlyInterest
      : values.loanDetails.quarterlyInterest

  const loanDetails: LoanDetails = application
    ? [
        // Total loan plus origination fee
        {
          heading: t('offer.loan.setLoan.details.totalLoan.label'),
          data: t('common.intlCurrencySimplifiedFormat', {
            value: values.loanDetails.totalLoan,
          }),
          notes: t('offer.loan.setLoan.details.totalLoan.note', {
            value: t('common.percentage', {
              value: application?.financingInfo?.originationFee,
            }),
          }),
        },
        // Estimated yearly interest
        {
          heading: t('offer.loan.setLoan.details.yearlyInterest.label'),
          data:
            t('common.intlCurrencySimplifiedFormat', {
              value: values.loanDetails.annualInterest,
            }) + ' *',
          notes: t('offer.loan.setLoan.details.yearlyInterest.note', {
            value: t('common.percentage', {
              value: application?.financingInfo?.interestRate,
            }),
          }),
        },
        {
          heading: t(
            `offer.loan.setLoan.details.${loanFrequency}Interest.label`
          ),
          data:
            t('common.intlCurrencySimplifiedFormat', {
              value: interestValue,
            }) + ' *',
          notes: t(`offer.loan.setLoan.details.${loanFrequency}Interest.note`, {
            value: t('common.percentage', {
              value: application?.financingInfo?.interestRate,
            }),
          }),
        },
      ]
    : []

  const handleCalculateLoanDetails = () => {
    if (errors.hasOwnProperty('requestedLoanAmount')) return
    const calculatedLoanDetails = calculateLoanDetails({
      loanDetails: {
        totalLoan: values.loanDetails.totalLoan,
        annualInterest: values.loanDetails.annualInterest,
        monthlyInterest: values.loanDetails.monthlyInterest,
        quarterlyInterest: values.loanDetails.quarterlyInterest,
      },
      application,
      requestedLoanAmount: values.requestedLoanAmount,
      sofrRate,
    })

    setFieldValue('loanDetails', calculatedLoanDetails)
  }

  useDebounce(
    () => {
      handleCalculateLoanDetails()
    },
    10,
    [values.requestedLoanAmount]
  )

  return (
    <Grid container rowGap={3}>
      <Grid item container xs={12} smd={8} rowGap={3}>
        <Paper>
          {/* Set requested loan amount slider */}
          <BorderGridLayout mb={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {t('offer.loan.setLoan.slider.label')}
              </Typography>
              <CurrencySlider
                fieldName="requestedLoanAmount"
                minValue={minimumLoanAmount}
                maxValue={application?.financingInfo?.commitment ?? 0}
                value={values?.requestedLoanAmount}
                helperText={t('offer.loan.setLoan.slider.helperText', {
                  minValue: t('common.intlCurrencySimplifiedFormat', {
                    value: minimumLoanAmount,
                  }),
                  maxValue: t('common.intlCurrencySimplifiedFormat', {
                    value: application?.financingInfo?.commitment ?? 0,
                  }),
                })}
              />
            </Grid>
          </BorderGridLayout>

          {/* Calculated details */}
          <BorderGridLayout>
            <Grid item mb={4}>
              <Typography variant="h3">
                {t('offer.loan.setLoan.details.title')}
              </Typography>
            </Grid>
            <Grid item mb={4}>
              <OfferDetailsCard loanDetails={loanDetails} />
              <Typography variant="subtitle1" mt={4} color="secondary.dark">
                {t('offer.sofrDisclaimer')}
              </Typography>
            </Grid>

            <Grid item mb={4}>
              <Typography>
                {t('offer.loan.setLoan.nextConfirmation')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ResponsiveStepperButtons
                previousStepText={t('common.cancel')}
                handleForward={handleNext}
                handleBack={() => navigate(`${DEFAULT_MEMBER_ROUTE}`)}
                nextStepText={t('common.next')}
              />
            </Grid>
          </BorderGridLayout>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SetLoanAmountForm
