import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import Layout from 'components/Layout/Layout'
import LoadingIndicator from 'components/LoadingIndicator'
import styles from './markdownContent.module.scss'

function MarkdownContent({
  type,
}: {
  type: 'privacy' | 'terms' | 'glba' | 'confidentiality'
}) {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [markdownContent, setMarkdownContent] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    const url = {
      privacy: import(
        `locale/translations/PrivacyPolicy.${resolvedLanguage}.md`
      ),
      terms: import(
        `locale/translations/TermsAndConditions.${resolvedLanguage}.md`
      ),
      glba: import(`locale/translations/GlbaPolicy.${resolvedLanguage}.md`),
      confidentiality: import(
        `locale/translations/ConfidentialityStatement.${resolvedLanguage}.md`
      ),
    } as any

    const grabMarkdownContent = async (): Promise<void> => {
      const file = await url[type]
      const content = await fetch(file.default)
      const text = await content.text()

      setMarkdownContent(text)
      setIsLoading(false)
    }
    grabMarkdownContent()
  }, [resolvedLanguage, type])

  return (
    <Layout hasLogo maxWidth="md">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <ReactMarkdown
          className={styles.markdown}
          remarkPlugins={[remarkGfm]}
        >{`${markdownContent}`}</ReactMarkdown>
      )}
    </Layout>
  )
}

export default MarkdownContent
