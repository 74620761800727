import MuiModal, { ModalProps } from '@mui/material/Modal'
import Paper from 'components/mui/Paper/Paper'

type Props = ModalProps

function Modal({ children, ...props }: Props) {
  return (
    <MuiModal
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.sx,
      }}
    >
      <>
        <Paper
          sx={{
            borderRadius: '6px',
            width: { xs: '80%', sm: '60%' },
            maxWidth: 370,
          }}
        >
          {children}
        </Paper>
      </>
    </MuiModal>
  )
}

export default Modal
