import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography/Typography'

import { DEFAULT_ADMIN_MEMBERS_ROUTE } from 'routes/AdminDashboard'
import Button from 'components/mui/Button'
import Layout from 'components/Layout/Layout'
import Paper, { paperShape } from 'components/mui/Paper/Paper'

function SelectMemberType() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const memberTypes = [
    {
      label: t('liquidlpAdmin.memberType.registeredInvestmentAdvisor'),
      action: () => {
        navigate(`${DEFAULT_ADMIN_MEMBERS_ROUTE}/add-member?userType=RIA`, {
          replace: true,
        })
      },
      path: `${DEFAULT_ADMIN_MEMBERS_ROUTE}/add-member?userType=RIA`,
    },
    {
      label: t('liquidlpAdmin.memberType.generalPartner'),
      action: () => {
        navigate(`${DEFAULT_ADMIN_MEMBERS_ROUTE}/add-member?userType=GP`, {
          replace: true,
        })
      },
      path: `${DEFAULT_ADMIN_MEMBERS_ROUTE}/add-member?userType=GP`,
    },
    {
      label: t('liquidlpAdmin.memberType.limitedPartner'),
      action: () => {
        navigate(`${DEFAULT_ADMIN_MEMBERS_ROUTE}/add-member?userType=LP`, {
          replace: true,
        })
      },
      path: `${DEFAULT_ADMIN_MEMBERS_ROUTE}/add-member?userType=LP`,
    },
  ] as any

  return (
    <Layout maxWidth="sm" sx={{ px: 0 }}>
      <Typography
        variant="h2"
        component="h1"
        textTransform="capitalize"
        textAlign="center"
      >
        {t('liquidlpAdmin.memberType.heading')}
      </Typography>

      <Typography my={3} textAlign="center">
        {t('liquidlpAdmin.memberType.subHeading')}
      </Typography>

      <Paper hasBorder>
        {memberTypes?.map((type: any) => (
          <Button
            key={type.label}
            mode="forward"
            variant="text"
            onClick={type.action}
            iconColor="primary.main"
            fullWidth
            sx={{
              ...paperShape,
              backgroundColor: 'white',
              border: '1px solid #BDBDBD',
              color: 'secondary.darkest',
              py: 1.5,
              my: 0.5,
              justifyContent: 'space-between',
              textAlign: 'left',
            }}
          >
            <Typography variant="h4">{type.label}</Typography>
          </Button>
        ))}
      </Paper>
    </Layout>
  )
}

export default SelectMemberType
