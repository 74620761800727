import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import theme from 'styles/customTheme'

interface Props {
  welcomeMessage: string
  greeting: string
}

function Greeting({ welcomeMessage, greeting }: Props) {
  return (
    <Grid
      container
      px={{ xs: 3, smd: 9 }}
      py={{ xs: 3, smd: 4 }}
      sx={{
        background: theme.palette.primary.main,
        borderRadius: '24px',
      }}
      mb={5}
    >
      <Grid item container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            fontWeight={500}
            color="secondary.contrastText"
            textAlign="center"
            mb={2}
            dangerouslySetInnerHTML={{
              __html: welcomeMessage,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography
            variant="h4"
            fontWeight={400}
            color="secondary.contrastText"
            textAlign="center"
          >
            {greeting}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Greeting
