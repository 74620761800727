import Divider from '@mui/material/Divider/Divider'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

export type LoanDetails = {
  heading: string
  isLargeHeading?: boolean
  data: any
  notes?: string
  notesElement?: React.ReactNode
}[]

function OfferDetailsCard({
  header,
  loanDetails,
}: {
  header?: { title: string; description: string }
  loanDetails: LoanDetails
}) {
  return (
    <Grid container>
      {header && (
        <Grid item xs={12} mb={3}>
          <Typography variant="h4" mb={2}>
            {header.title}
          </Typography>
          <Typography>{header.description}</Typography>
          <Divider variant="middle" sx={{ width: 1, mx: 'auto', my: 2.5 }} />
        </Grid>
      )}
      {loanDetails.map((detail, index) => (
        <Grid item container xs={12} rowGap={1.5} key={detail.heading}>
          <Grid item xs={12}>
            <Typography variant="h4">{detail.heading}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="h3"
              variant={detail.isLargeHeading ? 'h1' : 'h3'}
              mb={detail.isLargeHeading && detail.notesElement ? 2 : 0}
            >
              {detail.data}
            </Typography>
          </Grid>
          {detail.notesElement && (
            <Grid item xs={12}>
              {detail.notesElement}
            </Grid>
          )}
          {detail.notes && (
            <Grid item xs={12}>
              <Typography color="secondary.lighter">{detail.notes}</Typography>
            </Grid>
          )}
          {index !== loanDetails.length - 1 && (
            <Divider variant="middle" sx={{ width: 1, mx: 'auto', my: 2.5 }} />
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export default OfferDetailsCard
