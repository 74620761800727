import { Route, Routes } from 'react-router-dom'

import PageNotFound from 'views/PageNotFound'

function Account() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
export default Account
