import { useTranslation } from 'react-i18next'
import { useUnmount } from 'react-use'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Form, Formik } from 'lib/formik'
import { useAuth } from 'app/Auth'
import { LoginDetails } from 'common/types'
import { loginDetailsSchema } from 'lib/validation'

import Alert from 'components/mui/Alert'
import BrandLayout from 'components/BrandLayout/BrandLayout'
import Link from 'components/mui/Link'
import LoadingButton from 'components/mui/LoadingButton'
import TextInput from 'components/mui/TextInput'

const initialValues = {
  email: '',
  password: '',
} as LoginDetails

function Login() {
  const { t } = useTranslation()
  const { loginMutation, sessionStatus, dispatch } = useAuth()

  const handleSubmit = (values: typeof initialValues) => {
    loginMutation.mutate(values)
  }

  // Reset session status on unmount
  useUnmount(
    () =>
      sessionStatus !== null &&
      dispatch({ type: 'set_session_status', payload: null })
  )

  return (
    <BrandLayout>
      <Box
        sx={{
          mx: { sm: 1, md: 6 },
          mt: { xs: 4, sm: 10 },
          px: { xs: 2, sm: 4 },
        }}
      >
        <Typography variant="h2" mt={5} mb={5}>
          {t('user.login.logInOpenstock')}
        </Typography>
        {sessionStatus === 'expired' && (
          <Alert
            severity="warning"
            title={t('user.login.sessionExpiredTitle')}
            sx={{ mb: 3 }}
            icon={false}
          >
            {t('user.login.sessionExpired')}
          </Alert>
        )}
        {/* TODO: Enable federated login */}
        {/* <Button variant="outlined" fullWidth sx={{ borderRadius: '10px' }}>
        {t('user.login.signInGoogle')}
      </Button>
      <Divider className={styles.divider} sx={{ my: 4 }}>
        {t('user.login.or')}
      </Divider> */}
        <Formik
          initialValues={initialValues}
          validationSchema={loginDetailsSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    id="email"
                    name="email"
                    label={t('user.login.email')}
                    placeholder={t('user.login.emailPlaceholder')}
                    type="email"
                    required
                    inputMode="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="password"
                    name="password"
                    label={t('user.login.password')}
                    placeholder={t('user.login.passwordPlaceholder')}
                    type="password"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* TODO: Build/enable remember me functionality, cognito remembers by default so this is extra functionality
                <CheckboxInput
                  name="rememberMe"
                  label={
                    <Typography variant="h5">
                      {t('user.login.rememberMe')}
                    </Typography>
                  }
                />*/}
                </Grid>
                {loginMutation.isError && (
                  <Grid item xs={12}>
                    <Alert title={t('common.error')} sx={{ mb: 1.5 }}>
                      {(loginMutation.error as Error).message ??
                        t('common.errorUnknown')}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12} mb={2}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    loading={loginMutation.isPending || loginMutation.isSuccess}
                  >
                    {t('user.login.logIn')}
                  </LoadingButton>
                </Grid>
                {/* Signup button hidden for now */}
                {/* <Grid xs={12}>
                  <Button fullWidth variant="outlined" to="/login/create">
                    {t('user.login.signUp')}
                  </Button>
                </Grid> */}
              </Grid>
            </Form>
          )}
        </Formik>
        <Grid container rowSpacing={2} mt={1}>
          <Grid item xs={12}>
            <Link to="/login/forgot" variant="subtitle2">
              {t('user.login.forgotPassword')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </BrandLayout>
  )
}

export default Login
