import { gql } from '@apollo/client'

export const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_MUTATION(
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $phoneNumber: String!
    $userType: User_Type
    $userpoolSub: String
    $company: String
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      phoneNumber: $phoneNumber
      userType: $userType
      userpoolSub: $userpoolSub
      company: $company
    ) {
      firstName
      lastName
      emailAddress
      phoneNumber
      company
      userType
      createdAt
      updatedAt
      _id
    }
  }
`

export const CREATE_APPLICATION = gql`
  mutation createApplication($application: Application_Input!) {
    createApplication(application: $application) {
      _id
      createdAt
      updatedAt
      __typename
    }
  }
`

export const UPDATE_APPLICATION = gql`
  mutation updateApplication($id: ID!, $application: Application_Input!) {
    updateApplication(id: $id, application: $application) {
      _id
    }
  }
`

export const SUBMIT_APPLICATION = gql`
  mutation submitApplication($id: ID!) {
    submitApplication(id: $id) {
      _id
    }
  }
`

export const APPROVE_APPLICATION = gql`
  mutation approveApplication($id: ID!) {
    approveApplication(id: $id) {
      _id
    }
  }
`

export const SEND_BACK_APPLICATION = gql`
  mutation sendBackApplication($id: ID!, $reason: String!) {
    sendBackApplication(id: $id, reason: $reason) {
      _id
    }
  }
`

export const DENY_APPLICATION = gql`
  mutation denyApplication($id: ID!, $reason: String!) {
    denyApplication(id: $id, reason: $reason) {
      _id
    }
  }
`

export const CREATE_FINANCING_INFO = gql`
  mutation createFinancingInfo(
    $applicationId: ID!
    $financingInfo: FinancingInfo_Input!
  ) {
    createFinancingInfo(
      applicationId: $applicationId
      financingInfo: $financingInfo
    ) {
      _id
    }
  }
`

export const UPDATE_FINANCING_INFO = gql`
  mutation updateFinancingInfo(
    $applicationId: ID!
    $financingInfo: FinancingInfo_Input!
  ) {
    updateFinancingInfo(
      applicationId: $applicationId
      financingInfo: $financingInfo
    ) {
      _id
    }
  }
`

export const ACCEPT_APPLICATION_OFFER = gql`
  mutation acceptApplicationOffer(
    $id: ID!
    $actualAmount: Float!
    $bankingInfo: BankingInfo_Input!
    $address: User_Address_Input
    $firstName: String
    $lastName: String
  ) {
    acceptApplicationOffer(
      id: $id
      actualAmount: $actualAmount
      bankingInfo: $bankingInfo
      address: $address
      firstName: $firstName
      lastName: $lastName
    ) {
      _id
    }
  }
`

export const APPROVE_APPLICATION_FOR_FINANCING = gql`
  mutation approveApplicationForFinancing($id: ID!, $comment: String) {
    approveApplicationForFinancing(id: $id, comment: $comment) {
      _id
    }
  }
`

export const CREATE_COMPANY_FILE_DOWNLOAD_LINK = gql`
  mutation CREATE_COMPANY_FILE_DOWNLOAD_LINK(
    $companyFileId: ID!
    $version: Int
  ) {
    createCompanyFileDownloadLink(
      companyFileId: $companyFileId
      version: $version
    ) {
      url
    }
  }
`
