import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import LockIcon from '@mui/icons-material/Lock'
import Typography from '@mui/material/Typography'

import {
  UPLOAD_DOCUMENTS_RIA_LIST,
  UPLOAD_DOCUMENTS_GP_LIST,
} from 'lib/formConfig'
import { UploadDocumentsListItem, CompanyFile, UserType } from 'common/types'
import Alert from 'components/mui/Alert'
import UploadFile from 'components/files/UploadFile'

interface Props {
  uploadedFilesByLabel?: { [s: string]: CompanyFile }
  type?: UserType
}

function UploadDocumentsForm({ uploadedFilesByLabel, type }: Props) {
  const { t } = useTranslation()

  const requiredDocuments = useMemo(
    () =>
      (type === 'GP'
        ? UPLOAD_DOCUMENTS_GP_LIST
        : UPLOAD_DOCUMENTS_RIA_LIST
      ).filter((doc) => doc.required),
    [type]
  )
  const optionalDocuments = useMemo(
    () =>
      (type === 'GP'
        ? UPLOAD_DOCUMENTS_GP_LIST
        : UPLOAD_DOCUMENTS_RIA_LIST
      ).filter((doc) => !doc.required),
    [type]
  )

  return (
    <Grid container rowSpacing={2}>
      <Alert
        severity="info"
        variant="standard"
        icon={<LockIcon />}
        sx={{ my: 2 }}
      >
        <Typography>{t('disclosureDocuments.note')}</Typography>
      </Alert>
      <Grid item>
        <Grid item xs={12}>
          <Typography variant="body2" mb={1}>
            {t('disclosureDocuments.form.heading')}
          </Typography>
          <Typography variant="body1">
            {t('disclosureDocuments.form.subHeading')}
          </Typography>
        </Grid>
        {requiredDocuments.map((document: UploadDocumentsListItem) => (
          <Grid
            container
            item
            xs={12}
            my={4}
            columnSpacing={2}
            key={document.name}
          >
            <Grid item xs={12}>
              <UploadFile
                document={document}
                showDescription
                multiple={true}
                uploadToReplace={uploadedFilesByLabel?.[document.name]}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Divider variant="fullWidth" sx={{ mb: 4 }} />
      </Grid>

      <Grid item>
        <Grid item xs={12}>
          <Typography variant="body2" mb={1}>
            {t('disclosureDocuments.form.types.additionalDocuments.heading')}
          </Typography>
          <Typography variant="body1">
            {t('disclosureDocuments.form.types.additionalDocuments.subHeading')}
          </Typography>
        </Grid>
        {optionalDocuments.map((document: UploadDocumentsListItem) => (
          <Grid
            container
            item
            xs={12}
            my={4}
            columnSpacing={2}
            key={document.name}
          >
            <Grid item xs={12}>
              <UploadFile
                document={document}
                showDescription
                multiple={true}
                uploadToReplace={uploadedFilesByLabel?.[document.name]}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default UploadDocumentsForm
