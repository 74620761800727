import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Layout from 'components/Layout/Layout'
import Typography from '@mui/material/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import useSofrRate from 'hooks/useSofrRate'
import { useSteps } from 'hooks/useSteps'
import {
  useAcceptApplicationOffer,
  useGetApplicationQuery,
} from 'lib/apollo/hooks'
import { calculateLoanDetails } from 'lib/data'
import { Form, Formik, FormikHelpers } from 'lib/formik'
import { paymentInformationSchema } from 'lib/validation'
import { DEFAULT_MEMBER_DASHBOARD_ROUTE } from 'routes/MemberDashboard'
import theme from 'styles/customTheme'

import QueryBoundary from 'app/QueryBoundary'
import AlertDialog from 'components/AlertDialog'
import BackLink from 'components/BackLink'
import SideBarStepper from 'components/SideBarStepper/SideBarStepper'

import CustomerPaymentInformation from 'views/member/setLoan/PaymentInformation'
import NextSteps from 'views/member/setLoan/NextSteps'
import ReviewLoanAndPayment from 'views/member/setLoan/ReviewLoanAndPayment'
import SetLoanAmountForm from 'views/member/setLoan/SetLoanAmountForm'

export type SetLoanAmountProps = {
  firstName: string
  lastName: string
  requestedLoanAmount: number
  loanDetails: {
    totalLoan: number
    annualInterest: number
    monthlyInterest: number
    quarterlyInterest: number
  }
  country: string
  state: string
  city: string
  streetAddress: string
  streetAddress2: string
  zipCode: string
  SSN: string
  bankAccountNumber: string
  ABA_routingNumber: string
}

function SetLoanAmount() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { applicationId } = useParams()

  const applicationQuery = useGetApplicationQuery(applicationId)
  const { data } = applicationQuery
  const application = data?.getApplication

  const sofrRateQuery = useSofrRate()
  const sofrRate = sofrRateQuery.data?.percentRate

  const [acceptApplicationOffer, acceptApplicationOfferMutation] =
    useAcceptApplicationOffer()

  const isSubmitting = acceptApplicationOfferMutation.loading

  const { activeStep, handleBack, handleNext } = useSteps()
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  const validationSchema = [null, paymentInformationSchema]

  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleNavigate = () =>
    navigate(`${DEFAULT_MEMBER_DASHBOARD_ROUTE}`, {
      state: {
        unblockPrompt: true,
      },
    })

  const handleAction = () => navigate('/')

  const handleSubmit = async (
    values: SetLoanAmountProps,
    formik?: FormikHelpers<SetLoanAmountProps>
  ) => {
    if (activeStep !== 3) {
      formik?.setTouched({}, false)
      return handleNext()
    }

    const data = {
      id: applicationId,
      actualAmount: values.requestedLoanAmount,
      address: {
        streetAddress: values.streetAddress,
        streetAddress2: values.streetAddress2 || null,
        city: values.city,
        country: values.country,
        state: values.state,
        zipCode: values.zipCode,
      },
      bankingInfo: {
        bankAccountNumber: values.bankAccountNumber ?? null,
        ABA_routingNumber: values.ABA_routingNumber ?? null,
        SSN: values.SSN ?? null,
      },
      firstName: values.firstName,
      lastName: values.lastName,
    }

    if (!acceptApplicationOfferMutation.data) {
      const { errors } = await acceptApplicationOffer({
        variables: data,
        onCompleted: () => setShowSuccessDialog(true),
      })

      if (errors) return
    }
  }

  const handleResetMutation = () => {
    setShowErrorDialog(false)
    acceptApplicationOfferMutation.reset()
  }

  useEffect(() => {
    if (acceptApplicationOfferMutation.error !== undefined) {
      setShowErrorDialog(true)
    }
  }, [acceptApplicationOfferMutation.error])

  const pages: { title: string; description: string }[] = t(
    'offer.loan.pages',
    {
      returnObjects: true,
    }
  )

  const steps: string[] = pages.map(({ title }) => title)

  const calculatedLoanDetails = calculateLoanDetails({
    loanDetails: {
      totalLoan: 0,
      annualInterest: 0,
      monthlyInterest: 0,
      quarterlyInterest: 0,
    },
    application,
    requestedLoanAmount: application?.financingInfo?.commitment ?? 0,
    sofrRate,
  })

  const initialFormData = {
    firstName: application?.firstName ?? '',
    lastName: application?.lastName ?? '',
    requestedLoanAmount: application?.financingInfo?.commitment ?? 0,
    loanDetails: calculatedLoanDetails,
    country: application?.address?.country ?? '',
    state: application?.address?.state ?? '',
    city: application?.address?.city ?? '',
    streetAddress: application?.address?.streetAddress ?? '',
    streetAddress2: application?.address?.streetAddress2 ?? '',
    zipCode: application?.address?.zipCode ?? '',
    SSN: '',
    bankAccountNumber: '',
    ABA_routingNumber: '',
  }

  return (
    <QueryBoundary queries={[applicationQuery, sofrRateQuery]}>
      <Grid container sx={{ flex: 1 }}>
        {isMediumUp && (
          <Grid item xs={12} md={3} lg={2.5}>
            <SideBarStepper
              activeStep={activeStep}
              steps={steps}
              title={t('offer.loan.steps.title')}
              action={handleAction}
              actionTitle={t('common.back')}
            />
          </Grid>
        )}

        <Grid item xs={12} md={9} lg={9.5}>
          <Layout maxWidth="md" isSideBarStepper>
            {isMediumDown && <BackLink onClick={handleAction} />}
            <Grid container rowGap={3} mb={3} maxWidth={{ smd: 0.6 }}>
              <Grid item>
                <Typography variant="h2" component="h1">
                  {pages[activeStep].title}
                </Typography>
              </Grid>

              {pages[activeStep].description && (
                <Grid item>
                  <Typography mb={1}>
                    {pages[activeStep].description}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Formik
              initialValues={initialFormData}
              validationSchema={validationSchema[activeStep]}
              onSubmit={handleSubmit}
            >
              {({ submitForm, values }) => {
                const caseProps = {
                  handleBack,
                  handleNext,
                  isSubmitting,
                  values,
                  sofrRate,
                  application,
                }
                return (
                  <Form promptOnLeave>
                    {(() => {
                      switch (activeStep) {
                        case 0:
                          return <SetLoanAmountForm {...caseProps} />
                        case 1:
                          return <CustomerPaymentInformation {...caseProps} />
                        case 2:
                          return <ReviewLoanAndPayment {...caseProps} />
                        case 3:
                          return <NextSteps {...caseProps} />
                        default:
                          return
                      }
                    })()}

                    {/* Shows mutation error when submitting form  */}
                    <AlertDialog
                      isOpen={showErrorDialog}
                      onClose={() => setShowErrorDialog(false)}
                      description={t(
                        'offer.loan.nextSteps.modal.error.description'
                      )}
                      primaryButtonAction={() => {
                        submitForm()
                        setShowErrorDialog(false)
                      }}
                      secondaryButtonAction={handleResetMutation}
                    />

                    <AlertDialog
                      mode="success"
                      isOpen={showSuccessDialog}
                      onClose={() => setShowSuccessDialog(false)}
                      title={t('offer.loan.nextSteps.modal.success.title')}
                      description={t(
                        'offer.loan.nextSteps.modal.success.description'
                      )}
                      primaryButtonText={t('common.close')}
                      primaryButtonAction={() => handleNavigate()}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Layout>
        </Grid>
      </Grid>
    </QueryBoundary>
  )
}

export default SetLoanAmount
