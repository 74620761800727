// import {  useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import ConfirmationImage from 'assets/confirmation.svg'
import Button from 'components/mui/Button'
import { DEFAULT_ADMIN_DASHBOARD_ROUTE } from 'routes/AdminDashboard'
import Layout from 'components/Layout/Layout'

function Confirmation() {
  const navigate = useNavigate()
  //   const { t } = useTranslation()

  const handleNavigate = () => navigate(DEFAULT_ADMIN_DASHBOARD_ROUTE)

  return (
    <Layout>
      <Typography variant="h2" component="h1" mb={2}>
        DONE
      </Typography>

      <Button onClick={handleNavigate} mode="forward" sx={{ my: 4 }}>
        DONE
      </Button>

      <img src={ConfirmationImage} alt="" width="375" height="370" />
    </Layout>
  )
}

export default Confirmation
