import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography/Typography'

import QueryBoundary from 'app/QueryBoundary'
import { useGetUsersQuery } from 'lib/apollo/hooks'
import MembersTable from 'components/tables/MembersTable'
import Layout from 'components/Layout/Layout'

function Members() {
  const { t } = useTranslation()

  const usersQuery = useGetUsersQuery()
  const { data: userData } = usersQuery
  const members = userData?.getUsers

  return (
    <QueryBoundary queries={[usersQuery]}>
      <Layout maxWidth="lg">
        {/* Heading */}
        <Typography variant="h1" mb={4} textAlign="center">
          {t('liquidlpAdmin.members.heading')}
        </Typography>

        <MembersTable membersList={members ?? []} />
      </Layout>
    </QueryBoundary>
  )
}

export default Members
