import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import i18n from 'locale/i18n'

import { User } from 'common/types'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import {
  DEFAULT_ADMIN_ROUTE,
  DEFAULT_ADMIN_MEMBERS_ROUTE,
} from 'routes/AdminDashboard'

import Grid from '@mui/material/Grid'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import Button from 'components/mui/Button'
import PaginatedTable, {
  SortableHeadCells,
  PaginatedTableRow,
} from 'components/mui/PaginatedTable'
import Paper from 'components/mui/Paper/Paper'
import StatusIndicator from 'components/StatusIndicator'

function MembersTableRow({ member }: { member: User }) {
  const navigate = useNavigate()
  const handleNavigate = (memberId: string) =>
    navigate(`${DEFAULT_ADMIN_ROUTE}/members/${memberId}/applications`)

  return (
    <PaginatedTableRow onClick={() => handleNavigate(member._id)} hover>
      <TableCell component="th" id={member._id} scope="row">
        <Typography>
          {dayjs(member.createdAt).format(monthDayFullYearSimplifiedFormat)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{`${member.firstName} ${member.lastName}`}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{member.userType}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{member.company !== '' ? member.company : '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{member.phoneNumber}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{member.emailAddress}</Typography>
      </TableCell>
      <TableCell>
        <StatusIndicator
          status={member.userpoolSub !== null ? 'active' : 'inactive'}
          category="member"
        />
      </TableCell>
    </PaginatedTableRow>
  )
}

export default function MembersTable({ membersList }: { membersList: User[] }) {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const filteredList = useMemo(
    () =>
      search
        ? membersList.filter((m) =>
            `${m.firstName} ${m.lastName} ${m.emailAddress}`
              .toLowerCase()
              .match(search.toLowerCase())
          )
        : membersList,
    [membersList, search]
  )

  const headCells: SortableHeadCells = [
    {
      id: 'createdAt',
      label: i18n.t('liquidlpAdmin.allMembersTable.tableHeadCell1'),
      width: '12%',
      sortable: true,
    },
    {
      id: 'firstName',
      label: i18n.t('liquidlpAdmin.allMembersTable.tableHeadCell2'),
      width: '20%',
      sortable: true,
    },
    {
      id: 'userType',
      label: i18n.t('liquidlpAdmin.allMembersTable.tableHeadCell3'),
      width: '12%',
      sortable: true,
    },
    {
      id: 'company',
      label: i18n.t('liquidlpAdmin.allMembersTable.tableHeadCell4'),
      width: '12%',
      sortable: true,
    },
    {
      id: 'phoneNumber',
      label: i18n.t('liquidlpAdmin.allMembersTable.tableHeadCell5'),
      width: '12%',
    },
    {
      id: 'emailAddress',
      label: i18n.t('liquidlpAdmin.allMembersTable.tableHeadCell6'),
      width: '20%',
    },
    {
      id: 'status',
      label: i18n.t('liquidlpAdmin.allMembersTable.tableHeadCell7'),
      width: '12%',
    },
  ]

  return (
    <Paper hasBorder>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h2" ml={{ xs: 0, sm: 2 }}>
            {t('liquidlpAdmin.allMembersTable.heading')}
          </Typography>
        </Grid>

        <Grid item xs={true} sx={{ textAlign: 'right' }}>
          <TextField
            placeholder={t('liquidlpAdmin.allMembersTable.search')}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item>
          <Button
            mode="forward"
            to={`${DEFAULT_ADMIN_MEMBERS_ROUTE}/member-type`}
            sx={{
              py: 0.6,
              width: 'fit-content',
              mx: 'auto',
              my: 5,
              backgroundColor: 'secondary.darker',
            }}
          >
            {t('liquidlpAdmin.allMembersTable.addMemberButtonLabel')}
          </Button>
        </Grid>
      </Grid>
      <PaginatedTable
        ariaLabel={t('liquidlpAdmin.allMembersTable.tableHeadCell7')}
        headCells={headCells}
        isPaperComponent={false}
        rowsPerPage={10}
        list={filteredList}
        row={(member) => <MembersTableRow key={member._id} member={member} />}
        defaultSort="createdAt"
        defaultDirection="desc"
      />
    </Paper>
  )
}
