import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import { useGetApplicationQuery } from 'lib/apollo/hooks'
import QueryBoundary from 'app/QueryBoundary'
import ApplicationInformation from 'views/liquidlpAdmin/applications/ApplicationInformation'
import ApplicationStatus from 'views/liquidlpAdmin/applications/ApplicationStatus'
import BackLink from 'components/BackLink'
import FinancingInfo from 'views/liquidlpAdmin/applications/FinancingInfo'
import Layout from 'components/Layout/Layout'
import Paper from 'components/mui/Paper/Paper'
import StatusIndicator from 'components/StatusIndicator'
import TabBar from 'components/mui/TabBar'

function ApplicationDetails() {
  const { t } = useTranslation()
  const { applicationId } = useParams()

  const applicationQuery = useGetApplicationQuery(applicationId)
  const { data } = applicationQuery
  const application = data?.getApplication

  const tabList = application
    ? [
        {
          label: t('liquidlpAdmin.applications.tabs.application'),
          path: 'details',
          element: <ApplicationInformation application={application} />,
        },
        {
          label: t('liquidlpAdmin.applications.tabs.financing'),
          path: 'financing-information',
          element: <FinancingInfo application={application} />,
        },
        {
          label: t('liquidlpAdmin.applications.tabs.status'),
          path: 'status',
          element: <ApplicationStatus application={application} />,
        },
      ]
    : []

  return (
    <QueryBoundary queries={[applicationQuery]}>
      <Layout maxWidth="lg">
        <BackLink
          to={`/admin/applications?stage=${application?.currentStage.toLowerCase()}`}
        />

        {/* Heading */}
        <Typography variant="h1" mb={4} textAlign="center">
          {t('liquidlpAdmin.applications.details.heading')}
        </Typography>

        <Paper>
          <Grid item container xs={12} sm={4} mb={2} alignContent="center">
            {application && (
              <StatusIndicator
                status={application.currentStage}
                category="application"
                isCapitalizeLabel={false}
              />
            )}
          </Grid>

          <Grid container rowSpacing={4}>
            <TabBar tabList={tabList} fallbackPath="details" />
          </Grid>
        </Paper>
      </Layout>
    </QueryBoundary>
  )
}

export default ApplicationDetails
