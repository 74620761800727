import { Trans, useTranslation } from 'react-i18next'

import Container from 'components/mui/Container/Container'
import Link from 'components/mui/Link'
import {
  LIQUIDLP_PRIVACY_POLICY,
  LIQUIDLP_TERMS_CONDITIONS,
  LIQUIDLP_COMMUNITY_URL,
  LIQUIDLP_SUPPORT_EMAIL,
} from 'lib/config'
import dayjs from 'lib/dayjs'

import Box from '@mui/material/Box/Box'
import Grid from '@mui/material/Grid/Grid'
import MuiContainer, { ContainerProps } from '@mui/material/Container'
import Typography from '@mui/material/Typography/Typography'
import styles from './globalLayout.module.scss'

type Props = ContainerProps

function GlobalLayout({ children }: Props) {
  const { t } = useTranslation()
  const currentYear = dayjs().year()

  return (
    <MuiContainer className={styles.container} maxWidth={false}>
      <Box className={styles.childrenContainer}>{children}</Box>
      {/* Footer */}
      <Box role="footer" className={styles.footer}>
        <Container maxWidth="lg" sx={{ px: { xs: 0, sm: 'default' } }}>
          <Grid container rowGap={{ xs: 2, sm: 0 }}>
            {/* Contact Links */}
            <Grid
              item
              xs={12}
              sm={3.5}
              md={3}
              lg={2}
              container
              direction="column"
              rowGap={{ xs: 1, sm: 2 }}
            >
              <Grid item>
                <Link
                  href={`mailto:${LIQUIDLP_SUPPORT_EMAIL}?subject=${t(
                    'common.emailSubject'
                  )}`}
                  variant="subtitle2"
                  className={styles.socialLink}
                >
                  {t('footer.contactUs')}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href={LIQUIDLP_COMMUNITY_URL}
                  target="_blank"
                  variant="subtitle2"
                  className={styles.socialLink}
                >
                  {t('footer.joinOurCommunity')}
                </Link>
              </Grid>
            </Grid>
            {/* Legal links */}
            <Grid
              item
              xs={10}
              sm={7.5}
              md={9}
              lg={10}
              columns={12}
              container
              direction="column"
              rowGap={{ xs: 0.5, sm: 2 }}
            >
              <Grid item>
                <Typography variant="subtitle1" color="secondary.lightest">
                  {t('footer.copyright', { currentYear })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={styles.moreDetails} variant="subtitle1">
                  <Trans i18nKey="account.footer.moreOptions">
                    More details on our legal framework can be found in
                    our&nbsp;
                    <Link
                      href={LIQUIDLP_TERMS_CONDITIONS}
                      target="_blank"
                      color="inherit"
                      variant="subtitle2"
                      className={styles.legalLink}
                    >
                      Terms & Conditions
                    </Link>
                    &nbsp;and&nbsp;
                    <Link
                      href={LIQUIDLP_PRIVACY_POLICY}
                      target="_blank"
                      variant="subtitle2"
                      color="inherit"
                      className={styles.legalLink}
                    >
                      Privacy Policy
                    </Link>
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </MuiContainer>
  )
}

export default GlobalLayout
