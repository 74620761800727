import { Route, Routes } from 'react-router-dom'

import AddMemberForm from 'views/liquidlpAdmin/AddMemberForm'
import Applications from 'views/liquidlpAdmin//applications/Applications'
import ApplicationsByUser from 'views/liquidlpAdmin//applications/ApplicationsByUser'
import ApplicationDetails from 'views/liquidlpAdmin/applications/ApplicationDetails'
import Confirmation from 'views/liquidlpAdmin/Confirmation'
import Dashboard from 'views/liquidlpAdmin/Members'
import PageNotFound from 'views/PageNotFound'
import SelectMemberType from 'views/liquidlpAdmin/SelectMemberType'

export const DEFAULT_ADMIN_ROUTE = '/admin'
export const DEFAULT_ADMIN_DASHBOARD_ROUTE = '/admin/dashboard'
export const DEFAULT_ADMIN_MEMBERS_ROUTE = '/admin/members'

function AdminDashboard() {
  return (
    <Routes>
      <Route path="/members" element={<Dashboard />} />
      <Route path="/members/member-type/*" element={<SelectMemberType />} />
      <Route path="/members/add-member/*" element={<AddMemberForm />} />
      <Route path="/applications" element={<Applications />} />
      <Route
        path="/applications/:applicationId/*"
        element={<ApplicationDetails />}
      />
      <Route
        path="/members/:userId/applications"
        element={<ApplicationsByUser />}
      />
      <Route path="/confirmation" element={<Confirmation />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
export default AdminDashboard
