import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import {
  useApproveApplication,
  useApproveApplicationForFinancing,
  useDenyApplication,
  useGetApplicationQuery,
  useSendBackApplication,
} from 'lib/apollo/hooks'
import { Form, Formik } from 'lib/formik'
import {
  acceptApplicationStagesOptions,
  applicationStagesLabelOptions,
  applicationStagesOptions,
} from 'lib/formConfig'
import { applicationStageSchema } from 'lib/validation'

import { Application } from 'common/types'
import BorderGridLayout from 'components/BorderGridLayout'
import Button from 'components/mui/Button'
import MutationModal from 'components/MutationModal'
import OutlinedSelectInput from 'components/mui/OutlinedSelectInput'
import RequiredNote from 'components/RequiredNote'
import TextInput from 'components/mui/TextInput'

const StatusSubmitted = ({
  stage,
  comments,
}: {
  stage: Application['currentStage']
  comments?: string | null
}) => {
  const { t } = useTranslation()

  const stageLabel = applicationStagesLabelOptions.find(
    (option) => option.value === stage
  )?.label

  return (
    <BorderGridLayout
      maxWidth={{ xs: '370px', sm: '520px' }}
      container
      rowSpacing={2}
    >
      <Grid item xs={12} mb={{ xs: 2, sm: 4 }}>
        <Typography variant="h3" mb={2}>
          {t('liquidlpAdmin.applications.status.inputLabel')}
        </Typography>
        {stage === 'INITIAL' ? (
          t('liquidlpAdmin.applications.status.initial')
        ) : (
          <Typography>{stageLabel}</Typography>
        )}
      </Grid>
      {stage !== 'APPROVED' && stage !== 'INITIAL' && (
        <Grid item xs={12}>
          <Typography variant="h3" mb={2}>
            {t('liquidlpAdmin.applications.status.commentsHeading')}
          </Typography>

          <Typography variant="subtitle2">
            {t('liquidlpAdmin.applications.status.commentsLabel')}
          </Typography>

          <Typography>{comments ?? '-'}</Typography>
        </Grid>
      )}
    </BorderGridLayout>
  )
}

function ApplicationStatus({ application }: { application: Application }) {
  const { t } = useTranslation()
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [approveApplication, approveApplicationMutation] =
    useApproveApplication(application._id)
  const [denyApplication, denyApplicationMutation] = useDenyApplication(
    application._id
  )
  const [sendBackApplication, sendBackApplicationMutation] =
    useSendBackApplication(application._id)
  const [
    approveApplicationForFinancing,
    approveApplicationForFinancingMutation,
  ] = useApproveApplicationForFinancing(application._id)

  const applicationQuery = useGetApplicationQuery(application._id)

  const currentStageComments = useMemo(
    () => application?.stage[application?.stage.length - 1]?.revisionComments,
    [application?.stage]
  )

  const isApplicationMutationError =
    approveApplicationMutation.error !== undefined ||
    denyApplicationMutation.error !== undefined ||
    sendBackApplicationMutation.error !== undefined ||
    approveApplicationForFinancingMutation.error !== undefined

  const isApplicationMutationLoading =
    approveApplicationMutation.loading ||
    denyApplicationMutation.loading ||
    sendBackApplicationMutation.loading ||
    approveApplicationForFinancingMutation.loading ||
    applicationQuery.loading

  const handleClose = () => {
    setShowConfirmDialog(false)
    approveApplicationMutation.reset()
    denyApplicationMutation.reset()
    sendBackApplicationMutation.reset()
  }

  const handleSubmit = async (values: typeof initialValues) => {
    if (!approveApplicationMutation.data && values.stage === 'APPROVED') {
      const { errors } = await approveApplication({
        variables: {
          id: application._id,
        },
        onCompleted: () => {
          setShowConfirmDialog(false)
          approveApplicationMutation.reset()
        },
      })

      if (errors) return
    }

    if (!denyApplicationMutation.data && values.stage === 'DENIED') {
      const { errors } = await denyApplication({
        variables: {
          id: application._id,
          reason: values.comment,
        },
        onCompleted: () => {
          setShowConfirmDialog(false)
          denyApplicationMutation.reset()
        },
      })

      if (errors) return
    }

    if (
      !sendBackApplicationMutation.data &&
      values.stage === 'MORE_INFORMATION_REQUIRED'
    ) {
      const { errors } = await sendBackApplication({
        variables: {
          id: application._id,
          reason: values.comment,
        },
        onCompleted: () => {
          setShowConfirmDialog(false)
          sendBackApplicationMutation.reset()
        },
      })

      if (errors) return
    }

    if (
      !approveApplicationForFinancingMutation.data &&
      values.stage === 'FUNDED'
    ) {
      const { errors } = await approveApplicationForFinancing({
        variables: {
          id: application._id,
          comment: values.comment,
        },
        onCompleted: () => {
          setShowConfirmDialog(false)
          approveApplicationForFinancingMutation.reset()
        },
      })

      if (errors) return
    }
  }

  const initialValues = {
    stage: application?.currentStage ?? 'UNDER_REVIEW',
    comment: '',
  }

  if (
    application.currentStage !== 'UNDER_REVIEW' &&
    application?.currentStage !== 'TERMS_REQUESTED'
  ) {
    return (
      <StatusSubmitted
        stage={application.currentStage}
        comments={currentStageComments}
      />
    )
  }

  return (
    <BorderGridLayout container maxWidth={{ xs: '370px', sm: '520px' }}>
      <Typography variant="h3" mb={4}>
        {t('liquidlpAdmin.applications.status.heading')}
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={applicationStageSchema}
        onSubmit={() => setShowConfirmDialog(true)}
      >
        {({ values }) => {
          const options =
            application?.currentStage === 'TERMS_REQUESTED'
              ? acceptApplicationStagesOptions
              : applicationStagesOptions

          const modifiedApplicationStagesOptions = options.map((option) => ({
            ...option,
            disabled: option.value === application?.currentStage,
          }))

          return (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <OutlinedSelectInput
                    id="application-stage"
                    name="stage"
                    label={t('liquidlpAdmin.applications.status.inputLabel')}
                    placeholder={t('common.selectPlaceholder')}
                    options={modifiedApplicationStagesOptions}
                    value={values.stage}
                  />
                </Grid>

                {values.stage === 'APPROVED' && (
                  <RequiredNote
                    label={t(
                      'liquidlpAdmin.applications.status.stages.approvedHint'
                    )}
                  />
                )}
                {values.stage !== 'APPROVED' &&
                  values.stage !== 'TERMS_REQUESTED' &&
                  values.stage !== 'UNDER_REVIEW' && (
                    <Grid item xs={12} mt={{ xs: 2, sm: 6 }}>
                      <Typography variant="h3" mb={2}>
                        {t('liquidlpAdmin.applications.status.commentsHeading')}
                      </Typography>

                      <TextInput
                        id="application-comment"
                        name="comment"
                        label={t(
                          'liquidlpAdmin.applications.status.commentsLabel'
                        )}
                        placeholder={t(
                          'liquidlpAdmin.applications.status.commentsPlaceholder'
                        )}
                        value={values.comment}
                        multiline
                        minRows={6}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} mt={4}>
                  <Button
                    type="submit"
                    sx={{ ml: 0.5 }}
                    disabled={values.stage === application?.currentStage}
                  >
                    {t('common.submit')}
                  </Button>
                </Grid>
              </Grid>

              {/* Shows confirmation dialog to submit form  */}
              <MutationModal
                mode="info"
                isError={isApplicationMutationError}
                isLoading={isApplicationMutationLoading}
                isOpen={showConfirmDialog}
                onClose={handleClose}
                title={t('liquidlpAdmin.applications.status.confirm.title')}
                primaryButtonAction={() => handleSubmit(values)}
                primaryButtonText={t('common.confirm')}
                secondaryButtonAction={handleClose}
                secondaryButtonText={t('common.cancel')}
              >
                <Typography>
                  {t('liquidlpAdmin.applications.status.confirm.description')}
                </Typography>
              </MutationModal>
            </Form>
          )
        }}
      </Formik>
    </BorderGridLayout>
  )
}

export default ApplicationStatus
