import i18n from 'locale/i18n'
import {
  DOC_SUPPORTED_FILE_TYPES,
  SPREADSHEET_SUPPORTED_FILE_TYPES,
} from 'lib/config'
import statesTaxTable from 'lib/taxTable.json'

//COMMON
export const yesNoOptions = [
  { label: i18n.t('common.yes'), value: true },
  { label: i18n.t('common.no'), value: false },
]

export const defaultCountry = 'us'
export const defaultWebsiteProtocol = 'https://'

export const uploadDocumentsFields = [
  'capitalizationTable',
  'companyOverviewFile',
  'financialStatementsFile',
  'historyOfValuationsFile',
  'listOfPrimaryInvestorsFile',
  'employeeOutstandingFile',
  'recent409AValuationFile',
]

export const UPLOAD_DOCUMENTS_RIA_LIST = [
  {
    name: 'financialStatements',
    title: i18n.t('disclosureDocuments.form.types.financialStatements.title'),
    description: i18n.t(
      'disclosureDocuments.form.types.financialStatements.description'
    ),
    required: true,
  },
  {
    name: 'fundOverviewPresentation',
    title: i18n.t(
      'disclosureDocuments.form.types.fundOverviewPresentation.title'
    ),
    required: true,
  },
  {
    name: 'subscriptionDocument',
    title: i18n.t('disclosureDocuments.form.types.subscriptionDocument.title'),
    required: true,
  },
  {
    name: 'mostRecentAccountStatements',
    title: i18n.t(
      'disclosureDocuments.form.types.mostRecentAccountStatements.title'
    ),
    required: true,
  },
  {
    name: 'additionalDocuments',
    title: i18n.t('disclosureDocuments.form.types.additionalDocuments.title'),
    required: false,
  },
]

export const UPLOAD_DOCUMENTS_GP_LIST = [
  {
    name: 'lpAgreement',
    title: i18n.t('disclosureDocuments.form.types.lpAgreement.title'),
    description: i18n.t(
      'disclosureDocuments.form.types.lpAgreement.description'
    ),
    required: true,
  },
  {
    name: 'financialStatementsAudited',
    title: i18n.t(
      'disclosureDocuments.form.types.financialStatementsAudited.title'
    ),
    description: i18n.t(
      'disclosureDocuments.form.types.financialStatementsAudited.description'
    ),
    required: true,
  },
  {
    name: 'fundOverviewPresentation',
    title: i18n.t(
      'disclosureDocuments.form.types.fundOverviewPresentation.title'
    ),
    required: true,
  },
  {
    name: 'k1Document',
    title: i18n.t('disclosureDocuments.form.types.k1Document.title'),
    required: true,
  },
  {
    name: 'mostRecentAccountStatements',
    title: i18n.t(
      'disclosureDocuments.form.types.mostRecentAccountStatements.title'
    ),
    required: true,
  },
  {
    name: 'additionalDocuments',
    title: i18n.t('disclosureDocuments.form.types.additionalDocuments.title'),
    required: false,
  },
]

export const UPLOAD_DOCUMENTS_LIST = [
  {
    name: 'financialStatementsFile',
    title: i18n.t('documents.financialStatementsFile.title'),
    description: i18n.t('documents.financialStatementsFile.description'),
    required: true,
  },
  {
    name: 'recent409AValuationFile',
    title: i18n.t('documents.recent409AValuationFile.title'),
    required: true,
  },
  {
    name: 'companyOverviewFile',
    title: i18n.t('documents.companyOverviewFile.title'),
    description: i18n.t('documents.companyOverviewFile.description'),
    required: false,
  },
  {
    name: 'historyOfValuationsFile',
    title: i18n.t('documents.historyOfValuationsFile.title'),
    required: false,
  },
  {
    name: 'listOfPrimaryInvestorsFile',
    title: i18n.t('documents.listOfPrimaryInvestorsFile.title'),
    required: false,
  },
  {
    name: 'employeeOutstandingFile',
    title: i18n.t('documents.employeeOutstandingFile.title'),
    fileTypes: {
      ...SPREADSHEET_SUPPORTED_FILE_TYPES,
      ...DOC_SUPPORTED_FILE_TYPES,
    },
    required: false,
  },
]

export const UPLOAD_DOCUMENTS_LIST_BY_REQUIRED = UPLOAD_DOCUMENTS_LIST.reduce(
  (acc: any, file: any) => {
    if (file.required) {
      acc.required.push(file)
    } else {
      acc.optional.push(file)
    }

    return acc
  },
  {
    required: [],
    optional: [],
  }
)

export const OFFER_UPLOAD_DOCUMENTS_LIST = UPLOAD_DOCUMENTS_LIST.map(
  (document) => ({ ...document, required: false })
)

export const UPLOAD_CAPITALIZATION_TABLE = {
  name: 'capitalizationTable',
  title: i18n.t('documents.capitalizationTable.title'),
  required: true,
}

export const UPLOAD_USER_INFORMATION = {
  name: 'shareholderInformation',
  title: i18n.t('documents.shareholderInformation.title'),
  required: true,
}

export const UPLOAD_INGESTION_SPREADSHEET = {
  name: 'ingestionSpreadsheet',
  title: i18n.t('documents.ingestionSpreadsheet.title'),
  required: true,
}

// OFFER
export const offerFixedTimelineDays = 60
export const minimumLoanAmount = 100000
export const maximumLoanAmount = 1000000

// OPENSTOCK ADMIN - LOAN INFO

export const loanTypes = [
  {
    value: 'VARIABLE_RATE_LOAN',
    label: i18n.t('offer.details.loanType.variableRateLoan'),
  },
]

export const loanStructures = [
  {
    value: 'INTEREST_ONLY_PAYMENTS',
    label: i18n.t('offer.details.repaymentPlan.interestOnly'),
  },
]

export const loanRepaymentSchedules = [
  {
    value: 'monthly',
    label: i18n.t('offer.details.repaymentSchedule.monthly'),
  },
  {
    value: 'quarterly',
    label: i18n.t('offer.details.repaymentSchedule.quarterly'),
  },
]

export const useOfProceeds = [
  {
    value: 'Working Capital',
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.useOfProceeds.options.workingCapital'
    ),
  },
  {
    value: 'Investment Opportunity',
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.useOfProceeds.options.investmentOpportunity'
    ),
  },
  {
    value: 'Refinance',
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.useOfProceeds.options.refinance'
    ),
  },
  {
    value: 'Capital Call',
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.useOfProceeds.options.capitalCall'
    ),
  },
  {
    value: 'Tax Purposes',
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.useOfProceeds.options.taxPurposes'
    ),
  },
  {
    value: 'Other',
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.useOfProceeds.options.other'
    ),
  },
]

export const defaultInterestRate = 8
export const defaultLoanSettings = {
  loanType: 'VARIABLE_RATE_LOAN',
  loanStructure: 'INTEREST_ONLY_PAYMENTS',
  loanRepaymentSchedule: 'QUARTERLY',
}

export const applicationStagesOptions = [
  {
    label: i18n.t('liquidlpAdmin.applications.status.pending'),
    value: 'UNDER_REVIEW',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.approved'),
    value: 'APPROVED',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.denied'),
    value: 'DENIED',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.additionalInfo'),
    value: 'MORE_INFORMATION_REQUIRED',
  },
]

export const acceptApplicationStagesOptions = [
  {
    label: i18n.t('liquidlpAdmin.applications.status.terms'),
    value: 'TERMS_REQUESTED',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.accepted'),
    value: 'FUNDED',
  },
]

export const applicationStagesLabelOptions = [
  {
    label: i18n.t('liquidlpAdmin.applications.status.pending'),
    value: 'UNDER_REVIEW',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.approved'),
    value: 'APPROVED',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.denied'),
    value: 'DENIED',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.additionalInfo'),
    value: 'MORE_INFORMATION_REQUIRED',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.terms'),
    value: 'TERMS_REQUESTED',
  },
  {
    label: i18n.t('liquidlpAdmin.applications.status.accepted'),
    value: 'FUNDED',
  },
]

export const applicationStagesValues: any = applicationStagesLabelOptions.map(
  (option) => option.value
)

export const loanTermOptions = [
  {
    label: i18n.t(
      'liquidlpAdmin.applications.applicationInfo.loan.loanTerm.termLoan'
    ),
    value: 'termLoan',
  },
  {
    label: i18n.t(
      'liquidlpAdmin.applications.applicationInfo.loan.loanTerm.drawDown'
    ),
    value: 'drawDown',
  },
]

export const loanDurationOptions = [
  {
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.duration.6months'
    ),
    value: 6,
  },
  {
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.duration.12months'
    ),
    value: 12,
  },
  {
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.duration.18months'
    ),
    value: 18,
  },
  {
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.duration.24months'
    ),
    value: 24,
  },
  {
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.duration.30months'
    ),
    value: 30,
  },
  {
    label: i18n.t(
      'member.newApplication.requestedLoanAmount.labels.duration.36months'
    ),
    value: 36,
  },
]

// COMPANY REGISTRATION PRODUCT OFFERINGS
export const productOfferings = [
  {
    label: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.nonRecourseLoan.label'
    ),
    description: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.nonRecourseLoan.description'
    ),
  },
  {
    label: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.stockOptionFinancing.label'
    ),
    description: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.stockOptionFinancing.description'
    ),
  },
  {
    label: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.tenderOffer.label'
    ),
    description: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.tenderOffer.description'
    ),
  },
  {
    label: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.shareBuyback.label'
    ),
    description: i18n.t(
      'account.company.offeringsInterestsQuestions.productOfferings.offerings.shareBuyback.description'
    ),
  },
]

// Liquidity Calculator
export const financialProductsList: string[] = [
  i18n.t('calculator.details.form.nonRecourseLoan'),
  i18n.t('calculator.details.form.tenderOffer'),
  i18n.t('calculator.details.form.options'),
  i18n.t('calculator.details.form.data'),
  i18n.t('calculator.details.form.education'),
  i18n.t('calculator.details.form.haventDecided'),
]

export const calcChartLoopIteration = 7
export const unitedStatesRegions = Object.keys(statesTaxTable.data)

export const sharesAssumedValues = {
  termInYears: 3,
  annualInterestRate: 15,
  originationFee: 5,
  maxPercentageOfShares: 25,
  maxPercentageValueOfShares: 25,
  commission: 5,
  federalLtCapitalGains: 20,
}

export const optionsAssumedValues = {
  termInYears: 3,
  grossAnnualIncome: 150000,
  annualInterestRate: 15,
  originationFee: 5,
  federalTax: 37,
  federalLtCapitalGains: 20,
  alternativeMinTax: 35, //Alternative Minimum Tax (Fed & State) %
}

export const optionsCalculatorTypeOptions = [
  {
    label: i18n.t('calculator.tab.options.form.optionsTypeOptions.iso'),
    value: 'ISO',
  },
  {
    label: i18n.t('calculator.tab.options.form.optionsTypeOptions.nso'),
    value: 'NSO',
  },
]
