import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { useField } from 'formik'

import TextField, { TextFieldProps } from '@mui/material/TextField/TextField'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

interface CustomProps {
  onChange: (event: { target: { name: string; value: number } }) => void
  name: string
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.floatValue as number,
            },
          })
        }}
        thousandSeparator
        {...other}
      />
    )
  }
)

export type TextInputProps = TextFieldProps & {
  name: string
  maxLength?: number
  delimiter?: string
  suffixText?: string
  showErrorHelperText?: boolean
  showErrorOnTouchedOnly?: boolean
  isNumericFormat?: boolean
  isInputCursive?: boolean
  hasDecimalScale?: boolean
  showSignHereIndicator?: boolean
}

function TextInput({
  inputMode = 'text',
  name,
  maxLength,
  delimiter,
  type = 'text',
  suffixText,
  variant = 'outlined',
  showErrorHelperText = true,
  showErrorOnTouchedOnly = true,
  isNumericFormat = false,
  isInputCursive = false,
  hasDecimalScale = true,
  showSignHereIndicator = false,
  label,
  ...props
}: TextInputProps) {
  const { t } = useTranslation()
  const [field, meta] = useField(name)
  const [showPassword, setShowPassword] = useState(false)

  const config = {
    ...field,
    ...props,
  }

  if (
    (showErrorOnTouchedOnly && meta.touched && meta.error) ||
    (!showErrorOnTouchedOnly && meta.error)
  ) {
    config.error = true
    showErrorHelperText && (config.helperText = meta.error)
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) =>
    e.preventDefault()

  const decimalInputProps = isNumericFormat &&
    hasDecimalScale && {
      fixedDecimalScale: true,
      decimalScale: 2,
    }

  return (
    <Grid sx={{ position: 'relative' }}>
      {showSignHereIndicator && (
        <Box
          display="flex"
          sx={{
            top: 0,
            right: 0,
            position: 'absolute',
            color: 'error.main',
          }}
        >
          <Typography pr={1} pt={0.2} variant="subtitle2">
            {t('common.signHere')}
          </Typography>
          <BorderColorIcon sx={{ color: 'error.main', fontSize: '1rem' }} />
        </Box>
      )}
      {label && (
        <Typography variant="h6" mb={1}>
          {label}
          {props.required && <span style={{ color: '#CF0C0C' }}> *</span>}
        </Typography>
      )}
      <TextField
        {...config}
        fullWidth
        variant={variant}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        inputProps={{
          inputMode,
          maxLength,
          ...decimalInputProps,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {type === 'password' && (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              )}
              {suffixText && <Typography>{suffixText}</Typography>}
            </InputAdornment>
          ),
          startAdornment: delimiter && (
            <InputAdornment position="start">{delimiter}</InputAdornment>
          ),
          inputComponent: isNumericFormat
            ? (NumericFormatCustom as any)
            : undefined,
          sx: {
            input: {
              fontFamily: isInputCursive ? 'Cedarville Cursive' : 'inherit',
            },
          },
        }}
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
  )
}

export default TextInput
