import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography/Typography'

import Button from 'components/mui/Button'
import Layout from 'components/Layout/Layout'
import Link from 'components/mui/Link'

import { LIQUIDLP_COMMUNITY_URL, LIQUIDLP_MARKETING_URL } from 'lib/config'

function LandingPageCommunity() {
  const { t } = useTranslation()

  return (
    <Layout hasLogo isSplash sx={{ p: 0 }}>
      <Grid container item gap={{ xs: 1, sm: 3 }} xs={12}>
        <Grid item xs={12} mt={{ xs: -2, sm: 3 }}>
          <Typography
            variant="h1"
            color="white"
            sx={{ fontSize: '2.5rem', lineHeight: '3.75rem' }}
          >
            {t('common.splash.community.title')}
          </Typography>
        </Grid>
        <Grid item maxWidth="sm">
          <Typography
            variant="h3"
            color="white"
            mb={4}
            sx={{ fontWeight: 400, lineHeight: '1.875rem' }}
            whiteSpace="pre-line"
          >
            {t('common.splash.community.description')}
          </Typography>
        </Grid>
        <Grid item xs={12} mb={{ xs: 1, sm: 0 }}>
          <Button mode="forward" href={LIQUIDLP_COMMUNITY_URL} isLightHover>
            {t('common.splash.community.button')}
          </Button>
        </Grid>
        <Grid item xs={12} mb={2} display="flex">
          <Link
            href={LIQUIDLP_MARKETING_URL}
            target="_blank"
            color="#fff"
            variant="body2"
          >
            {t('common.splash.community.linkTitle')}
          </Link>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default LandingPageCommunity
