import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'

import Button from 'components/mui/Button'
import LoadingButton from 'components/mui/LoadingButton'
import ArrowButton from 'components/mui/ArrowButton'
import theme from 'styles/customTheme'

interface Props {
  handleBack?: () => void
  handleForward?: () => void
  isForwardDisabled?: boolean
  isBackwardDisabled?: boolean
  isSubmitting?: boolean
  previousStepText?: string
  nextStepText?: string
  isLastStep?: boolean
  isUploading?: boolean
  uploadProgress?: number
}

function ResponsiveStepperButtons({
  handleBack,
  handleForward,
  isForwardDisabled = false,
  isBackwardDisabled = false,
  isSubmitting = false,
  previousStepText,
  nextStepText,
  isLastStep = false,
  isUploading,
  uploadProgress,
}: Props) {
  const { t } = useTranslation()

  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Grid item xs={12}>
        {isSmallDown ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {handleBack && (
              <ArrowButton
                onClick={handleBack}
                mode="back"
                variant="outlined"
                disabled={isBackwardDisabled || isSubmitting || isUploading}
              />
            )}
            {handleForward ? (
              <ArrowButton
                disabled={isForwardDisabled}
                mode="forward"
                onClick={handleForward}
              />
            ) : (
              <ArrowButton
                type="submit"
                disabled={isForwardDisabled}
                loading={isSubmitting || isUploading}
                mode="forward"
                loadingIndicator={
                  isUploading &&
                  uploadProgress !== undefined &&
                  uploadProgress > 0 &&
                  uploadProgress < 100
                    ? `${uploadProgress}%`
                    : undefined
                }
              />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {handleBack && (
              <Button
                onClick={handleBack}
                variant="outlined"
                disabled={isBackwardDisabled || isSubmitting || isUploading}
                sx={{ mr: 0.5 }}
              >
                {previousStepText ?? t('common.previous')}
              </Button>
            )}
            {handleForward ? (
              <LoadingButton
                disabled={isForwardDisabled}
                mode={isLastStep ? undefined : 'forward'}
                onClick={handleForward}
                loading={isSubmitting || isUploading}
                sx={{ ml: 0.5 }}
              >
                {nextStepText ?? t('common.next')}
              </LoadingButton>
            ) : (
              <LoadingButton
                type="submit"
                disabled={isForwardDisabled}
                loading={isSubmitting || isUploading}
                loadingIndicator={
                  isUploading &&
                  uploadProgress !== undefined &&
                  uploadProgress > 0 &&
                  uploadProgress < 100
                    ? `${uploadProgress}%`
                    : undefined
                }
                mode={isLastStep ? undefined : 'forward'}
                sx={{ ml: 0.5 }}
              >
                {nextStepText ?? t('common.next')}
              </LoadingButton>
            )}
          </Box>
        )}
      </Grid>
    </>
  )
}

export default ResponsiveStepperButtons
