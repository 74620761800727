import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper/Paper'
import styles from './paper.module.scss'

import theme from 'styles/customTheme'

export const paperShape = {
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  borderRadius: '12px',
  padding: { xs: '0.5rem', smd: '1.5rem' },
}

function PaperWrapper({ sx = {}, hasBorder = false, ...props }) {
  return (
    <Paper
      {...props}
      className={`${styles.paper} ${props.className}`}
      sx={{ ...paperShape, ...sx }}
    >
      <Grid
        container
        p={3}
        sx={{
          ...(hasBorder
            ? {
                border: `1px solid ${theme.palette.secondary.light}`,
                borderRadius: '24px',
              }
            : {}),
        }}
      >
        {props.children}
      </Grid>
    </Paper>
  )
}

export default PaperWrapper
