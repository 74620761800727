import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography/Typography'

import history from 'lib/history'
import { DEFAULT_MEMBER_ROUTE } from 'routes/MemberDashboard'
import Button from 'components/mui/Button'
import Layout from 'components/Layout/Layout'
import Paper, { paperShape } from 'components/mui/Paper/Paper'

function SelectClientType() {
  const { t } = useTranslation()

  const addQueryParam = (key: string, value: string) => {
    const searchParams = new URLSearchParams(history.location.search)
    searchParams.set(key, value)

    // Navigate to the same path with the new or updated query parameter
    history.push(
      `${DEFAULT_MEMBER_ROUTE}/ria/create-application?${searchParams.toString()}`
    )
  }

  const clientTypes = [
    {
      label: t('member.newApplication.ria.clientType.individual'),
      value: 'individual',
    },
    {
      label: t('member.newApplication.ria.clientType.entity'),
      value: 'entity',
    },
  ]

  return (
    <Layout maxWidth="sm" sx={{ px: 0 }}>
      <Typography
        variant="h2"
        component="h1"
        textTransform="capitalize"
        textAlign="center"
      >
        {t('member.newApplication.ria.clientType.heading')}
      </Typography>

      <Typography my={3} textAlign="center">
        {t('member.newApplication.ria.clientType.subHeading')}
      </Typography>

      <Paper hasBorder>
        {clientTypes?.map(({ label, value }) => (
          <Button
            key={label}
            mode="forward"
            variant="text"
            onClick={() => addQueryParam('clientType', value)}
            iconColor="primary.main"
            fullWidth
            sx={{
              ...paperShape,
              backgroundColor: 'white',
              border: '1px solid #BDBDBD',
              color: 'secondary.darkest',
              py: 1.5,
              my: 0.5,
              justifyContent: 'space-between',
              textAlign: 'left',
            }}
          >
            <Typography variant="h4">{label}</Typography>
          </Button>
        ))}
      </Paper>
    </Layout>
  )
}

export default SelectClientType
