import Grid, { GridProps } from '@mui/material/Grid'

import theme from 'styles/customTheme'

function BorderGridLayout({
  children,
  ...props
}: GridProps & { children: React.ReactNode }) {
  return (
    <Grid
      {...props}
      sx={{
        border: `1px solid ${theme.palette.secondary.light}`,
        borderRadius: '24px',
        p: { xs: 2, sm: 4 },
      }}
      {...props}
    >
      {children}
    </Grid>
  )
}

export default BorderGridLayout
