import { Application, CompanyFile } from 'common/types'
import dayjs from 'lib/dayjs'
import {
  calcChartLoopIteration,
  offerFixedTimelineDays,
  UPLOAD_DOCUMENTS_LIST_BY_REQUIRED,
} from 'lib/formConfig'
import { SetLoanAmountProps } from 'views/member/setLoan/SetLoanAmount'

export const getOfferExpiryDate = ({ expiryDate }: { expiryDate: string }) => {
  const fixedTimelineExpiryDate = dayjs()
    .add(dayjs.duration({ days: offerFixedTimelineDays }))
    .endOf('day')

  const offerExpiryDate = fixedTimelineExpiryDate.isBefore(dayjs(expiryDate))
    ? fixedTimelineExpiryDate
    : expiryDate

  return dayjs(offerExpiryDate).toISOString()
}

export const getUploadedRequiredFiles = (files: CompanyFile[]) =>
  files.filter(({ label }) =>
    UPLOAD_DOCUMENTS_LIST_BY_REQUIRED.required.find(
      ({ name }: { name: string }) => name === label
    )
  )

export const getHasUploadedRequiredFiles = (files: CompanyFile[]) =>
  getUploadedRequiredFiles(files).length ===
  UPLOAD_DOCUMENTS_LIST_BY_REQUIRED.required.length

export const getUploadedFilesByLabel = (files: CompanyFile[]) =>
  files.reduce(
    (acc, cur) => {
      acc[cur.label] = cur

      return acc
    },
    {} as { [key: string]: CompanyFile }
  )

export const getIsCapTableProcessing = (companyFiles: CompanyFile[]) =>
  companyFiles?.filter(
    (file: CompanyFile) =>
      file.label === 'capitalizationTable' ||
      file.label === 'shareholderInformation'
  ).length === 2

export const getChartScalingArray = (value: number) => {
  // First element of the array should always be 0
  const data = [0, 0, value, 0, 0, 0, 0]

  if (value === 0) return data

  // No rounding for values lower than 2
  const halfValue = value < 2 ? value / 2 : Math.floor(value / 2)

  // Decrease scale of value by its 50%
  for (let i = 1; i > 0; i--) {
    data[i] = data[i + 1] - halfValue
  }

  // Increase scale of value by its 50%
  for (let i = 3; i < calcChartLoopIteration; i++) {
    data[i] = data[i - 1] + halfValue
  }

  return data
}

export const calculateLoanDetails = ({
  loanDetails,
  application,
  requestedLoanAmount = 0,
  sofrRate,
}: {
  loanDetails: SetLoanAmountProps['loanDetails']
  application?: Application
  requestedLoanAmount: number
  sofrRate: number
}) => {
  const calculatedLoanDetails = { ...loanDetails }
  const totalLoan =
    requestedLoanAmount +
    (requestedLoanAmount * (application?.financingInfo?.originationFee ?? 0)) /
      100

  const annualInterest =
    requestedLoanAmount *
    (((application?.financingInfo?.interestRate ?? 0) + sofrRate) / 100)

  const quarterlyInterest = annualInterest / 4

  const monthlyInterest = annualInterest / 12

  calculatedLoanDetails.totalLoan = totalLoan
  calculatedLoanDetails.annualInterest = annualInterest
  calculatedLoanDetails.quarterlyInterest = quarterlyInterest
  calculatedLoanDetails.monthlyInterest = monthlyInterest

  return calculatedLoanDetails
}

export const calculatePreauthorizedAmount = ({
  assetsPledged,
  LTV,
}: {
  assetsPledged: number
  LTV: number
}) => (assetsPledged * LTV) / 100

export const getIsLoanLesserThanLtv = (application: Application) => {
  const commitment = application.financingInfo?.commitment ?? 0
  const loanAmount = application.actualAmount ?? application.amount ?? 0

  const ltvThreshold = loanAmount / commitment

  return ltvThreshold < 0.25
}

export const getApplicationOfferStage = (
  currentStage: Application['currentStage']
) => {
  const offerAcceptedStages = ['TERMS_REQUESTED', 'FUNDED']
  const offerCreatedStages = [
    'INITIAL',
    'UNDER_REVIEW',
    'MORE_INFORMATION_REQUIRED',
  ]
  const preApprovedStages = ['APPROVED']
  const offerDeniedStages = ['DENIED']

  return {
    isApplicationOfferAccepted: offerAcceptedStages.includes(currentStage),
    isApplicationOfferCreated: offerCreatedStages.includes(currentStage),
    isApplicationPreApproved: preApprovedStages.includes(currentStage),
    isApplicationOfferDenied: offerDeniedStages.includes(currentStage),
  }
}
