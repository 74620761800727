import { useTranslation } from 'react-i18next'

import CheckIcon from '@mui/icons-material/Check'
import Box from '@mui/material/Box/Box'
import Grid from '@mui/material/Grid/Grid'
import Step from '@mui/material/Step'
import MuiStepper, { StepperProps } from '@mui/material/Stepper'
import StepContent from '@mui/material/StepContent/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography/Typography'
import Button from './Button'

interface SubTask {
  label: string
  isCompleted: boolean
  status?: string
  actionPath?: string
  completedActionPath?: string
}

const TaskCard = ({ subTask }: { subTask: SubTask }) => {
  const { t } = useTranslation()

  const { label, isCompleted, status, actionPath, completedActionPath } =
    subTask

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: 'secondary.lightest',
        borderRadius: '12px',
        p: 1.5,
      }}
    >
      <Grid
        item
        display="flex"
        xs={12}
        smd="auto"
        alignItems="center"
        sx={{ my: { xs: 1, smd: 0 } }}
      >
        <Box
          sx={{
            backgroundColor: isCompleted ? 'primary.main' : 'secondary.main',
            borderRadius: '50%',
            mx: 2,
            minWidth: '25px',
            maxWidth: '25px',
            minHeight: '25px',
            maxHeight: '25px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isCompleted && (
            <CheckIcon
              fontSize="small"
              sx={{ '&.MuiSvgIcon-root': { color: 'white' } }}
            />
          )}
        </Box>
        <Typography py={1}>{label}</Typography>
      </Grid>
      <Grid item xs={12} smd="auto">
        {status ? (
          <Typography py={1} px={2}>
            {status}
          </Typography>
        ) : (
          actionPath && (
            <Button
              variant="text"
              mode="forward"
              to={
                isCompleted && completedActionPath
                  ? completedActionPath
                  : actionPath
              }
            >
              {isCompleted
                ? t('dashboard.overview.setUp.viewDetails')
                : t('dashboard.overview.setUp.completeTask')}
            </Button>
          )
        )}
      </Grid>
    </Grid>
  )
}

type Props = StepperProps & {
  steps?: string[]
  tasks?: {
    label: string
    display?: boolean
    subTasks: SubTask[]
  }[]
}

export default function Stepper({
  steps,
  tasks,
  orientation = 'vertical',
  ...props
}: Props) {
  return (
    <MuiStepper
      orientation={orientation}
      activeStep={tasks && -1}
      nonLinear={tasks ? true : undefined}
      {...props}
      sx={{
        width: 1,
        '& .MuiStepIcon-text': {
          display: tasks ? 'none' : 'inline',
        },
        '& 	.MuiStepLabel-label': {
          fontWeight: tasks ? 500 : 400,
          color: tasks ? 'secondary.darkest' : 'primary.main',
        },
        '& .MuiStepLabel-iconContainer.Mui-completed': {
          position: 'relative',

          // Create a white background on checked icon
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 2,
            bottom: 2,
            left: 2,
            right: 10,
            backgroundColor: 'white',
            borderRadius: '50%',
          },
        },
        '& 	.MuiStepIcon-root.Mui-completed': {
          color: 'primary.main',
          borderRadius: '50%',
          zIndex: 1,
        },
        '& 	.MuiStepIcon-root': {
          color: 'secondary.main',
        },
      }}
    >
      {tasks
        ? tasks.map(
            (task) =>
              task.display !== false && (
                <Step
                  key={task.label}
                  expanded
                  completed={task.subTasks.every(
                    (subTask) => subTask.isCompleted === true
                  )}
                >
                  <StepLabel>{task.label}</StepLabel>
                  <StepContent sx={{ borderLeft: '1px solid #bdbdbd', pt: 2 }}>
                    <Grid container rowGap={2}>
                      {task.subTasks.map((subTask: SubTask) => (
                        <TaskCard key={subTask.label} subTask={subTask} />
                      ))}
                    </Grid>
                  </StepContent>
                </Step>
              )
          )
        : steps?.map((step) => (
            <Step key={step}>
              <StepLabel
                sx={{
                  '& 	.MuiStepLabel-label': {
                    color: 'primary.main',
                    fontWeight: 400,
                  },
                  '& 	.MuiStepLabel-label.Mui-active': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                  '& 	.MuiStepLabel-label.Mui-completed': {
                    color: 'primary.main',
                  },
                  '& 	.MuiStepIcon-root.Mui-active': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                }}
              >
                {step}
              </StepLabel>
            </Step>
          ))}
    </MuiStepper>
  )
}
