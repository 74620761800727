import { allCountries } from 'country-region-data'

export default allCountries

export const countryList = allCountries?.map((country) => country[0]) || []

export const defaultPriorityCountryList = ['United States', 'Canada']

export const getPriorityCountryList = (
  priorityCountries = defaultPriorityCountryList
) => {
  const countriesListedFirst: string[] = []
  let filteredCountries = countryList

  filteredCountries = filteredCountries.filter((country) => {
    // Find the priority countries in the list of countries.
    const isPrioritized = priorityCountries.includes(country)

    if (isPrioritized) {
      countriesListedFirst.push(country)
    }

    return !isPrioritized
  })

  return countriesListedFirst.length
    ? [...countriesListedFirst.reverse(), ...filteredCountries]
    : filteredCountries
}

export const getRegionByCountry = (country?: string) => {
  if (!country) {
    return []
  }
  const regions = allCountries.find((option) => option[0] === country)

  return regions ? regions[2].map((region) => region[0]) : []
}

// List of countries with default priority country list
export const priorityCountryList = getPriorityCountryList()
