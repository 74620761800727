import i18n from 'locale/i18n'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const config = {
  default: {
    active: {
      color: 'success.main',
      backgroundColor: 'success.light',
      textColor: 'success.dark',
    },
    saved: {
      color: 'warning.main',
      backgroundColor: 'warning.light',
      textColor: 'warning.dark',
    },
    completed: {
      color: 'secondary.dark',
      backgroundColor: 'secondary.light',
      textColor: 'secondary.darkest',
    },
    cancelled: {
      color: 'error.main',
      backgroundColor: 'error.light',
      textColor: 'error.dark',
    },
    deleted: {
      color: 'secondary.main',
      backgroundColor: 'secondary.light',
      textColor: 'secondary.dark',
    },
    info: {
      color: 'info.main',
      backgroundColor: 'info.light',
      textColor: 'info.main',
    },
  },
  application: {
    under_review: {
      color: 'warning.main',
      backgroundColor: 'warning.light',
      textColor: 'warning.dark',
      label: i18n.t('liquidlpAdmin.applications.status.stages.underReview'),
    },
    initial: {
      color: 'info.main',
      backgroundColor: 'info.light',
      textColor: 'info.main',
      label: i18n.t('liquidlpAdmin.applications.status.stages.initial'),
    },
    more_information_required: {
      color: 'info.main',
      backgroundColor: 'info.light',
      textColor: 'info.main',
      label: i18n.t('liquidlpAdmin.applications.status.stages.moreInfo'),
    },
    approved: {
      color: 'success.main',
      backgroundColor: 'success.light',
      textColor: 'success.dark',
      label: i18n.t('liquidlpAdmin.applications.status.stages.approved'),
    },
    denied: {
      color: 'error.main',
      backgroundColor: 'error.light',
      textColor: 'error.dark',
      label: i18n.t('liquidlpAdmin.applications.status.stages.denied'),
    },
    terms_requested: {
      color: 'info.main',
      backgroundColor: 'info.light',
      textColor: 'info.main',
      label: i18n.t('liquidlpAdmin.applications.status.stages.terms'),
    },
    funded: {
      color: 'success.main',
      backgroundColor: 'success.light',
      textColor: 'success.dark',
      label: i18n.t('liquidlpAdmin.applications.status.stages.funded'),
    },
    expired: {
      color: 'secondary.main',
      backgroundColor: 'secondary.light',
      textColor: 'secondary.dark',
      label: i18n.t('liquidlpAdmin.applications.status.stages.expired'),
    },
  },
  shareholder: {
    not_subscribed: {
      label: i18n.t('common.notSubscribed'),
      color: 'warning.main',
      backgroundColor: 'warning.light',
      textColor: 'warning.dark',
    },
    subscribed: {
      label: i18n.t('common.subscribed'),
      color: 'success.main',
      backgroundColor: 'success.light',
      textColor: 'success.dark',
    },
  },
  loan: {
    active: {
      color: 'success.main',
      backgroundColor: 'success.light',
      textColor: 'success.dark',
    },
    expired: {
      label: i18n.t('loan.termEnded'),
      color: 'secondary.dark',
      backgroundColor: 'secondary.light',
      textColor: 'secondary.darkest',
    },
  },
  member: {
    active: {
      color: 'success.main',
      backgroundColor: 'success.light',
      textColor: 'success.dark',
    },
    inactive: {
      color: 'error.main',
      backgroundColor: 'error.light',
      textColor: 'error.dark',
    },
  },
} as any

interface Props {
  category?: keyof typeof config
  label?: string
  status: string
  hasBorder?: boolean
  isCapitalizeLabel?: boolean
}

function StatusIndicator({
  category = 'default',
  label,
  status,
  hasBorder = false,
  isCapitalizeLabel = true,
}: Props) {
  const statusConfig = config[category][status.toLowerCase()] ?? ''
  const borderColor = hasBorder && {
    border: 1,
    borderColor: statusConfig.color,
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: statusConfig?.backgroundColor,
        borderRadius: '5px',
        display: 'flex',
        px: 1,
        py: 0.5,
        ...borderColor,
      }}
    >
      <Box
        sx={{
          backgroundColor: statusConfig?.color,
          borderRadius: '50%',
          height: '8px',
          width: '8px',
          mr: 1,
        }}
      />
      <Typography
        variant="subtitle1"
        color={statusConfig?.textColor}
        textTransform={isCapitalizeLabel ? 'capitalize' : undefined}
      >
        {label ?? statusConfig?.label ?? status.toLowerCase()}
      </Typography>
    </Box>
  )
}

export default StatusIndicator
