import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Application } from 'common/api-types'
import { getApplicationOfferStage } from 'lib/data'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'
import { loanRepaymentSchedules } from 'lib/formConfig'
import { getLabelByValue } from 'lib/helpers'

import BorderGridLayout from 'components/BorderGridLayout'
import Button from 'components/mui/Button'
import FinancingInfoForm from 'views/liquidlpAdmin/applications/FinancingInfoForm'

function FinancingInformation({ application }: { application: Application }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const queryParams = searchParams.get('status')
  const isUpdating =
    queryParams === 'updating' || application.financingInfo === null

  const { isApplicationOfferCreated } = useMemo(
    () => getApplicationOfferStage(application?.currentStage),
    [application?.currentStage]
  )

  const { financingInfo } = application

  const inputs = [
    {
      label: t(
        'liquidlpAdmin.applications.financingInfo.form.labels.assetsPledged'
      ),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: financingInfo?.fundSize,
      }),
    },
    {
      label: t('liquidlpAdmin.applications.financingInfo.form.labels.ltv'),
      value: t('common.percentage', {
        value: financingInfo?.LTV,
      }),
    },
    {
      label: t(
        'liquidlpAdmin.applications.financingInfo.form.labels.preauthorizedAmount'
      ),
      value: t('common.intlCurrencySimplifiedFormat', {
        value: financingInfo?.commitment,
      }),
    },
    {
      label: t(
        'liquidlpAdmin.applications.financingInfo.form.labels.originationFee'
      ),
      value: t('common.percentage', {
        value: financingInfo?.originationFee,
      }),
    },
    {
      label: t(
        'liquidlpAdmin.applications.financingInfo.form.labels.interestRate'
      ),
      value: `${t('common.percentage', {
        value: financingInfo?.interestRate,
      })}`,
    },
    {
      label: t(
        'liquidlpAdmin.applications.financingInfo.form.labels.financingTerm'
      ),
      value: t('common.monthWithCount', {
        count: financingInfo?.financingTerm.length,
      }),
    },
    {
      label: t(
        'liquidlpAdmin.applications.financingInfo.form.labels.loanRepaymentSchedule'
      ),
      value: financingInfo?.loanRepaymentFrequency
        ? getLabelByValue(
            financingInfo?.loanRepaymentFrequency,
            loanRepaymentSchedules
          )
        : '-',
    },
    {
      label: t(
        'liquidlpAdmin.applications.financingInfo.form.labels.offerEndDate'
      ),
      value: dayjs(financingInfo?.offerEndDate).format(
        monthDayFullYearSimplifiedFormat
      ),
    },
  ]

  if (isUpdating) {
    return <FinancingInfoForm application={application} />
  }

  return (
    <BorderGridLayout container maxWidth="370px">
      <Grid
        container
        columnSpacing={4}
        rowSpacing={{ xs: 2, sm: 0 }}
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h3">
            {t('liquidlpAdmin.applications.financingInfo.heading')}
          </Typography>
        </Grid>

        <Grid item container rowSpacing={2} mt={2} mb={4}>
          {inputs.map(({ label, value }) => (
            <Grid key={label} item xs={12}>
              <Typography variant="subtitle2">{label}</Typography>
              <Typography variant="subtitle1">{value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {isApplicationOfferCreated && (
        <Grid item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate('?status=updating')}
          >
            {t(
              'liquidlpAdmin.applications.financingInfo.updateFinancingInfoButtonLabel'
            )}
          </Button>
        </Grid>
      )}
    </BorderGridLayout>
  )
}

export default FinancingInformation
