import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from 'locale/i18n'

import {
  useGetApplicationsQuery,
  useGetApplicationCountQuery,
} from 'lib/apollo/hooks'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import ApplicationsTable from 'components/tables/ApplicationsTable'
import Layout from 'components/Layout/Layout'
import Paper from 'components/mui/Paper/Paper'
import QueryBoundary from 'app/QueryBoundary'
import StatusIndicator from 'components/StatusIndicator'
import BorderGridLayout from 'components/BorderGridLayout'
import { ApplicationStage } from 'common/types'

function Applications() {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentStage = searchParams
    .get('stage')
    ?.toUpperCase() as ApplicationStage
  const [currentStages, setCurrentStages] = useState<ApplicationStage[]>([
    currentStage ?? 'UNDER_REVIEW',
  ])

  const applicationsQuery = useGetApplicationsQuery(currentStages)
  const { data } = applicationsQuery
  const applications = data?.getApplications

  const applicationCountQuery = useGetApplicationCountQuery()
  const { data: countData } = applicationCountQuery
  const applicationCount = countData?.getApplicationCount

  const getCountByStage = (stageToFind: ApplicationStage): number => {
    const matchingStage = applicationCount?.find(
      (v: { stage: string }) => v.stage === stageToFind
    )

    return matchingStage ? matchingStage.count : 0
  }

  useEffect(() => {
    searchParams.set('stage', currentStages[0].toLowerCase())
    setSearchParams(searchParams)
  }, [currentStages, searchParams, setSearchParams])

  const stages: { id: ApplicationStage; label: string; value: number }[] = [
    {
      id: 'INITIAL',
      label: i18n.t('liquidlpAdmin.applications.status.stages.initial'),
      value: getCountByStage('INITIAL') || 0,
    },
    {
      id: 'UNDER_REVIEW',
      label: i18n.t('liquidlpAdmin.applications.status.stages.underReview'),
      value: getCountByStage('UNDER_REVIEW') || 0,
    },
    {
      id: 'MORE_INFORMATION_REQUIRED',
      label: i18n.t('liquidlpAdmin.applications.status.stages.moreInfo'),
      value: getCountByStage('MORE_INFORMATION_REQUIRED') || 0,
    },
    {
      id: 'APPROVED',
      label: i18n.t('liquidlpAdmin.applications.status.stages.approved'),
      value: getCountByStage('APPROVED') || 0,
    },
    {
      id: 'DENIED',
      label: i18n.t('liquidlpAdmin.applications.status.stages.denied'),
      value: getCountByStage('DENIED') || 0,
    },
    {
      id: 'TERMS_REQUESTED',
      label: i18n.t('liquidlpAdmin.applications.status.stages.terms'),
      value: getCountByStage('TERMS_REQUESTED') || 0,
    },
    {
      id: 'FUNDED',
      label: i18n.t('liquidlpAdmin.applications.status.stages.funded'),
      value: getCountByStage('FUNDED') || 0,
    },
    {
      id: 'EXPIRED',
      label: i18n.t('liquidlpAdmin.applications.status.stages.expired'),
      value: getCountByStage('EXPIRED') || 0,
    },
  ]

  return (
    <QueryBoundary queries={[applicationsQuery, applicationCountQuery]}>
      <Layout maxWidth="lg">
        <Paper hasBorder>
          <Grid container justifyItems="space-around" mb={4}>
            <Typography variant="h2" mb={{ xs: 0, sm: 4 }}>
              {t('liquidlpAdmin.applications.status.stages.heading')}
            </Typography>
            <Grid
              item
              container
              justifyItems="space-around"
              columnGap={2}
              xs={12}
              mb={4}
              sx={{
                overflowX: 'auto',
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
              }}
            >
              {stages.map((stage) => (
                <Grid
                  key={stage.id}
                  item
                  container
                  alignItems="space-between"
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid black',
                    p: 1,
                    width: 200,
                    height: 90,
                    cursor: 'pointer',
                    ...(currentStages[0] !== stage.id && {
                      ':hover': {
                        backgroundColor: 'rgba(10, 6, 6, 0.04)',
                        color: '#000',
                      },
                    }),
                    backgroundColor:
                      currentStages[0] === stage.id ? '#000' : '#fff',
                    color: currentStages[0] === stage.id ? '#fff' : '#000',
                  }}
                  onClick={() => setCurrentStages([stage.id])}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2">{stage.label}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    alignContent="flex-end"
                    justifyContent="space-between"
                  >
                    <Typography>{stage.value}</Typography>
                    {(stage.id === 'UNDER_REVIEW' ||
                      stage.id === 'TERMS_REQUESTED') &&
                      stage.value > 0 && <WarningAmberIcon color="warning" />}
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid
              item
              container
              xs={12}
              columnGap={2}
              justifyContent="space-between"
              flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
            >
              <BorderGridLayout
                item
                container
                xs={12}
                sm={6}
                mb={{ xs: 2, sm: 0 }}
                alignItems="space-between"
              >
                <Grid item container xs={12} alignItems="center" pb={1}>
                  <Typography variant="h6" color="secondary.lighter">
                    {t('liquidlpAdmin.applications.heading')}
                  </Typography>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={8}
                    ml={2}
                    alignContent="center"
                    justifyContent="start"
                  >
                    <StatusIndicator
                      status={currentStages[0]}
                      category="application"
                      isCapitalizeLabel={false}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h1">{applications?.totalCount}</Typography>
              </BorderGridLayout>
              <BorderGridLayout item xs={12} sm={6} alignItems="space-between">
                <Typography variant="h6" color="secondary.lighter" pb={1}>
                  {t(
                    `liquidlpAdmin.applications.status.stages.total${
                      currentStages[0] === 'FUNDED' ? 'Financed' : 'Loan'
                    }`
                  )}
                </Typography>
                <Typography variant="h1">
                  {t('common.intlCurrencySimplifiedFormat', {
                    value: applications?.totalAmount,
                  })}
                </Typography>
              </BorderGridLayout>
            </Grid>
          </Grid>
          <ApplicationsTable
            applications={applications?.applications ?? []}
            currentStage={currentStages[0]}
          />
        </Paper>
      </Layout>
    </QueryBoundary>
  )
}

export default Applications
