import { useMemo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { ProtectedRoute } from 'app/ProtectedRoute'
import { useAuth } from 'app/Auth'
import { useUser } from 'app/User'
import { FEATURE_FLAGS } from 'lib/config'

import Account from 'routes/Account'
import AdminDashboard, {
  DEFAULT_ADMIN_MEMBERS_ROUTE,
} from 'routes/AdminDashboard'
import MemberDashboard, {
  DEFAULT_MEMBER_DASHBOARD_ROUTE,
} from 'routes/MemberDashboard'
import User from 'routes/User'
import ActivateAccount from 'views/account/ActivateAccount'
import BrandLayout from 'components/BrandLayout/BrandLayout'
import LandingPage from 'views/LandingPage'
import LandingPageCommunity from 'views/LandingPageCommunity'
import AccountSettings from 'views/account/AccountSettings'
import MarkdownContent from 'views/MarkdownContent/MarkdownContent'
import PageNotFound from 'views/PageNotFound'
import AccountCreationConfirmation from 'views/AccountCreationConfirmation/AccountCreationConfirmation'
import UploadFilesTest from 'views/UploadFilesTest'
import ResponsiveHeaderBar from 'components/headerBar/ResponsiveHeaderBar'
import RoleSelect from 'views/RoleSelect'
import TestAlerts from 'views/TestAlerts'
import VerificationCode from 'views/user/VerificationCode'

export const DEFAULT_ROOT_ROUTE = '/'

function Routing() {
  const { isLoggedIn } = useAuth()
  const {
    state: { activeRole },
    roles: { userRoles },
  } = useUser()

  const homeRoute = useMemo(() => {
    if (activeRole === null && userRoles && userRoles.length > 1) {
      return '/role'
      //TODO:Temporary fix for the user role, must be from isOpenstockAdmin (useUser hook)
    } else if (userRoles?.[0] === 'ADMIN') {
      return DEFAULT_ADMIN_MEMBERS_ROUTE
    }

    return DEFAULT_MEMBER_DASHBOARD_ROUTE
  }, [activeRole, userRoles])

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to={isLoggedIn ? '/home' : '/login'} replace />}
      />
      <Route path="home" element={<Navigate to={homeRoute} replace />} />
      <Route
        path="confirmation"
        element={
          <ProtectedRoute permittedRoles={['ADMIN']}>
            <ResponsiveHeaderBar>
              <AccountCreationConfirmation role="ADMIN" />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="account-confirmation"
        element={
          <ProtectedRoute permittedRoles={['USER']}>
            <ResponsiveHeaderBar>
              <AccountCreationConfirmation role="USER" />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="/login/*"
        element={
          <ProtectedRoute invert>
            <User />
          </ProtectedRoute>
        }
      />
      <Route
        path="account/*"
        element={
          <ProtectedRoute>
            <ResponsiveHeaderBar>
              <Account />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      {FEATURE_FLAGS.TEST_UPLOADS && (
        <Route
          path="upload-files-test"
          element={
            <ProtectedRoute>
              <UploadFilesTest />
            </ProtectedRoute>
          }
        />
      )}
      {FEATURE_FLAGS.TEST_ALERTS && (
        <Route path="test-alerts" element={<TestAlerts />} />
      )}
      <Route
        path="role"
        element={
          <ProtectedRoute>
            <ResponsiveHeaderBar>
              {userRoles && userRoles.length > 1 ? (
                <RoleSelect />
              ) : (
                <Navigate to={DEFAULT_ROOT_ROUTE} replace />
              )}
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="admin/*"
        element={
          <ProtectedRoute permittedRoles={['ADMIN']}>
            <ResponsiveHeaderBar>
              <AdminDashboard />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="member/*"
        element={
          <ProtectedRoute permittedRoles={['USER']}>
            <ResponsiveHeaderBar>
              <MemberDashboard />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="upload-documents/ria/:userId"
        element={<Navigate to="/" />}
      />
      <Route path="upload-documents/lp/:userId" element={<Navigate to="/" />} />
      <Route path="upload-documents/gp/:userId" element={<Navigate to="/" />} />
      <Route
        path="account-settings"
        element={
          <ProtectedRoute>
            <ResponsiveHeaderBar>
              <AccountSettings />
            </ResponsiveHeaderBar>
          </ProtectedRoute>
        }
      />
      <Route
        path="activate"
        element={
          <ProtectedRoute invert>
            <ActivateAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path="verification"
        element={
          <ProtectedRoute invert>
            <BrandLayout>
              <VerificationCode isPaperComponent={false} />
            </BrandLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="get-started"
        element={
          <ProtectedRoute invert>
            <LandingPage />
          </ProtectedRoute>
        }
      />
      <Route path="community" element={<LandingPageCommunity />} />
      <Route
        path="confidentiality"
        element={<MarkdownContent type="confidentiality" />}
      />
      <Route path="privacy" element={<MarkdownContent type="privacy" />} />
      <Route path="terms" element={<MarkdownContent type="terms" />} />
      <Route path="glba-policy" element={<MarkdownContent type="glba" />} />
      <Route
        path="*"
        element={
          <ResponsiveHeaderBar>
            <PageNotFound />
          </ResponsiveHeaderBar>
        }
      />
    </Routes>
  )
}

export default Routing
