import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import MuiTabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function a11yProps(index: string) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

function useMatchPath(allPaths: string[]) {
  const { pathname } = useLocation()
  const matchedPath = allPaths.find((path) => pathname.endsWith(`/${path}`))

  return matchedPath
}

interface TabListItem {
  label: string
  path: string
  element: JSX.Element
  disabled?: boolean
}

interface TabBarProps {
  tabList: TabListItem[]
  fallbackPath: string
  hidden?: boolean
}

function TabBar({ tabList, fallbackPath, hidden = false }: TabBarProps) {
  const allPaths = tabList.map((tabItem) => tabItem.path)
  const currentTab = useMatchPath(allPaths)

  if (!currentTab) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to={fallbackPath} />} />
        <Route path="*" element={<Navigate to={'/pageNotFound'} />} />
      </Routes>
    )
  }

  return (
    <Box mt={4} width={1}>
      <MuiTabs
        value={currentTab}
        aria-label="tab bar"
        variant="scrollable"
        scrollButtons={false}
        sx={{
          pb: 4,
          '&.MuiTabs-root': {
            minHeight: 'fit-content',
          },
          '& .MuiTab-root': {
            minHeight: 'fit-content',
            minWidth: 'fit-content',
            pb: 0.5,
            color: 'primary.main',
            '&.Mui-selected': {
              color: 'secondary.darkest',
            },
          },
          '& .MuiTabs-indicator': {
            backgroundColor: 'secondary.darkest',
          },
          display: hidden ? 'none' : 'block',
        }}
      >
        {tabList.map((tabItem) => (
          <Tab
            label={tabItem.label}
            key={`tab-${tabItem.path}`}
            component={Link}
            to={tabItem.path}
            value={tabItem.path}
            disabled={tabItem.disabled}
            {...a11yProps(tabItem.path)}
            sx={{
              p: 0,
              mr: 3,
            }}
          />
        ))}
      </MuiTabs>
      <Box>
        <Routes>
          {tabList.map((tabItem) => (
            <Route
              key={tabItem.path}
              path={tabItem.path}
              element={
                <TabPanel
                  key={`tabpanel-${tabItem.path}`}
                  value={tabItem.path}
                  index={tabItem.path}
                >
                  {tabItem.element}
                </TabPanel>
              }
            />
          ))}
        </Routes>
      </Box>
    </Box>
  )
}

export default TabBar
