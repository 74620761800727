import { useField } from 'formik'
import dayjs, { monthDayFullYearSimplifiedFormat } from 'lib/dayjs'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import Typography from '@mui/material/Typography'

function DateInput({ name, label, calculatedValue, ...props }: any) {
  const [field, meta, helpers] = useField(name)

  const value = calculatedValue ?? field.value

  const config = {
    name,
    ...props,
    value: value
      ? dayjs(value as dayjs.ConfigType, monthDayFullYearSimplifiedFormat)
      : null,
  }

  if (meta.touched && meta.error) {
    config.error = true
    config.helperText = meta.error
  }

  const textFieldProps = {
    fullWidth: true,
    error: false,
    helperText: props.helperText ?? '',
    InputLabelProps: { shrink: true },
    label: '',
  }

  if (meta.touched && meta.error) {
    textFieldProps.error = true
    textFieldProps.helperText = meta.error
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label && (
        <Typography variant="h6" mb={1}>
          {label}
          {props.required && <span style={{ color: '#CF0C0C' }}> *</span>}
        </Typography>
      )}
      <DesktopDatePicker
        {...config}
        format={monthDayFullYearSimplifiedFormat}
        onChange={(newValue: any) => {
          helpers.setTouched(true)
          helpers.setValue(
            dayjs(newValue).format(monthDayFullYearSimplifiedFormat)
          )
        }}
        slotProps={{ textField: textFieldProps }}
        minDate={dayjs()}
        disableHighlightToday
        disablePast
      />
    </LocalizationProvider>
  )
}

export default DateInput
