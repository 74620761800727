import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid/Grid'
import Layout from 'components/Layout/Layout'
import Typography from '@mui/material/Typography/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useUser } from 'app/User'
import { ClientTypes } from 'common/types'
import { useSteps } from 'hooks/useSteps'
import { Form, Formik, FormikHelpers } from 'lib/formik'
import {
  useCreateApplication,
  useGetApplicationQuery,
  useSubmitApplication,
  useUpdateApplication,
} from 'lib/apollo/hooks'
import {
  corporateDetailsSchema,
  fundDetailsSchema,
  requestLoanAmountSchema,
} from 'lib/validation'
import { DEFAULT_MEMBER_ROUTE } from 'routes/MemberDashboard'
import theme from 'styles/customTheme'

import { ApplicationInputForm } from 'views/member/RiaNewApplication'
import AlertDialog from 'components/AlertDialog'
import BackLink from 'components/BackLink'
import FundInfoForm from 'views/member/newApplication/FundInfoForm'
import QueryBoundary from 'app/QueryBoundary'
import RequestedLoanAmount from 'views/member/newApplication/RequestedLoanAmount'
import ContactInfoForm from 'views/member/newApplication/ContactInfoForm'
import SideBarStepper from 'components/SideBarStepper/SideBarStepper'
import StepperSuccess from 'components/StepperSuccess'
import UploadDisclosureDocuments from 'views/UploadDisclosureDocuments'

function GpNewApplication() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const urlParamsApplicationId = queryParams.get('applicationId') ?? undefined

  const { activeStep, handleBack, handleNext } = useSteps()
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const [applicationId, setApplicationId] = useState(urlParamsApplicationId)
  const [createApplication, createApplicationMutation] = useCreateApplication()
  const [updateApplication, updateApplicationMutation] =
    useUpdateApplication(applicationId)
  const [submitApplication, submitApplicationMutation] = useSubmitApplication()

  const applicationQuery = useGetApplicationQuery(urlParamsApplicationId)
  const { data } = applicationQuery
  const application = useMemo(() => data?.getApplication, [data])

  const clientType = 'individual' as ClientTypes
  const { data: user } = useUser()
  const userType = user?.userType.toLowerCase()

  const isSubmittingApplication =
    createApplicationMutation.loading ||
    updateApplicationMutation.loading ||
    submitApplicationMutation.loading

  const validationSchema = [
    corporateDetailsSchema,
    fundDetailsSchema,
    requestLoanAmountSchema,
  ]

  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleAction = () =>
    navigate(`${DEFAULT_MEMBER_ROUTE}/dashboard`, {
      state: {
        unblockPrompt: true,
      },
    })

  const handleSaveNext = async (
    values: ApplicationInputForm,
    formik?: FormikHelpers<ApplicationInputForm>
  ) => {
    const data = {
      application: {
        clientType,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
        address: {
          streetAddress: values.streetAddress,
          streetAddress2: values.streetAddress2 || null,
          city: values.city,
          country: values.country,
          state: values.state,
          zipCode: values.zipCode,
        },
        fund: {
          name: values.fundName ?? null,
          website: values.fundWebsite ?? null,
          fundSizeRaised: Number(values.fundSizeRaised) ?? null,
          currentAUM: Number(values.currentAUM) ?? null,
          LPsNumber: values.LPsNumber ?? null,
        },
        documents: values.documents || null,
        amount: values.amount ?? null,
        useOfProceeds: values.useOfProceeds || null,
        duration: values.duration ?? null,
        comments: values.comments ?? null,
      },
    }
    if (
      !createApplicationMutation.data &&
      urlParamsApplicationId === undefined
    ) {
      const { errors, data: newData } = await createApplication({
        variables: data,
        onCompleted: (data) => {
          const applicationId = data.createApplication._id
          setApplicationId(applicationId)
          handleNext()
        },
      })

      if (errors) return

      setApplicationId(newData?.createApplication._id)
    }

    if (!updateApplicationMutation.data && applicationId !== undefined) {
      const { errors } = await updateApplication({
        variables: {
          id: applicationId,
          application: data.application,
        },
        onCompleted: async () => {
          if (!submitApplicationMutation.data && activeStep === 3) {
            const { errors } = await submitApplication({
              variables: { id: applicationId },
              onCompleted: handleNext,
            })

            if (errors) return

            formik?.resetForm()
          }

          handleNext()
        },
      })

      if (errors) return
    }

    formik?.setTouched({}, false)
    updateApplicationMutation.reset()
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
  }

  const handleOnClose = () => setShowAlertDialog(false)

  useEffect(() => {
    if (
      createApplicationMutation.error ||
      updateApplicationMutation.error ||
      submitApplicationMutation.error
    ) {
      setShowAlertDialog(true)
    }
  }, [
    createApplicationMutation.error,
    submitApplicationMutation.error,
    updateApplicationMutation.error,
  ])

  const pages: { title: string; description: string }[] = t(
    'member.newApplication.gp.pages',
    {
      returnObjects: true,
    }
  )

  const steps: string[] = pages.map(({ title }) => title)

  const documentIds = application?.documents?.map((file) => file._id)

  const initialFormData = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phone: user?.phoneNumber ?? '',
    email: user?.emailAddress ?? '',
    streetAddress: application?.address.streetAddress ?? '',
    streetAddress2: application?.address.streetAddress2 ?? '',
    city: application?.address.city ?? '',
    country: application?.address.country ?? '',
    state: application?.address.state ?? '',
    zipCode: application?.address.zipCode ?? '',
    amount: application?.amount ?? undefined,
    useOfProceeds: application?.useOfProceeds ?? '',
    duration: application?.duration ?? undefined,
    comments: application?.comments ?? '',
    documents: documentIds ?? [],
    fundName: application?.fund?.name ?? '',
    fundWebsite: application?.fund?.website ?? '',
    fundSizeRaised: application?.fund?.fundSizeRaised ?? '',
    currentAUM: application?.fund?.currentAUM ?? '',
    LPsNumber: application?.fund?.LPsNumber ?? undefined,
  }

  return (
    <QueryBoundary queries={[applicationQuery]}>
      <Grid container sx={{ flex: 1 }}>
        {isMediumUp && (
          <Grid item xs={12} md={3} lg={2.5}>
            <SideBarStepper
              activeStep={activeStep}
              steps={steps}
              title={t('member.newApplication.steps.title')}
              action={handleAction}
              actionTitle={t('common.back')}
            />
          </Grid>
        )}

        <Grid item xs={12} md={9} lg={9.5}>
          <Layout maxWidth="md" isSideBarStepper>
            <BackLink onClick={handleAction} />
            <Grid container rowGap={3} mb={3} maxWidth={{ smd: 0.6 }}>
              <Grid item>
                <Typography variant="h2" component="h1">
                  {pages[activeStep].title}
                </Typography>
              </Grid>

              {pages[activeStep].description && (
                <Grid item>
                  <Typography mb={1}>
                    {pages[activeStep].description}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Formik
              initialValues={initialFormData}
              validationSchema={validationSchema[activeStep]}
              onSubmit={handleSaveNext}
            >
              {({ submitForm }) => {
                const handlePrevious = () => {
                  handleBack()
                  updateApplicationMutation.reset()
                }

                const caseProps = {
                  application,
                  applicationId,
                  clientType,
                  createApplicationMutation,
                  handlePrevious,
                  handleNext,
                  handleSaveNext,
                  isSubmittingApplication,
                }

                return (
                  <Form promptOnLeave>
                    {(() => {
                      switch (activeStep) {
                        case 0:
                          return <ContactInfoForm {...caseProps} />
                        case 1:
                          return <FundInfoForm {...caseProps} />
                        case 2:
                          return <RequestedLoanAmount {...caseProps} />
                        case 3:
                          return (
                            caseProps.applicationId && (
                              <UploadDisclosureDocuments
                                type="GP"
                                {...caseProps}
                                applicationId={caseProps.applicationId}
                              />
                            )
                          )
                        case 4:
                          return (
                            <StepperSuccess
                              userType={userType}
                              nextStepText={t('common.done')}
                              handleForward={handleAction}
                            />
                          )
                        default:
                          return
                      }
                    })()}
                    {/* Shows mutation error when submitting form  */}
                    <AlertDialog
                      isOpen={showAlertDialog}
                      onClose={handleOnClose}
                      description={t('common.errorSubmitForm')}
                      primaryButtonAction={() => {
                        submitForm()
                        setShowAlertDialog(false)
                      }}
                      secondaryButtonAction={handleResetMutation}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Layout>
        </Grid>
      </Grid>
    </QueryBoundary>
  )
}

export default GpNewApplication
