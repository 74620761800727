import { Link } from 'react-router-dom'
import Button, { ButtonProps } from '@mui/material/Button/Button'
import IconButton, {
  IconButtonProps,
} from '@mui/material/IconButton/IconButton'

import { ReactComponent as LiquidLPLogo } from 'assets/LiquidLPLogo.svg'
import { ReactComponent as LiquidLPLogoDark } from 'assets/LiquidLPLogoDark.svg'
import { ReactComponent as OpenstockLogoSymbol } from 'assets/OpenstockLogoSymbol.svg'

export const FullLogoLinkButton = ({
  path = '/',
  isWhiteLogo = false,
  sx = {},
}: ButtonProps & {
  path?: string
  isWhiteLogo?: boolean
}) => (
  <Button component={Link} to={path} sx={{ ...sx }}>
    {isWhiteLogo ? <LiquidLPLogo /> : <LiquidLPLogoDark width={200} />}
  </Button>
)

export const SymbolLogoLinkButton = ({
  path = '/',
  sx = {},
}: IconButtonProps & {
  path?: string
}) => (
  <IconButton component={Link} to={path} sx={{ paddingLeft: 0, ...sx }}>
    <OpenstockLogoSymbol />
  </IconButton>
)
