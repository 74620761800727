import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'

import { useUser } from 'app/User'
import { ClientTypes } from 'common/types'
import { getRegionByCountry, priorityCountryList } from 'lib/countryRegion'
import { useFormikContext } from 'lib/formik'
import { ApplicationInputForm } from 'views/member/RiaNewApplication'

import AutocompleteInput from 'components/mui/AutocompleteInput'
import Paper from 'components/mui/Paper/Paper'
import PhoneInput from 'components/mui/PhoneInput'
import RequiredNote from 'components/RequiredNote'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import TextInput from 'components/mui/TextInput'

interface Props {
  clientType?: ClientTypes
  isSubmittingApplication: boolean
}

function ContactInfoForm({ clientType, isSubmittingApplication }: Props) {
  const { t } = useTranslation()

  const { values, setFieldValue } = useFormikContext<ApplicationInputForm>()
  const regions = getRegionByCountry(values.country)

  const {
    data: { userType },
  } = useUser()
  const type = userType === 'Customer' ? 'ria' : userType.toLowerCase()

  return (
    <Grid container>
      <Grid item container xs={12} smd={7.5}>
        <Paper hasBorder>
          <RequiredNote />

          <Grid container rowSpacing={3} mt={2}>
            {userType !== 'GP' && (
              <>
                <Grid item xs={12}>
                  <TextInput
                    id="firstName"
                    name="firstName"
                    label={t(
                      `member.newApplication.${type}.contactInfo.labels.firstName`
                    )}
                    placeholder={t(
                      `member.newApplication.${type}.contactInfo.labels.firstNamePlaceholder`
                    )}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="lastName"
                    name="lastName"
                    label={t(
                      `member.newApplication.${type}.contactInfo.labels.lastName`
                    )}
                    placeholder={t(
                      `member.newApplication.${type}.contactInfo.labels.lastNamePlaceholder`
                    )}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    id="phone"
                    name="phone"
                    label={t(
                      `member.newApplication.${type}.contactInfo.labels.phone`
                    )}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="email"
                    name="email"
                    label={t(
                      `member.newApplication.${type}.contactInfo.labels.email`
                    )}
                    placeholder={t(
                      `member.newApplication.${type}.contactInfo.labels.emailPlaceholder`
                    )}
                    type="email"
                    inputMode="email"
                    required
                  />
                </Grid>
                {(clientType === 'entity' || userType === 'LP') && (
                  <>
                    <Grid item xs={12}>
                      <TextInput
                        id="companyName"
                        name="companyName"
                        label={t(
                          `member.newApplication.${type}.contactInfo.labels.companyName`
                        )}
                        placeholder={t(
                          `member.newApplication.${type}.contactInfo.labels.companyNamePlaceholder`
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        id="website"
                        name="website"
                        label={t(
                          `member.newApplication.${type}.contactInfo.labels.companyWebsite`
                        )}
                        placeholder={t(
                          `member.newApplication.${type}.contactInfo.labels.companyWebsitePlaceholder`
                        )}
                        inputMode="url"
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid item xs={12}>
              <TextInput
                id="street-address"
                name="streetAddress"
                label={t(
                  `member.newApplication.${type}.contactInfo.labels.streetAddress`
                )}
                placeholder={t(
                  `member.newApplication.${type}.contactInfo.labels.streetAddressPlaceholder`
                )}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="street-address2"
                name="streetAddress2"
                label={t('common.address.streetAddress2')}
                placeholder={t('common.address.streetAddress2Placeholder')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="city"
                name="city"
                label={t('common.address.city')}
                placeholder={t('common.address.cityPlaceholder')}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteInput
                id="country"
                name="country"
                label={t('common.address.country')}
                placeholder={t('common.selectPlaceholder')}
                options={priorityCountryList}
                onChangeCallback={() => {
                  setFieldValue('states', '')
                }}
                inputProps={{
                  autoComplete: 'country-name',
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteInput
                id="state"
                name="state"
                label={t('common.address.state')}
                placeholder={t('common.selectPlaceholder')}
                options={regions}
                disabled={!values.country}
                inputProps={{
                  autoComplete: 'address-level1',
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="zip-code"
                name="zipCode"
                label={t('common.address.zipCode')}
                placeholder={t('common.address.zipCodePlaceholder')}
                required
              />
            </Grid>
            <Grid item xs={12} mt={4}>
              <ResponsiveStepperButtons
                isSubmitting={isSubmittingApplication}
                isLastStep
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ContactInfoForm
