import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'

import QueryBoundary from 'app/QueryBoundary'
import { useUser } from 'app/User'
import { useGetMyApplicationsQuery } from 'lib/apollo/hooks'

import Layout from 'components/Layout/Layout'
import Greeting from 'components/Greeting'
import MemberApplicationsTable from 'components/tables/MemberApplicationsTable'

function MemberOverview() {
  const { t } = useTranslation()

  const {
    data: { firstName, lastName, userType },
  } = useUser()

  const applicationsQuery = useGetMyApplicationsQuery()
  const { data } = applicationsQuery
  const applications = data?.getMyApplications ?? []

  return (
    <QueryBoundary queries={[applicationsQuery]}>
      <Layout maxWidth="lg">
        <Typography
          variant="h1"
          textAlign="center"
          sx={{
            fontSize: { smd: '2.5rem' },
            lineHeight: { smd: '2.5rem' },
            textTransform: 'capitalize',
            wordBreak: 'break-word',
          }}
          mb={4}
        >
          {t('common.greeting.greetName', { firstName, lastName })}
        </Typography>
        <Greeting
          welcomeMessage={t('common.greeting.welcome')}
          greeting={t('member.dashboard.greeting')}
        />

        <MemberApplicationsTable type={userType} applications={applications} />
      </Layout>
    </QueryBoundary>
  )
}

export default MemberOverview
