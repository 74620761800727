import ArrowBack from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Link from 'components/mui/Link'
import Stepper from 'components/mui/Stepper'
import styles from './sideBarStepper.module.scss'

interface Props {
  activeStep: number
  steps: string[]
  title: string
  action?: () => void
  actionTitle?: string
}

export default function SideBarStepper({
  activeStep,
  steps,
  title,
  action,
  actionTitle = '',
}: Props) {
  return (
    <Box className={styles.container}>
      <Box>
        {action && (
          <Link
            onClick={action}
            color="rgb(255,255,255)"
            variant="subtitle2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 4,
            }}
          >
            <ArrowBack sx={{ mr: 1 }} />
            {actionTitle}
          </Link>
        )}
        <Typography my={{ sm: 2.5 }} variant="h3">
          {title}
        </Typography>
        <Stepper activeStep={activeStep} steps={steps} orientation="vertical" />
      </Box>
    </Box>
  )
}
