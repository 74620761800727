import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'

import { Form, Formik } from 'lib/formik'
import useFileUpload from 'hooks/useFileUpload'
import { UPLOAD_DOCUMENTS_LIST, UPLOAD_USER_INFORMATION } from 'lib/formConfig'

import AlertDialog from 'components/AlertDialog'
import Layout from 'components/Layout/Layout'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import UploadDocumentsForm from 'views/companyAdmin/companyInfo/UploadDocumentsForm'
import UploadFile from 'components/files/UploadFile'

export const CREATE_COMPANY_ROOT_ROUTE = '/account/company'

const initialFormData = {}

function UploadFilesTest() {
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  const fileUploadMutation = useFileUpload()

  const handleSubmit = async (values: any) => {
    const filesToUpload = UPLOAD_DOCUMENTS_LIST.filter(
      (document) =>
        values.hasOwnProperty(document.name) && values[document.name]
    ).map((document) => ({
      file: values[document.name][0],
      name: document.name,
    }))

    return fileUploadMutation.mutateAsync({
      endpoint: 'companyfilesupload',
      documents: filesToUpload,
    })
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    fileUploadMutation.reset()
  }

  useEffect(() => {
    if (fileUploadMutation.isSuccess) {
      console.log({ fileUploadMutation })
    }
  }, [fileUploadMutation, fileUploadMutation.isSuccess])

  useEffect(() => {
    if (fileUploadMutation.isError) {
      setShowAlertDialog(true)
    }
  }, [fileUploadMutation.isError])

  return (
    <Layout>
      <Formik initialValues={initialFormData} onSubmit={handleSubmit}>
        {({ submitForm }) => (
          <>
            <Form noValidate>
              <UploadDocumentsForm />
              <Box my={4}>
                <UploadFile document={UPLOAD_USER_INFORMATION} />
              </Box>
              <ResponsiveStepperButtons
                isUploading={fileUploadMutation.isPending}
                uploadProgress={fileUploadMutation.progress}
              />
            </Form>
            {/* Shows mutation error when submitting form */}
            <AlertDialog
              isOpen={showAlertDialog}
              onClose={() => setShowAlertDialog(false)}
              primaryButtonAction={() => {
                submitForm()
                setShowAlertDialog(false)
              }}
              secondaryButtonAction={handleResetMutation}
            />
          </>
        )}
      </Formik>
    </Layout>
  )
}

export default UploadFilesTest
