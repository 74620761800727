import { useEffect, useState } from 'react'
import { useField } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, {
  BaseTextFieldProps,
} from '@mui/material/TextField/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

type Props = Omit<
  BaseTextFieldProps,
  'value' | 'onChange' | 'onBlur' | 'onFocus'
> & {
  onChangeCallback?: () => void
  options: string[]
  name: string
  label?: string
}

function AutocompleteInput({
  id,
  disabled = false,
  onChangeCallback,
  label,
  name,
  options,
  ...props
}: Props) {
  const [field, meta, helpers] = useField(name)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isAutocomplete, setIsAutocomplete] = useState(false)

  const { value } = field
  const { setValue } = helpers

  const config = { ...props }

  if (meta.touched && meta.error) {
    config.error = true
    config.helperText = meta.error
  }

  const handleOnChange = (_event: any, newValue: any) => {
    onChangeCallback?.()

    setValue(newValue)
  }

  const handleInputValueChange = (_event: any, newValue: any) => {
    // Trigger autocomplete side effect
    // This will capture most autofill's, but likely not all
    if (value === '' && inputValue === '' && options.includes(newValue)) {
      setValue(newValue)
      setIsAutocomplete(true)
    }

    setInputValue(newValue)
  }

  useEffect(() => {
    // By default inputValue changes will open the autocomplete
    // Override this when autocomplete happened
    if (isAutocomplete && isOpen) {
      setIsAutocomplete(false)
      setIsOpen(false)
    }
  }, [isAutocomplete, isOpen])

  return (
    <Grid>
      {label && (
        <Typography variant="h6" mb={1}>
          {label}
          {props.required && <span style={{ color: '#CF0C0C' }}> *</span>}
        </Typography>
      )}
      <Autocomplete
        open={isOpen && !isAutocomplete}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        autoHighlight
        autoSelect
        disabled={disabled}
        id={id}
        options={options}
        // value and onChange represent actual form values
        value={value || null}
        onChange={handleOnChange}
        // inputValue and onInputChange represent temporary state (typing autocomplete)
        inputValue={inputValue}
        onInputChange={handleInputValueChange}
        renderInput={(params) => (
          <TextField
            {...config}
            {...params}
            inputProps={{
              ...params.inputProps,
              ...props.inputProps,
            }}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
  )
}

export default AutocompleteInput
