import { useState } from 'react'

import LightbulbIcon from '@mui/icons-material/Lightbulb'
import Box from '@mui/system/Box/Box'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'

import QueryBoundary from 'app/QueryBoundary'
import AlertDialog from 'components/AlertDialog'
import Alert from 'components/mui/Alert'
import Button from 'components/mui/Button'
import LoadingIndicator from 'components/LoadingIndicator'
import Layout from 'components/Layout/Layout'
import useSofrRate from 'hooks/useSofrRate'

function TestAlerts() {
  const sofrRateQuery = useSofrRate()
  const [loading, setLoading] = useState(false)
  const [showAlertDialog, setShowAlertDialog] = useState(false)

  return (
    <QueryBoundary
      queries={[sofrRateQuery]}
      alertMessage={'This is an alert message passed as props'}
    >
      <Box>
        <Grid container columnGap={2}>
          <Grid item m={2}>
            <Button onClick={() => setLoading(!loading)}>Toggle loading</Button>
          </Grid>
          <Grid item m={2}>
            <Button onClick={() => setShowAlertDialog(true)}>
              Open alert dialog
            </Button>
          </Grid>
          <Grid item my={2}>
            <Alert severity="info" variant="standard" icon={<LightbulbIcon />}>
              <Typography>
                Straight Alert. No layout wrapper. No title passed, just
                children. Note: this is not attached to loading toggle / new
                LoadingError component.
              </Typography>
            </Alert>
          </Grid>
        </Grid>

        {loading ? (
          <LoadingIndicator zIndex={-1} />
        ) : (
          <Grid item>
            <Alert title="Query boundry UI" isBackButton variant="standard">
              <Typography>
                When passed with alertMessage props will look like this.
              </Typography>
            </Alert>

            <Typography>No title or children passed:</Typography>
            <Alert actionButtonHandler={() => null} />

            <Alert
              actionButtonHandler={() => null}
              title="Test title"
              variant="outlined"
              severity="success"
              isBackButton
            >
              <Typography>Is back button prop = true</Typography>
            </Alert>
            <Layout maxWidth="lg">
              <Alert
                actionButtonHandler={() => null}
                title="Test title"
                variant="outlined"
                severity="warning"
              >
                <Typography>
                  Has layout parent wrapper with maxWidth="lg". Title passed
                  with children.
                </Typography>
              </Alert>
            </Layout>
          </Grid>
        )}

        <AlertDialog
          isOpen={showAlertDialog}
          onClose={() => setShowAlertDialog(false)}
          description="Lorem ipsum dolor sit amet consectetur adipisicing elit."
          primaryButtonAction={() => null}
          secondaryButtonText="close dialog"
          secondaryButtonAction={() => setShowAlertDialog(false)}
        />
      </Box>
    </QueryBoundary>
  )
}

export default TestAlerts
