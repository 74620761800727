import * as yup from 'yup'
import i18n from 'i18next'

import { UploadDocumentsListItem } from 'common/types'
import {
  REGEX_URL,
  REGEX_SPECIAL_CHAR,
  REGEX_UPPER_LOWERCASE,
  REGEX_CONTAIN_NUMBER,
  REGEX_E164_FORMAT,
  REGEX_NO_WHITESPACE,
} from 'lib/config'

export const loginDetailsSchema = yup.object({
  email: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.validation.emailRequired')),
  password: yup.string().required(i18n.t('common.validation.passwordRequired')),
})

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.validation.emailRequired')),
})

export const resetPasswordSchema = yup.object({
  code: yup
    .string()
    .trim()
    .required(i18n.t('account.verification.verificationCodeRequired')),
  profilePassword: yup
    .string()
    .required(i18n.t('account.verification.passwordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber')),
  profileConfirmPassword: yup
    .string()
    .required(i18n.t('account.profile.labels.confirmPasswordRequired'))
    .oneOf(
      [yup.ref('profilePassword'), null],
      i18n.t('account.profile.labels.confirmPasswordInvalid')
    ),
})

export const changePasswordSchema = yup.object({
  currentPassword: yup
    .string()
    .required(i18n.t('accountSettings.changePassword.currentPasswordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber'))
    .matches(REGEX_NO_WHITESPACE, i18n.t('common.passwordWhitespace')),
  profilePassword: yup
    .string()
    .required(i18n.t('accountSettings.changePassword.newPasswordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber'))
    .matches(REGEX_NO_WHITESPACE, i18n.t('common.passwordWhitespace'))
    .notOneOf(
      [yup.ref('currentPassword')],
      i18n.t('accountSettings.changePassword.cannotMatchCurrentPassword')
    ),
  profileConfirmPassword: yup
    .string()
    .required(i18n.t('account.profile.labels.confirmPasswordRequired'))
    .oneOf(
      [yup.ref('profilePassword'), null],
      i18n.t('account.profile.labels.confirmPasswordInvalid')
    ),
})

export const passwordSchema = yup.object({
  profilePassword: yup
    .string()
    .required(i18n.t('accountSettings.changePassword.newPasswordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber'))
    .matches(REGEX_NO_WHITESPACE, i18n.t('common.passwordWhitespace'))
    .notOneOf(
      [yup.ref('currentPassword')],
      i18n.t('accountSettings.changePassword.cannotMatchCurrentPassword')
    ),
  profileConfirmPassword: yup
    .string()
    .required(i18n.t('account.profile.labels.confirmPasswordRequired'))
    .oneOf(
      [yup.ref('profilePassword'), null],
      i18n.t('account.profile.labels.confirmPasswordInvalid')
    ),
})

export const profileSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.firstNameRequired')),
  lastName: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.lastNameRequired')),
  profilePassword: yup
    .string()
    .required(i18n.t('account.profile.labels.passwordRequired'))
    .min(8, i18n.t('common.passwordMinLength'))
    .matches(REGEX_UPPER_LOWERCASE, i18n.t('common.passwordUpperLowerCase'))
    .matches(REGEX_SPECIAL_CHAR, i18n.t('common.passwordSpecialChar'))
    .matches(REGEX_CONTAIN_NUMBER, i18n.t('common.passwordNumber'))
    .matches(REGEX_NO_WHITESPACE, i18n.t('common.passwordWhitespace')),
  profileConfirmPassword: yup
    .string()
    .required(i18n.t('account.profile.labels.confirmPasswordRequired'))
    .oneOf(
      [yup.ref('profilePassword'), null],
      i18n.t('account.profile.labels.confirmPasswordInvalid')
    ),
  workEmail: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.validation.emailRequired')),
  mobileNumber: yup
    .string()
    .matches(
      REGEX_E164_FORMAT,
      i18n.t('account.profile.labels.mobileNumberInvalid')
    )
    .required(i18n.t('common.validation.phoneNumberRequired')),
})

export const addMemberSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.firstNameRequired')),
  lastName: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.lastNameRequired')),
  company: yup.string().trim(),
  email: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.validation.emailRequired')),
  phoneNumber: yup
    .string()
    .matches(
      REGEX_E164_FORMAT,
      i18n.t('account.profile.labels.mobileNumberInvalid')
    )
    .required(i18n.t('common.validation.phoneNumberRequired')),
})

export const verificationCodeDetailsSchema = yup.object({
  code: yup
    .string()
    .trim()
    .min(6, i18n.t('account.verification.exactCodeLength'))
    .max(6, i18n.t('account.verification.exactCodeLength'))
    .required(i18n.t('account.verification.verificationCodeRequired')),
})

export const riaClientInfoSchema = yup.object({
  firstName: yup.string().trim().required(i18n.t('common.required')),
  lastName: yup.string().trim().required(i18n.t('common.required')),
  phone: yup
    .string()
    .matches(
      REGEX_E164_FORMAT,
      i18n.t('account.profile.labels.mobileNumberInvalid')
    )
    .required(i18n.t('common.required')),
  email: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.required')),
  companyName: yup
    .string()
    .trim()
    .when('clientType', {
      is: (value: string) => value === 'entity',
      then: yup
        .string()
        .required(i18n.t('account.company.labels.nameRequired')),
      otherwise: yup.string().nullable(),
    }),
  website: yup
    .string()
    .trim()
    .matches(REGEX_URL, i18n.t('account.company.labels.websiteAddressInvalid')),
  streetAddress: yup.string().trim().required(i18n.t('common.required')),
  streetAddress2: yup.string().trim().nullable(),
  city: yup.string().trim().required(i18n.t('common.validation.cityRequired')),
  country: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.countryRequired')),
  state: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.stateRequired')),
  zipCode: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.zipCodeRequired')),
})

export const lpContactInfoSchema = yup.object({
  firstName: yup.string().trim().required(i18n.t('common.required')),
  lastName: yup.string().trim().required(i18n.t('common.required')),
  phone: yup
    .string()
    .matches(
      REGEX_E164_FORMAT,
      i18n.t('account.profile.labels.mobileNumberInvalid')
    )
    .required(i18n.t('common.required')),
  email: yup
    .string()
    .email(i18n.t('common.validation.emailInvalid'))
    .required(i18n.t('common.required')),
  companyName: yup
    .string()
    .trim()
    .required(i18n.t('account.company.labels.nameRequired')),
  website: yup
    .string()
    .trim()
    .matches(REGEX_URL, i18n.t('account.company.labels.websiteAddressInvalid')),
  streetAddress: yup.string().trim().required(i18n.t('common.required')),
  streetAddress2: yup.string().trim().nullable(),
  city: yup.string().trim().required(i18n.t('common.validation.cityRequired')),
  country: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.countryRequired')),
  state: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.stateRequired')),
  zipCode: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.zipCodeRequired')),
})

export const corporateDetailsSchema = yup.object({
  streetAddress: yup.string().trim().required(i18n.t('common.required')),
  streetAddress2: yup.string().trim().nullable(),
  city: yup.string().trim().required(i18n.t('common.validation.cityRequired')),
  country: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.countryRequired')),
  state: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.stateRequired')),
  zipCode: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.zipCodeRequired')),
})

export const fundDetailsSchema = yup.object({
  fundName: yup.string().trim().required(i18n.t('common.required')),
  fundWebsite: yup
    .string()
    .trim()
    .matches(REGEX_URL, i18n.t('account.company.labels.websiteAddressInvalid')),
  fundSizeRaised: yup.string().nullable().required(i18n.t('common.required')),
  currentAUM: yup.string().nullable().required(i18n.t('common.required')),
  LPsNumber: yup.number().nullable(),
})

export const requestLoanAmountSchema = yup.object({
  amount: yup.number().nullable().required(i18n.t('common.required')),
  useOfProceeds: yup.string().nullable().required(i18n.t('common.required')),
  duration: yup.number().required(i18n.t('common.required')),
  comments: yup.string(),
})

export const uploadDocumentsSchema = (list: UploadDocumentsListItem[]) =>
  yup.object(list.reduce((items) => items, {} as Record<string, any>))

export const financingInfoSchema = yup.object({
  assetsPledged: yup.string().required(i18n.t('common.required')),
  preauthorizedAmount: yup.string(),
  ltv: yup.string().required(i18n.t('common.required')),
  originationFee: yup.string().required(i18n.t('common.required')),
  interestRate: yup.string().required(i18n.t('common.required')),
  financingTermLength: yup.string().required(i18n.t('common.required')),
  loanRepaymentFrequency: yup.string().required(i18n.t('common.required')),
  offerEndDate: yup.string().required(i18n.t('common.required')),
})

export const applicationStageSchema = yup.object({
  stage: yup.string().required(i18n.t('common.required')),
  comment: yup.string(),
})

export const paymentInformationSchema = yup.object().shape({
  firstName: yup.string().trim().required(i18n.t('common.required')),
  lastName: yup.string().trim().required(i18n.t('common.required')),
  streetAddress: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.homeAddressRequired')),
  streetAddress2: yup.string().trim(),
  city: yup.string().trim().required(i18n.t('common.validation.cityRequired')),
  country: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.countryRequired')),
  state: yup
    .string()
    .nullable()
    .required(i18n.t('common.validation.stateRequired')),
  zipCode: yup
    .string()
    .trim()
    .required(i18n.t('common.validation.yourZipCodeRequired')),
  SSN: yup.string().trim(),
  // .required(i18n.t('common.validation.socialSecurityNumberRequired')),
  bankAccountNumber: yup
    .string()
    .trim()
    .min(
      9,
      i18n.t('offer.loan.paymentInformation.bankInformation.bankAccountHint')
    )
    .max(
      12,
      i18n.t('offer.loan.paymentInformation.bankInformation.bankAccountHint')
    ),
  // .required(i18n.t('common.validation.bankAccountNumberRequired')),
  ABA_routingNumber: yup
    .string()
    .trim()
    .matches(
      /^\d{9}$/,
      i18n.t('offer.loan.paymentInformation.bankInformation.routingHint')
    ),
  // .required(i18n.t('common.validation.routingNumberRequired')),
})
