import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'

import Paper from 'components/mui/Paper/Paper'
import RequiredNote from 'components/RequiredNote'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'
import TextInput from 'components/mui/TextInput'

interface Props {
  handlePrevious: () => void
  isSubmittingApplication: boolean
}

function FundInfoForm({ handlePrevious, isSubmittingApplication }: Props) {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid item container xs={12} smd={7.5}>
        <Paper hasBorder>
          <RequiredNote />

          <Grid container rowSpacing={3} mt={2}>
            <Grid item xs={12}>
              <TextInput
                id="fundName"
                name="fundName"
                label={t('member.newApplication.gp.fundInfo.labels.fundName')}
                placeholder={t(
                  'member.newApplication.gp.fundInfo.labels.fundNamePlaceholder'
                )}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="fundWebsite"
                name="fundWebsite"
                label={t('member.newApplication.gp.fundInfo.labels.website')}
                placeholder={t(
                  'member.newApplication.gp.fundInfo.labels.websitePlaceholder'
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="fundSizeRaised"
                name="fundSizeRaised"
                label={t('member.newApplication.gp.fundInfo.labels.size')}
                placeholder={t('common.decimalPlaceholder')}
                delimiter="$"
                inputMode="decimal"
                isNumericFormat
                hasDecimalScale={false}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="currentAUM"
                name="currentAUM"
                label={t('member.newApplication.gp.fundInfo.labels.currentAum')}
                placeholder={t('common.decimalPlaceholder')}
                delimiter="$"
                inputMode="decimal"
                isNumericFormat
                hasDecimalScale={false}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="LPsNumber"
                name="LPsNumber"
                label={t('member.newApplication.gp.fundInfo.labels.numOfLps')}
                placeholder={t(
                  'member.newApplication.gp.fundInfo.labels.numOfLpsPlaceholder'
                )}
                isNumericFormat
                hasDecimalScale={false}
              />
            </Grid>

            <Grid item xs={12} mt={4}>
              <ResponsiveStepperButtons
                isSubmitting={isSubmittingApplication}
                previousStepText={t('common.previous')}
                handleBack={handlePrevious}
                isLastStep
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default FundInfoForm
