import axios, { AxiosError } from 'axios'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import { GenericObject } from 'common/types'
import { REST_CONFIG } from 'lib/config'
import { captureWarning } from 'lib/monitoring'

interface IArgs {
  endpoint: string
  documents: {
    file: File
    name: string
  }[]
}

const useFileUploadMutation = (userId?: string, applicationId?: string) => {
  const [progress, setProgress] = useState(0)
  const [uploadCaptableErrors, setUploadCaptableErrors] = useState([])

  const mutation = useMutation<void, AxiosError, IArgs>({
    mutationFn: async (args) => {
      const formData = new FormData()

      const fileLabels =
        args.documents.length > 0
          ? args.documents.map((document, index) => {
              formData.append(`file${index}`, document.file)

              return document.name
            })
          : []

      const path = `${REST_CONFIG.url}/${args.endpoint}${`?fileLabels=${
        fileLabels.length > 0 ? fileLabels.join(',') : ''
      }`}&userId=${userId}&applicationId=${applicationId}`

      return axios
        .post(path, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (ev: GenericObject) =>
            setProgress(Math.round((ev.loaded * 100) / ev.total)),
        })
        .then((response) => response.data)
    },
    onError: (e: any) => {
      if (e?.response.status === 400) {
        setUploadCaptableErrors(e.response.data.errors)
      }

      return captureWarning(e)
    },
  })

  return {
    ...mutation,
    captableErrors: uploadCaptableErrors,
    setUploadCaptableErrors,
    progress,
  }
}

export default useFileUploadMutation
