import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { useCreateUserMutation } from 'lib/apollo/hooks'
import { Form, Formik } from 'lib/formik'
import { addMemberSchema } from 'lib/validation'
import { DEFAULT_ADMIN_MEMBERS_ROUTE } from 'routes/AdminDashboard'

import AlertDialog from 'components/AlertDialog'
import Layout from 'components/Layout/Layout'
import LoadingButton from 'components/mui/LoadingButton'
import Paper from 'components/mui/Paper/Paper'
import PhoneInput from 'components/mui/PhoneInput'
import TextInput from 'components/mui/TextInput'
import RequiredNote from 'components/RequiredNote'

const initialValues = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  phoneNumber: '',
}

function AddMemberForm() {
  const { t } = useTranslation()
  const [createUser, createUserMutation] = useCreateUserMutation()
  const [showAlertDialog, setShowAlertDialog] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const userType = searchParams.get('userType')

  const handleNavigate = () => {
    navigate(DEFAULT_ADMIN_MEMBERS_ROUTE, {
      state: { toastMessage: t('liquidlpAdmin.addMember.successMessage') },
    })
  }

  const handleSubmit = async (values: typeof initialValues) => {
    const userDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.email,
      phoneNumber: values.phoneNumber,
      company: values.company,
      userType,
    }
    if (!createUserMutation.data) {
      const { errors } = await createUser({
        variables: userDetails,
        onCompleted: handleNavigate,
      })

      if (errors) return
    }
  }

  const handleResetMutation = () => {
    setShowAlertDialog(false)
    createUserMutation.reset()
  }

  useEffect(() => {
    if (createUserMutation.error) {
      setShowAlertDialog(true)
    }
  }, [createUserMutation.error])

  return (
    <Layout sx={{ px: 0 }} maxWidth="sm">
      <Typography variant="h2" component="h1" mb={3} textAlign="center">
        {t('liquidlpAdmin.addMember.heading')}
      </Typography>
      <Typography mb={3} textAlign="center">
        {t('liquidlpAdmin.addMember.subHeading')}
      </Typography>

      <Paper hasBorder>
        <RequiredNote />

        <Formik
          initialValues={initialValues}
          validationSchema={addMemberSchema}
          onSubmit={handleSubmit}
        >
          {({ submitForm }) => (
            <Form>
              <Grid container rowSpacing={3} mt={1}>
                <Grid item xs={12}>
                  <TextInput
                    id="firstName"
                    name="firstName"
                    label={t('liquidlpAdmin.addMember.labels.firstName')}
                    placeholder={t(
                      'liquidlpAdmin.addMember.labels.firstNamePlaceholder'
                    )}
                    autoFocus
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="lastName"
                    name="lastName"
                    label={t('liquidlpAdmin.addMember.labels.lastName')}
                    placeholder={t(
                      'liquidlpAdmin.addMember.labels.lastNamePlaceholder'
                    )}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="company"
                    name="company"
                    label={t('liquidlpAdmin.addMember.labels.company')}
                    placeholder={t(
                      'liquidlpAdmin.addMember.labels.companyPlaceholder'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="email"
                    name="email"
                    label={t('liquidlpAdmin.addMember.labels.email')}
                    placeholder={t(
                      'liquidlpAdmin.addMember.labels.emailPlaceholder'
                    )}
                    type="email"
                    inputMode="email"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    id="phone-number"
                    name="phoneNumber"
                    label={t('account.profile.labels.mobileNumber')}
                    required
                  />
                </Grid>

                <Grid item container xs={12} justifyContent="flex-end">
                  <LoadingButton
                    type="submit"
                    mode="forward"
                    loading={createUserMutation.loading}
                  >
                    {t('common.next')}
                  </LoadingButton>
                </Grid>

                <AlertDialog
                  isOpen={showAlertDialog}
                  onClose={() => setShowAlertDialog(false)}
                  primaryButtonAction={() => {
                    submitForm()
                    setShowAlertDialog(false)
                  }}
                  secondaryButtonAction={handleResetMutation}
                  description={createUserMutation.error?.message}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Layout>
  )
}

export default AddMemberForm
