import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import List from 'components/mui/List'
import Paper from 'components/mui/Paper/Paper'
import ResponsiveStepperButtons from 'components/ResponsiveStepperButtons'

function NextSteps({
  handleBack,
  isSubmitting,
}: {
  handleBack: () => void
  isSubmitting: boolean
}) {
  const { t } = useTranslation()

  const steps: string[] = t('offer.loan.nextSteps.items', {
    returnObjects: true,
  })

  return (
    <Grid container>
      <Grid item container xs={12} smd={7.5}>
        <Paper hasBorder>
          <Grid container mb={{ xs: 2, sm: 4 }}>
            <Typography variant="h3" mb={2}>
              {t('offer.loan.nextSteps.title')}
            </Typography>
            <Typography>{t('offer.loan.nextSteps.description')}</Typography>

            <List list={steps} />
          </Grid>

          <ResponsiveStepperButtons
            isSubmitting={isSubmitting}
            previousStepText={t('common.previous')}
            handleBack={handleBack}
            nextStepText={t('offer.loan.nextSteps.button')}
            isLastStep
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default NextSteps
